import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";

const DownloadAppPopup = ({
    isOpenAppDownloadModal,
    closeAppDownloadModal,
    isCancel = false
}: {
    isOpenAppDownloadModal: boolean;
    closeAppDownloadModal: () => void;
    isCancel?: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Transition appear show={isOpenAppDownloadModal} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50"
                    onClose={closeAppDownloadModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="relative w-full text-center max-w-md transform rounded-2xl bg-white p-6  align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className={`text-3xl font-medium ${isCancel ? "" : "leading-6"} text-gray-900`}
                                    >
                                        {isCancel ? `${t("forDetailsDownloadTheApp")}` : `${t("download")}`}
                                    </Dialog.Title>
                                    <div
                                        className="absolute -right-[10px] -top-[17px] sm:-right-5 sm:-top-4 cursor-pointer bg-white rounded-full shadow hover:shadow-lg transition p-1 hover:bg-[#FF3A3A] duration-200 group"
                                        onClick={closeAppDownloadModal}
                                    >
                                        <IoCloseOutline
                                            className="group-hover:text-white"
                                            size={30}
                                        />
                                    </div>
                                    <div className="mt-9">
                                        <div className="flex gap-4 justify-center">
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.padelmates"
                                                target="_blank"
                                                data-stop-propagation="true"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/googleplay.png"
                                                    className="h-12 w-18"
                                                    alt="Google Play"
                                                />
                                            </a>
                                            <a
                                                href="https://apps.apple.com/app/id1531797995?fbclid=IwAR3ywH0UF_AFtXYXTD2UaWNfAcOHZX8YRmgW1qV3RMC1w8RXo6EFkPVb0a0"
                                                target="_blank"
                                                data-stop-propagation="true"
                                                rel="noreferrer"
                                            >
                                                <img
                                                    src="/images/appstore.png"
                                                    className="h-12 w-18"
                                                    alt="App Store"
                                                />
                                            </a>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="px-10 text-[19px] py-2 bg-secondary text-white rounded-3xl mt-7 hover:bg-primary hover:text-secondary transform duration-200"
                                            onClick={closeAppDownloadModal}
                                        >
                                            {t("BookingModal.done")}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default DownloadAppPopup;
