// @ts-nocheck

import { t } from "i18next";
import moment from "moment";
import { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../clubProfile/ClubProfileMembership.css";
import { useBookingAtom } from "../../Utilities/clubStore";
import PaymentComponent from "./PaymentComponent";
import {
    IPaymentIntent,
    activeMembership,
    confirmSubscription,
    createSubscriptionIntent
} from "../../Api/api";
import Spinner from "../clubProfile/Spinner";
import { useStripe } from "@stripe/react-stripe-js";
import { AuthContext } from "../AuthProvider";
import { ConfirmCardPaymentData, StripeCardElement } from "@stripe/stripe-js";

const BecomeMemberForm = ({
    saveCardForFutureUse,
    setSaveCardForFutureUse,
    cardDetails,
    setCardDetails,
    handleCardElementSelect,
    cardElement
}: {
    saveCardForFutureUse: boolean;
    setSaveCardForFutureUse: (saveCardForFutureUse: boolean) => void;
    cardDetails: any;
    setCardDetails: (cardDetails: any) => void;
    handleCardElementSelect: (element: any) => void;
    cardElement: StripeCardElement | null;
}) => {
    const stripe = useStripe();
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    const [showPaymentMethods, setShowPaymentMethods] =
        useState<boolean>(false);
    const currentUser = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState<string | undefined>("");
    const [isSelected, setIsSelected] = useState("card");
    const [userCredit, setUserCredit] = useState(0);

    const [formData, setFormData] = useState<any>({
        dateOfBirth: "",
        phoneNumber: "",
        address: "",
        postalCode: "",
        county: "",
        country: ""
    });
    const [paymentMethodId, setPaymentMethodId] = useState<string>("");

    const [errors, setErrors] = useState<any>({});
    const [formStep, setFormStep] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentState, setPaymentState] = useState<{
        status: boolean;
        message: string;
        receiptUrl?: string | null;
        success?: boolean;
        error?: string;
    }>({
        status: false,
        message: "",
        receiptUrl: null,
        error: ""
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        const formattedValue =
            name === "dateOfBirth"
                ? moment(value).format("DD-MMM-YYYY")
                : name === "phoneNumber"
                ? value.replace(/[^0-9]/g, "")
                : value;

        setFormData({
            ...formData,
            [name]: formattedValue
        });
    };

    const handleDateChange = (date: any) => {
        setErrors({
            ...errors,
            dateOfBirth: date > new Date() ? "Date cannot be a future date" : ""
        });
        setFormData({
            ...formData,
            dateOfBirth: date
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const newErrors = validateForm(formData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            console.log("loading => Form data submitted:", formData);
            localStorage.setItem("memberData", JSON.stringify(formData));
            setFormStep(2);
        }
    };

    const validateForm = (data: any) => {
        const errors: any = {};

        // Date of Birth validation
        if (!data.dateOfBirth) {
            errors.dateOfBirth = "Date of Birth is required";
        }

        // Phone number validation
        if (!data.phoneNumber) {
            errors.phoneNumber = "Phone Number is required";
        } else if (!/^[\d+\-]{7,20}$/.test(data.phoneNumber)) {
            errors.phoneNumber =
                "Phone Number must be numeric, and can include '+' or '-' (up to 20 digits)";
        }

        // Address validation
        if (!data.address) {
            errors.address = "Address is required";
        }

        // Postal code validation
        if (!data.postalCode) {
            errors.postalCode = "Postal Code is required";
        }

        // County validation
        if (!data.county) {
            errors.county = "County is required";
        }

        // Country validation
        if (!data.country) {
            errors.country = "Country is required";
        }

        return errors;
    };

    const handleManageMembership = async () => {
        if (cardDetails && !cardDetails?.complete) {
            setPaymentState({
                ...paymentState,
                error: "Please fill all the card fields"
            });
            return;
        }
        if (!cardDetails && !paymentMethodId) {
            setPaymentState({
                ...paymentState,
                error: "Please select a card"
            });
            return;
        }
        const { duration, price_id, price } = bookingAtom.selectedMembership;
        setIsLoading(true);
        setPaymentState({
            status: true,
            message: "Processing payment..."
        });
        try {
            const intent = await createSubscriptionIntent(
                {
                    amount: price,
                    currency: bookingAtom.currency,
                    planDuration: duration,
                    planId: price_id,
                    description: "",
                    clubId: bookingAtom.clubData._id,
                    sourcePaymentMethodId: paymentMethodId,
                    url: "/subscription/membership/request"
                },
                setPaymentState,
                paymentState,
                setIsLoading
            );

            console.log("loading => createSubscription Intent resp", intent);

            // confirm with 3d secure
            const paymentResponse = cardDetails
                ? await confirmPaymentWith3DSecure(intent, cardDetails)
                : await confirmPaymentWith3DSecure(intent);

            if (paymentResponse && intent && intent.success) {
                // confirm subscription
                const subscriptionResponse = await confirmSubscription(
                    intent.subscriptionId,
                    "/subscription/membership/confirm"
                );
                console.log(
                    "loading => subscriptionResponse",
                    subscriptionResponse
                );
                if (subscriptionResponse && subscriptionResponse.success) {
                    setPaymentState({
                        status: true,
                        message: "Payment successful",
                        receiptUrl: subscriptionResponse.receiptUrl
                    });
                    handleActiveMembership(subscriptionResponse);
                }
                return subscriptionResponse;
            }
        } catch (error) {
            console.log("loading => subscription error", { error });
        }
    };

    const confirmPaymentWith3DSecure = async (
        intent: IPaymentIntent | any,
        cardDetails?: any
    ) => {
        if (!stripe) return;

        const confirmCardPaymentParams: ConfirmCardPaymentData = {};

        if (paymentMethodId && !cardDetails) {
            confirmCardPaymentParams.setup_future_usage = "off_session";
        }

        if (cardDetails && cardElement) {
            confirmCardPaymentParams.payment_method = {
                card: cardElement,
                billing_details: {
                    name: currentUser.displayName,
                    email: currentUser.email
                }
            };
        } else if (paymentMethodId) {
            confirmCardPaymentParams.payment_method = paymentMethodId; // only send for existing cards
        }

        const confirmPayment = await stripe.confirmCardPayment(
            intent?.clientSecret,
            confirmCardPaymentParams
        );
        console.log("loading => confirmPayment resp", confirmPayment);
        if (confirmPayment) return confirmPayment;
    };

    const handleActiveMembership = async (paymentRes: {
        success?: boolean;
        message: string;
        subscriptionId: string;
    }) => {
        const { subscriptionId } = paymentRes;

        if (!subscriptionId) {
            return;
        }

        const unit = bookingAtom.selectedMembership!.period.includes("month")
            ? 30
            : bookingAtom.selectedMembership!.period.includes("year")
            ? 365
            : 0;

        const data: any = {
            player_id: currentUser?.uid,
            birth_date: moment(formData!.dateOfBirth).format("YYYY-MM-DD"),
            phone_number: formData!.phoneNumber,
            address: formData!.address,
            postal_no: formData!.postalCode,
            county: formData!.county,
            country: formData!.country,
            club_id: bookingAtom.clubData._id,
            membership_id: bookingAtom.selectedMembership._id,
            following: bookingAtom.clubData!.following,
            payment_status: "",
            next_payment: moment
                .utc(bookingAtom.selectedMembership!.start_date)
                .tz(bookingAtom.clubData!.timezone)
                .add(unit, "days")
                .utc()
                .valueOf(),
            subscription_id: ""
        };

        data.subscription_id = subscriptionId;
        data.payment_status = "PAYMENT_SUCCESS";

        try {
            const activeMembershipResp = await activeMembership(data);

            console.log(
                "loading => activeMembershipResp",
                activeMembershipResp
            );
            if (activeMembershipResp && activeMembershipResp.success) {
                setFormStep(3);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("loading => active membership error", error);
        }
    };

    return (
        <>
            {formStep === 1 ? (
                <form className="w-[95%] mx-auto mt-8" onSubmit={handleSubmit}>
                    <div className="mb-2 w-full">
                        <label htmlFor="dateOfBirth">Date of Birth:</label>
                        <div
                            className="w-[100%] react-datepicker-wrapper"
                            style={{ width: "100%" }}
                        >
                            <DatePicker
                                selected={formData.dateOfBirth}
                                onChange={handleDateChange}
                                className={`border-2 border-secondary outline-primary rounded-lg px-2 py-2 datePicker ${
                                    errors.dateOfBirth
                                        ? "border-2 border-red-500"
                                        : ""
                                }`}
                                maxDate={new Date()} // Set the maximum date to today's date
                                dateFormat="dd-MMM-yyyy"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Select your birthday"
                                isClearable // Allow the user to clear the date if needed
                            />
                        </div>
                        {errors.dateOfBirth && (
                            <span className="text-red-500 text-sm">
                                {errors.dateOfBirth}
                            </span>
                        )}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            maxLength={20}
                            onChange={handleInputChange}
                            className={`w-full border-2 border-secondary outline-primary rounded-lg px-2 py-2 ${
                                errors.phoneNumber
                                    ? "border-2 border-red-500"
                                    : ""
                            }`}
                            pattern="[0-9]+"
                        />
                        {errors.phoneNumber && (
                            <span className="text-red-500 text-sm">
                                {errors.phoneNumber}
                            </span>
                        )}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="address">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                            className={`w-full border-2 border-secondary outline-primary rounded-lg px-2 py-2 ${
                                errors.address ? "border-2 border-red-500" : ""
                            }`}
                        />
                        {errors.address && (
                            <span className="text-red-500 text-sm">
                                {errors.address}
                            </span>
                        )}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="postalCode">Postal Code:</label>
                        <input
                            type="text"
                            id="postalCode"
                            name="postalCode"
                            value={formData.postalCode}
                            onChange={handleInputChange}
                            className={`w-full border-2 border-secondary outline-primary rounded-lg px-2 py-2 ${
                                errors.postalCode
                                    ? "border-2 border-red-500"
                                    : ""
                            }`}
                            maxLength={40}
                        />
                        {errors.postalCode && (
                            <span className="text-red-500 text-sm">
                                {errors.postalCode}
                            </span>
                        )}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="county">County:</label>
                        <input
                            type="text"
                            id="county"
                            name="county"
                            value={formData.county}
                            onChange={handleInputChange}
                            className={`w-full border-2 border-secondary outline-primary rounded-lg px-2 py-2 ${
                                errors.county ? "border-2 border-red-500" : ""
                            }`}
                        />
                        {errors.county && (
                            <span className="text-red-500 text-sm">
                                {errors.county}
                            </span>
                        )}
                    </div>
                    <div className="mb-2">
                        <label htmlFor="country">Country:</label>
                        <input
                            type="text"
                            id="country"
                            name="country"
                            value={formData.country}
                            onChange={handleInputChange}
                            className={`w-full border-2 border-secondary outline-primary rounded-lg px-2 py-2 ${
                                errors.country ? "border-2 border-red-500" : ""
                            }`}
                        />
                        {errors.country && (
                            <span className="text-red-500 text-sm">
                                {errors.country}
                            </span>
                        )}
                    </div>
                    <div className="pb-5">
                        <div className="text-center">
                            <button
                                type="submit"
                                className="px-16 text-[16px] md:text-[19px] py-2 bg-secondary text-white rounded-3xl mt-7 w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                            >
                                {t("BookingModal.continue")}
                            </button>
                        </div>
                    </div>
                </form>
            ) : formStep === 2 ? (
                <>
                    {isLoading && <Spinner />}
                    <div
                        className={
                            isLoading
                                ? `w-[95%] mx-auto mt-4 relative blur`
                                : `w-[95%] mx-auto mt-4 relative`
                        }
                    >
                        <div className="mb-2">
                            <h4 className="text-center font-bold text-xl my-1 mb-4">
                                {bookingAtom.selectedMembership.name}
                            </h4>
                            <div className="flex items-center justify-between my-1">
                                <p className="text-lg">Period</p>
                                <p className="text-lg">
                                    {moment
                                        .utc(
                                            bookingAtom.selectedMembership
                                                .start_date
                                        )
                                        .tz(bookingAtom.clubData.timezone)
                                        .format("DD MMM YYYY")}{" "}
                                    -{" "}
                                    {moment
                                        .utc(
                                            bookingAtom.selectedMembership
                                                .end_date
                                        )
                                        .tz(bookingAtom.clubData.timezone)
                                        .format("DD MMM YYYY")}
                                </p>
                            </div>
                            <hr className="w-full bg-secondary text-secondary" />
                            <div className="mt-4 text-lg font-medium min-h-[200px] mb-2">
                                <p className="my-4 font-semibold text-lg">
                                    Your Information
                                </p>
                                {Object.values(formData).length &&
                                    Object.entries(formData)?.map(
                                        (info: any, i: number) => (
                                            <p
                                                className="text-md font-medium"
                                                key={i}
                                            >
                                                {info[0] === "dateOfBirth"
                                                    ? moment(info[1]).format(
                                                          "DD-MMM-YYYY"
                                                      )
                                                    : info[1]}
                                            </p>
                                        )
                                    )}
                            </div>
                            <hr className="w-full bg-secondary text-secondary" />

                            {showPaymentMethods ? (
                                <div className="px-2">
                                    <PaymentComponent
                                        price={bookingAtom.price}
                                        setSelectedCard={setPaymentMethodId}
                                        selectedCard={paymentMethodId}
                                        hidePaymentIcons={true}
                                        clubId={bookingAtom.clubData._id}
                                        isSelected={isSelected}
                                        setIsSelected={setIsSelected}
                                        userCredit={userCredit}
                                        setUserCredit={setUserCredit}
                                        setErrorMessage={setErrorMessage}
                                        saveCardForFutureUse={
                                            saveCardForFutureUse
                                        }
                                        setSaveCardForFutureUse={
                                            setSaveCardForFutureUse
                                        }
                                        cardDetails={cardDetails}
                                        setCardDetails={setCardDetails}
                                        handleCardElementSelect={
                                            handleCardElementSelect
                                        }
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                            <hr className="w-full bg-secondary text-secondary" />

                            <div className="flex justify-between py-4 px-2">
                                <p className="text-lg font-medium">
                                    {t("Profile.totalPayment")}
                                </p>
                                <h4 className="text-lg font-semibold">
                                    {`${
                                        bookingAtom.selectedMembership.price
                                    } ${bookingAtom.clubData.currency?.toUpperCase()}/${bookingAtom.selectedMembership.period
                                        ?.split("ly")[0]
                                        ?.toLowerCase()}`}
                                </h4>
                            </div>

                            <hr className="w-full bg-secondary text-secondary" />
                        </div>
                        <div className="pb-5">
                            <div className="m-0">
                                {paymentState?.error ? (
                                    <h6 className="text-center font-semibold text-xl text-red-600">
                                        {paymentState.error}
                                    </h6>
                                ) : (
                                    <h6 className="text-center font-medium text-xl">
                                        {paymentState.message}
                                    </h6>
                                )}
                            </div>
                            <div className="text-center flex justify-between">
                                <button
                                    onClick={() => {
                                        setFormStep((prev) => prev - 1);
                                        setShowPaymentMethods(false);
                                    }}
                                    className="px-4 mx-1 text-[16px] md:text-[19px] py-2 bg-white border border-secondary text-secondary rounded-3xl mt-7 w-full max-w-[350px] hover:bg-[#FF3A3A] hover:border-[#FF3A3A] hover:text-white transform duration-200"
                                >
                                    {t("back")}
                                </button>
                                <button
                                    className="px-4 mx-1 text-[16px] md:text-[19px] py-2 bg-secondary text-white rounded-3xl mt-7 w-full max-w-[350px] hover:bg-primary border hover:border-primary hover:text-secondary transform duration-200"
                                    onClick={() => {
                                        if (!showPaymentMethods) {
                                            setShowPaymentMethods(true);
                                        } else {
                                            handleManageMembership();
                                        }
                                    }}
                                >
                                    {showPaymentMethods
                                        ? t("BookingModal.confirm")
                                        : t("BookingModal.pay")}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            ) : formStep === 3 ? (
                <div
                    className={
                        isLoading
                            ? `w-[95%] mx-auto mt-4 relative blur`
                            : `w-[95%] mx-auto mt-4 relative`
                    }
                >
                    <div className="mb-2">
                        <div className="flex flex-col items-center justify-center">
                            <img src="/images/verified.svg" alt="booked logo" />
                            <h2 className="text-[25px] font-bold text-center">
                                {`You have successfully subscribed for ${bookingAtom.selectedMembership.name}`}
                            </h2>
                        </div>
                    </div>
                    <div className="pb-5 flex justify-center">
                        <button
                            className="px-10 mx-1 text-[16px] md:text-[19px] py-2 bg-secondary text-white rounded-3xl mt-7 max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                            onClick={() => {
                                setBookingAtom({
                                    ...bookingAtom,
                                    show: !bookingAtom.show
                                });
                            }}
                        >
                            {t("BookingModal.done")}
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default BecomeMemberForm;
