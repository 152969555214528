import { memo, useEffect, useState } from "react";
import { ICentre, SportsAvailable } from "./interface";
import { updateClubInfo } from "../../Api/api";
import { useNavigate } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";

const generateGameType = (title: string) => {
    const lowerCaseTitle = title.toLowerCase();
    // const imageDirPath = "../static/media/";
    switch (true) {
        case lowerCaseTitle.includes("padel"):
            return "/images/padelGame.png";
        case lowerCaseTitle.includes("tennis"):
            return "/images/tennisGame.png";
        case lowerCaseTitle.includes("badminton"):
            return "/images/badmintonGame.png";
        case lowerCaseTitle.includes("squash"):
            return "/images/squashGame.png";
        case lowerCaseTitle.includes("pickleball"):
            return "/images/pickleballGame.png";
        default:
            return "/images/padelGame.png";
    }
};

const ComponentCentreCard = memo(
    ({
        data = {} as ICentre,
        currentUser
    }: {
        data: ICentre;
        currentUser?: any;
    }) => {
        const { t } = useTranslation();

        const {
            _id,
            name,
            photo_url,
            city,
            gameType,
            sports_available,
            about_the_hall
        } = data;
        const [following, setFollowing] = useState<boolean>(false);
        const [loading, setLoading] = useState<any>(null);
        const navigate = useNavigate();

        const handleFollowClub = (id: string) => {
            if (!currentUser) {
                const queryParams = new URLSearchParams();
                queryParams.set("page", "/find-centres");
                queryParams.set("action", "follow");
                queryParams.set("club_id", id);
                const existingParams = new URLSearchParams(
                    window.location.search
                );
                existingParams.forEach((value, key) => {
                    queryParams.set(key, value);
                });
                const queryString = queryParams.toString();
                const url = `/sign-in?${queryString}`;
                // navigate(`/SignIn?page=find-centres&action=follow&club_id=${id}`);
                navigate(url);
                return false;
            }
            setLoading(id);
            const body = {
                following: following ? false : true,
                notification: {
                    match_post: true,
                    tournament_post: true,
                    training_post: true
                }
            };
            updateClubInfo(id, currentUser?.uid, body).then(
                (followClubResp: any) => {
                    if (followClubResp?.data.success) {
                        setFollowing(!following);
                        setLoading(null);
                    }
                }
            );
        };

        const handleRedirect = (_id: string) => {
            navigate(`/club/${_id}`);
        };

        const renderAvailableCourts = (sports_available: SportsAvailable) => {
            const availableCourts = Object.keys(sports_available)
                .filter((key) => {
                    return sports_available[key] === true;
                })
                .reduce((acc: JSX.Element[], key, c) => {
                    const el = (
                        <div
                            className="inline-flex gap-1 items-center sm:justify-center ml-0 mr-3 sm:ml-3 sm:mr-0"
                            key={c}
                        >
                            <img
                                src={generateGameType(key)}
                                className="w-5 h-auto"
                                alt={`${key} icon`}
                            />
                            <h5 className="text-black text-[15px] font-normal capitalize">
                                {key}
                            </h5>
                        </div>
                    );
                    acc.push(el);
                    return acc;
                }, []);

            return <div className="mt-2 ">{availableCourts}</div>;
        };

        useEffect(() => {
            if (data && data._id) {
                setFollowing(data.following || false);
            }
        }, [data]);
        return (
            <div
                className="flex flex-col flex-wrap sm:flex-row sm:justify-between justify-center border-b border-[#00000026] py-8 w-full px-4 mx-auto items-start"
                key={_id}
            >
                {/* Col 1 */}
                <div className="flex gap-5 items-center w-full sm:w-8/12 lg:w-5/12 2xl:w-4/12">
                    <div className="flex overflow-hidden min-w-fit min-h-fit m-0 self-center">
                        <div
                            className="hover:cursor-pointer"
                            onClick={() => handleRedirect(_id)}
                        >
                            <img
                                src={
                                    photo_url ||
                                    generateGameType(gameType || name)
                                }
                                className="w-[102px] h-auto min-h-[50px] object-contain border border-[#000b2966] rounded-[6px]"
                                alt={name}
                                title={t("view_club")}
                            />
                        </div>
                    </div>
                    <div className="text-[#00081e] ">
                        <div
                            className="mb-5 hover:cursor-pointer"
                            onClick={() => handleRedirect(_id)}
                        >
                            <h3 id={`findCenterClubName-${_id}`} className="text-[25px] font-medium hover:cursor-pointer">
                                {name}
                            </h3>
                            <p className="text-slate-950 text-[19px] font-normal leading-[18px]">
                                {city}
                            </p>
                        </div>
                        <button
                            id={`followUnfollowButton-${_id}`}
                            className={
                                following
                                    ? "text-[15px] py-1 px-3 border border-[#00081e] max-w-fit bg-[#00081e] text-white rounded-sm hover:opacity-80 transform duration-200"
                                    : "text-[15px] py-1 px-3 border border-[#00081e] max-w-fit rounded-sm hover:bg-primary hover:text-secondary transform duration-200"
                            }
                            title={
                                following ? t("unfollow") : t("follow_the_club")
                            }
                            onClick={() => handleFollowClub(_id)}
                        >
                            {following ? (
                                <span id={`followingText-${_id}`} className="flex justify-center items-center gap-1 following">
                                    {t("following")}{" "}
                                    {loading === data?._id ? (
                                        <CgSpinner className="animate-spin" />
                                    ) : null}
                                </span>
                            ) : (
                                <span id={`followText-${_id}`} className="flex justify-center items-center gap-1 not-follwing">
                                    {t("follow")} {"+ "}
                                    {loading === data?._id ? (
                                        <CgSpinner className="animate-spin" />
                                    ) : null}
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {/* Col 2 */}
                <div className="text-left sm:text-right xl:text-center w-full mt-6 sm:mt-0 sm:w-4/12 lg:w-3/12 2xl:w-3/12 lg:px-3">
                    <h4 className="text-slate-950 text-lg font-medium leading-relaxed">
                        {t("FindCentres.title_court_types")}:
                    </h4>
                    {/* <div className="inline-flex items-center gap-2"> */}
                    {renderAvailableCourts(sports_available)}
                    {/* </div> */}
                </div>
                {/* Col 3 */}
                <div className="w-full mt-3 sm:mt-6 2xl:mt-0  2xl:w-5/12 text-slate-950 text-lg font-normal leading-relaxed">
                    <p> {about_the_hall} </p>
                </div>
            </div>
        );
    }
);

export default ComponentCentreCard;
