import React, { useEffect, useState } from "react";
import { ComponentBookingInfo } from "./ComponentBookingInfo";
import {
    ICourt,
    ISlot,
    convertUTC,
    getAllAvailableHours,
    getAllHours,
    getAvailableSlots,
    getClubProfile,
    getCustomizedSelectableHours,
    getSelectableHours,
    updateClubInfo
} from "../../Api/api";
import moment from "moment";
import { useBookingAtom, useClubAtom } from "../../Utilities/clubStore";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateGameType } from "../../Utilities/utils";

interface ComponentBookingCardProps {
    setPrice: React.Dispatch<React.SetStateAction<number>>;
    setMins: React.Dispatch<React.SetStateAction<number>>;
    jsonData: any;
    pageSetter: any;
    endTime: string;
    startTime: string;
    onClose: () => void;
    selectedStartDate: any;
    setSelectedBookingData: any;
    selectedSlot: string;
    setSelectedSlot: React.Dispatch<React.SetStateAction<string>>;
    setSelectedCourt: React.Dispatch<React.SetStateAction<any>>;
    setAvailableSlotState: any;
    setStartTime: any;
    setEndTime: any;
    basePrice: number;
    setBasePrice: any;
    currentUser?: any;
    bookingData?: any;
}

export const ComponentBookingCard: React.FC<ComponentBookingCardProps> =
    React.memo(
        ({
            jsonData,
            onClose,
            pageSetter,
            selectedStartDate,
            setSelectedBookingData,
            setPrice,
            setMins,
            endTime,
            startTime,
            selectedSlot,
            setSelectedSlot,
            setSelectedCourt,
            setAvailableSlotState,
            setStartTime,
            setEndTime,
            basePrice,
            setBasePrice,
            currentUser,
            bookingData
        }) => {
            const [selectedButton, setSelectedButton] = useState<string | null>(
                null
            );
            const [selectedTime, setSelectedTime] = useState<string>("");
            const [availableSlots, setAvailableSlots] = useState<string[]>([]);
            const [loadingSlots, setLoadingSlots] = useState<boolean>(false);
            const [slotsWithHours, setSlotsWithHours] = useState<any>([]);
            const [allSlots, setAllSlots] = useState<any[]>([]);
            const [originalCourts, setOriginalCourts] = useState<any[]>([]);
            const [club, setClub] = useClubAtom();
            const [slots, setSlots] = useState<
                (ICourt & {
                    available_slots: ISlot[];
                })[]
            >([]);
            const [following, setFollowing] = useState<boolean>(false);
            const [loading, setLoading] = useState<any>(null);
            const [bookingAtom, setBookingAtom] = useBookingAtom();

            const [clubData, setClubData] = useState<any>(null);

            const navigate = useNavigate();

            const [selectedSlotIdx, setSelectedSlotIdx] = useState<any>(null);

            const timezone = jsonData.timezone;

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const today = String(currentDate.getDate()).padStart(2, "0");

            let formattedDate = `${year}-${month}-${today}`;
            let todayDate = `${year}-${month}-${today}`;
            if (selectedStartDate) {
                formattedDate = selectedStartDate;
            }

            const currentTime = moment().tz(timezone).format("HH:mm");
            const isSameDay = todayDate === selectedStartDate;
            const currentHour = isSameDay
                ? moment().tz(timezone).format("HH")
                : "00";

            useEffect(() => {
                if (jsonData && jsonData._id) {
                    setFollowing(jsonData.following);
                    setLoadingSlots(true);
                    getAvailableSlots(
                        jsonData?._id,
                        convertUTC(
                            formattedDate,
                            `${currentHour}:00`,
                            timezone
                        ),
                        convertUTC(formattedDate, "23:59", timezone)
                    ).then((resp: any) => {
                        if (resp) {
                            setClub({ ...club, id: jsonData._id });
                            setSlots(resp);
                            setOriginalCourts(resp.originalCourts);
                            setAllSlots(resp.allSlots);
                            // const slotsWithHours = resp?.map((court: any) => ({
                            //     ...court,
                            //     available_slots: court.available_slots.map(
                            //         (slot: any) => ({
                            //             ...slot,
                            //             hours: getAllHours(slot, timezone)
                            //         })
                            //     )
                            // }));
                            let newArray: string[] = [];
                            // get all available unique hours
                            resp.uniqueStartTimes.forEach(
                                (startTime: string | number | Date) => {
                                    const data = moment(startTime)
                                        .tz(timezone)
                                        .format("HH:mm");
                                    const dataUTC = moment(startTime)
                                        .tz("UTC")
                                        .format("HH:mm");
                                    newArray.push(data + "," + dataUTC);
                                }
                            );
                            // if (slotsWithHours && slotsWithHours.length) {
                            //     setSlotsWithHours(slotsWithHours);
                            //     const hours =
                            //         getAllAvailableHours(slotsWithHours);

                            //     const customizedHours =
                            //         getCustomizedSelectableHours(
                            //             newArray
                            //         );

                            //     setAvailableSlots(customizedHours.flat());
                            //     setLoading(false);
                            // }
                            const customizedHours =
                                getCustomizedSelectableHours(newArray);
                            setLoadingSlots(false);
                            setAvailableSlots(customizedHours.flat());
                            setLoading(false);
                        }
                    }).catch(()=>{
                        setLoadingSlots(false);
                    });
                }
            }, [jsonData]);

            const handleButtonClick = (button: string) => {
                let newSlot = "";
                if (button) {
                    const myArray = button.split(",");
                    newSlot = myArray[1];
                }

                if (button === selectedButton) {
                    setSelectedButton(null);
                    setSelectedTime("");
                } else {
                    setSelectedButton(newSlot ? newSlot : button);
                    setSelectedTime(button);
                }
            };

            const daysOfWeek = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ];
            let dateFromLS = localStorage.getItem("selectedDate");
            const date: any = dateFromLS
                ? new Date(dateFromLS.replace(/-/g, "/").replace(/["\\]/g, ""))
                : new Date(); // Create a new Date object with the current date and time
            const dayOfWeek = date.getDay(); // Get the day of the week as a number (0 - Sunday, 1 - Monday, ...)

            const day = daysOfWeek[dayOfWeek]; // Retrieve the day name from the array

            const dayy = String(date.getDate()).padStart(2, "0"); // Get the day as a two-digit string
            const monthh = String(date.getMonth() + 1).padStart(2, "0"); // Get the month as a two-digit string (Note: months are zero-based)
            const yearr = date.getFullYear(); // Get the full year

            const dateString = `${dayy}-${monthh}-${yearr}`;

            let selectedDate: any = localStorage.getItem("selectedDate");
            selectedDate = selectedDate
                ? new Date(
                      selectedDate.replace(/-/g, "/").replace(/["\\]/g, "")
                  )
                : new Date();

            const newDate = new Date();

            const handleFollowClub = (id: string) => {
                if (!currentUser) {
                    const queryParams = new URLSearchParams();
                    queryParams.set("page", "/Booking");
                    queryParams.set("action", "follow");
                    queryParams.set("club_id", id);
                    const existingParams = new URLSearchParams(
                        window.location.search
                    );
                    existingParams.forEach((value, key) => {
                        queryParams.set(key, value);
                    });
                    const queryString = queryParams.toString();
                    const url = `/sign-in?${queryString}`;
                    navigate(url);
                    return false;
                }
                setLoading(id);
                const body = {
                    following: following ? false : true,
                    notification: {
                        match_post: true,
                        tournament_post: true,
                        training_post: true
                    }
                };
                updateClubInfo(id, currentUser?.uid, body).then(
                    (followClubResp: any) => {
                        if (followClubResp?.data.success) {
                            if (following) {
                                setFollowing(false);
                            } else {
                                setFollowing(true);
                            }
                            setLoading(null);
                        }
                    }
                );
            };

            const handleRedirect = (id: string) => {
                navigate(
                    `/club/${
                        jsonData.short_name ? jsonData.short_name : jsonData._id
                    }`
                );
            };

            const getCubProfile = async (clubId: any, playerId: string) => {
                try {
                    const resp = await getClubProfile(clubId, playerId);
                    if (resp?.status === 200) {
                        setClubData({ ...resp.data });
                    }
                } catch (error: any) {
                    console.log("loading => getCubProfile error", error);
                }
            };

            useEffect(() => {
                if (jsonData?._id && currentUser?.uid) {
                    getCubProfile(jsonData?._id, currentUser?.uid);
                }
            }, [jsonData?._id, currentUser?.uid, following]);

            useEffect(() => {
                if (clubData) {
                    setFollowing(clubData?.following);
                }
            }, [clubData]);

            const { t } = useTranslation();

            const getSlotValue = (slot: string, pre: boolean) => {
                const myArray = slot.split(",");
                if (pre) {
                    return myArray[0];
                }
                return myArray[1];
            };

            return (
                <div className="flex flex-col content-between flex-wrap sm:flex-row sm:justify-between justify-center gap-5 border-b-2 border-[#00000026] sm:border-none py-8 w-full px-4 sm:px-8 mx-auto items-start">
                    <div className="flex gap-5 items-start w-full md:w-[calc(50%-1.125rem)]">
                        <div className="flex flex-col items-center gap-2 mt-2">
                            <div
                                id={`clubImageDiv-${jsonData._id}`}
                                className="flex overflow-hidden w-[102px] h-[102px] m-0 self-center hover:cursor-pointer"
                                onClick={() => handleRedirect(jsonData._id)}
                            >
                                <img
                                    id={`clubImage-${jsonData._id}`}
                                    src={
                                        jsonData.photo_url
                                            ? jsonData.photo_url
                                            : generateGameType(
                                                  jsonData.gameType ||
                                                      jsonData.name
                                              )
                                    }
                                    className="w-[102px] h-[102px] object-contain border border-[#000b2966] rounded-[6px]"
                                    alt="Club Profile"
                                />
                            </div>
                            <button
                                id={`followButton-${jsonData._id}`}
                                className={
                                    !following
                                        ? "text-[15px] rounded-full py-1 px-3 border border-[#00081e] w-full max-w-[102px] bg-[#00081e] text-white hover:opacity-80 transform duration-200"
                                        : "text-[15px] rounded-full py-1 px-3 border border-[#00081e] w-full max-w-[102px] hover:bg-primary hover:text-secondary transform duration-200"
                                }
                                title={
                                    following ? "unfollow" : "follow the club"
                                }
                                onClick={() => handleFollowClub(jsonData?._id)}
                            >
                                {following ? (
                                    <span id={`following-${jsonData._id}`} className="flex justify-center items-center gap-1">
                                        {t("following")}
                                        {loading === jsonData?._id ? (
                                            <CgSpinner className="animate-spin" />
                                        ) : null}
                                    </span>
                                ) : (
                                    <span id={`follow-${jsonData._id}`} className="flex justify-center items-center gap-1">
                                        {t("follow")} +{" "}
                                        {loading === jsonData?._id ? (
                                            <CgSpinner className="animate-spin" />
                                        ) : null}
                                    </span>
                                )}
                            </button>
                        </div>
                        <div className="text-[#00081e] flex flex-col gap-4 mt-3">
                            <div
                                id={`clubInfoDiv-${jsonData._id}`}
                                className="hover:cursor-pointer"
                                onClick={() => handleRedirect(jsonData._id)}
                            >
                                <h3 id={`clubName-${jsonData._id}`} className="text-[25px] font-medium hover:cursor-pointer">
                                    {jsonData.name}
                                </h3>
                                <p id={`clubCity-${jsonData._id}`} className="text-[19px]">{jsonData.city}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 w-full md:w-[calc(50%-1.125rem)]">
                        <div className="w-full mx-auto m-0">
                            {!jsonData?._id ||
                            !jsonData?.permissions.mobile_booking ? (
                                <div className="flex justify-center">
                                    <h4 className="text-lg mt-6 text-center">
                                        {t("Profile.bookingUnavailable")}
                                    </h4>
                                </div>
                            ) : jsonData?._id &&
                              availableSlots?.length === 0 ? (
                                <div className="flex justify-center">
                                    <h4 id="noTimeSlotAvailable" className="text-lg mt-6 text-center">
                                        {" "}
                                        {loadingSlots ? t("checkingAvailableSlots") : t("noAvailableSlot")}
                                    </h4>
                                </div>
                            ) : (
                                <div className="flex flex-wrap gap-2 items-start justify-start">
                                    {selectedDate > newDate ? (
                                        // availableSlots?.map(
                                        //       (timeArray, i, arr) => (
                                        <div className="flex flex-wrap gap-2 justify-start items-start w-full">
                                            {availableSlots.map(
                                                (time, i, arr) => (
                                                    <button
                                                        id={`bookSlot-${i}`}
                                                        key={getSlotValue(
                                                            time,
                                                            true
                                                        )}
                                                        className={
                                                            selectedButton ===
                                                            getSlotValue(
                                                                time,
                                                                false
                                                            )
                                                                ? "bg-secondary text-white text-sm font-medium border border-secondary rounded-full px-2 py-1 w-[65px] min-h-[31px] flex items-center justify-center hover:opacity-80 transform duration-200"
                                                                : "bg-white text-secondary text-sm font-medium border border-secondary rounded-full px-2 py-1 w-[65px] min-h-[31px] flex items-center justify-center hover:bg-primary hover:text-secondary transform duration-200"
                                                        }
                                                        onClick={() => {
                                                            const len =
                                                                arr.length;
                                                            const index =
                                                                arr.indexOf(
                                                                    getSlotValue(
                                                                        time,
                                                                        true
                                                                    )
                                                                );
                                                            const diff = moment(
                                                                getSlotValue(
                                                                    time,
                                                                    true
                                                                ),
                                                                "HH:mm"
                                                            ).diff(
                                                                arr[index - 1],
                                                                "minutes"
                                                            );
                                                            handleButtonClick(
                                                                time
                                                            );
                                                            setSelectedSlotIdx(
                                                                i
                                                            );
                                                            setBookingAtom({
                                                                ...bookingAtom,
                                                                startTime:
                                                                    getSlotValue(
                                                                        time,
                                                                        true
                                                                    ),
                                                                selectedSlot:
                                                                    getSlotValue(
                                                                        time,
                                                                        true
                                                                    )
                                                            });
                                                        }}
                                                        style={{
                                                            padding: "6px 10px",
                                                            width: 80
                                                        }}
                                                    >
                                                        {getSlotValue(
                                                            time,
                                                            true
                                                        )}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        //       )
                                        //   )
                                        // availableSlots?.map(
                                        //     (timeArray, indexx) => (
                                        <div className="flex flex-wrap justify-start items-start w-full gap-2">
                                            {availableSlots.map(
                                                (time, i, arr) => (
                                                    <button
                                                        key={getSlotValue(
                                                            time,
                                                            true
                                                        )}
                                                        className={
                                                            selectedButton ===
                                                            getSlotValue(
                                                                time,
                                                                false
                                                            )
                                                                ? "bg-secondary text-white text-sm font-medium border border-secondary rounded-full px-2 py-1 w-[65px] min-h-[31px] flex items-center justify-center hover:opacity-80 transform duration-200"
                                                                : "bg-white text-secondary text-sm font-medium border border-secondary rounded-full px-2 py-1 w-[65px] min-h-[31px] flex items-center justify-center hover:bg-primary hover:text-secondary transform duration-200"
                                                        }
                                                        onClick={() => {
                                                            const index =
                                                                arr.indexOf(
                                                                    getSlotValue(
                                                                        time,
                                                                        true
                                                                    )
                                                                );
                                                            const diff = moment(
                                                                getSlotValue(
                                                                    time,
                                                                    true
                                                                ),
                                                                "HH:mm"
                                                            ).diff(
                                                                arr[index - 1],
                                                                "minutes"
                                                            );
                                                            handleButtonClick(
                                                                time
                                                            );
                                                            setSelectedSlotIdx(
                                                                i
                                                            );
                                                            setBookingAtom({
                                                                ...bookingAtom,
                                                                startTime:
                                                                    getSlotValue(
                                                                        time,
                                                                        true
                                                                    ),
                                                                selectedSlot:
                                                                    getSlotValue(
                                                                        time,
                                                                        true
                                                                    )
                                                            });
                                                        }}
                                                        style={{
                                                            padding: "6px 10px",
                                                            width: 80
                                                        }}
                                                    >
                                                        {getSlotValue(
                                                            time,
                                                            true
                                                        )}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                        //     )
                                        // )
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="w-full flex flex-col items-center justify-center">
                            {allSlots.length ? (
                                <ComponentBookingInfo
                                    menuDivActive={!!selectedButton}
                                    date={dateString}
                                    day={day}
                                    time={selectedTime}
                                    onClose={onClose}
                                    jsonData={jsonData}
                                    setSelectedBookingData={
                                        setSelectedBookingData
                                    }
                                    setPrice={setPrice}
                                    setMins={setMins}
                                    endTime={endTime}
                                    startTime={startTime}
                                    pageSetter={pageSetter}
                                    allSlots={allSlots}
                                    originalCourts={originalCourts}
                                    setSelectedCourt={setSelectedCourt}
                                    setAvailableSlotState={
                                        setAvailableSlotState
                                    }
                                    timezone={timezone}
                                    setStartTime={setStartTime}
                                    setEndTime={setEndTime}
                                    basePrice={basePrice}
                                    setBasePrice={setBasePrice}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        }
    );
