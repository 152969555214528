import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BiSolidBadge } from "react-icons/bi";
import "./profile.css";
import { AuthContext } from "../AuthProvider";

const ProfileHero = ({
    userProfile,
    levelInfo,
    setSportType,
    sportType,
    setEnableInfoModal,
    enableInfoModal
}: {
    userProfile: any;
    levelInfo: any;
    setSportType: any;
    sportType: string;
    setEnableInfoModal: any;
    enableInfoModal: boolean;
}) => {
    const { t } = useTranslation();
    const currentUser = useContext(AuthContext);
    const navigate = useNavigate();
    const [playerSportsTypes, setPlayerSportsTypes] = useState<string[]>([]);

    useEffect(() => {
        if (!currentUser) {
            const queryParams = new URLSearchParams();
            queryParams.set("page", `/profile`);
            const queryString = queryParams.toString();
            const url = `/sign-in?${queryString}`;
            navigate(url);
        }
    }, [currentUser]);

    useEffect(() => {
        if (userProfile && userProfile.selectedSports) {
            const sportsType = removeDuplicates(userProfile.selectedSports);
            setPlayerSportsTypes(sportsType);
        }
    }, [userProfile]);

    function removeDuplicates(arr: string[]) {
        return [...new Set(arr)];
    }

    return (
        <div className="px-4 md:px-16 bg-[#FBFBFB] shadow">
            <div className='w-full max-w-[1412px] mx-auto h-[180px] sm:h-[250px] lg:h-[300px] xl:h-[350px] 2xl:h-[400px] bg-[url("../public/images/padelmateslogobig.png")] bg-no-repeat bg-cover bg-top relative'>
                <div className="absolute -bottom-[60px] sm:-bottom-[70px] lg:-bottom-[80px] xl:-bottom-[85px] 2xl:-bottom-[95px] flex left-[calc(50%-60px)] sm:left-[calc(50%-70px)] lg:left-[calc(50%-80px)] xl:left-[calc(50%-85px)] 2xl:left-[calc(50%-95px)]">
                    <img
                        src={userProfile?.profilePic || `/images/profile.png`}
                        className="w-[120px] h-[120px] sm:w-[140px] sm:h-[140px] lg:w-[160px] lg:h-[160px] xl:w-[170px] xl:h-[170px] 2xl:w-[190px] 2xl:h-[190px] rounded-full"
                        alt="profile avatar"
                    />
                    {userProfile?.pro ? (
                        <div className="absolute left-[calc(50%-26.5px)] -bottom-[8px] bg-[#FFD47C] text-xs w-[53px] h-[21px] flex justify-center items-center font-medium">
                            PRO
                        </div>
                    ) : (
                        <></>
                    )}

                    <div
                        className="absolute -right-1 -bottom-2 flex hover:cursor-pointer hover:scale-105 hover:opacity-90 transform duration-200"
                        onClick={() => navigate("/account-settings")}
                    >
                        <img src="/images/edit.svg" alt="edit" />
                    </div>
                </div>
            </div>
            <div className="text-center mt-20 sm:mt-24 lg:mt-28 flex flex-col items-center justify-center gap-3 pb-5">
                <h1 className="text-3xl xl:text-[35px] xl:leading-normal font-semibold capitalize">
                    {userProfile?.name}
                </h1>
                <div>
                    <div className="my-2 p-4 gap-2 flex justify-between items-center max-w-[100vw] overflow-x-auto my-custom-scrollbar">
                        {playerSportsTypes?.map((sport: string, i: number) => (
                            <div
                                key={i}
                                className={
                                    sportType === sport
                                        ? "rounded-full px-4 py-2 text-white bg-secondary hover:border hover:border-secondary cursor-pointer capitalize"
                                        : "rounded-full border border-secondary px-4 py-2 text-secondary hover:text-white hover:bg-secondary cursor-pointer capitalize"
                                }
                                onClick={() => setSportType(sport)}
                            >
                                {sport}
                            </div>
                        ))}
                    </div>
                    <div
                        className="flex items-center justify-center gap-2 cursor-pointer"
                        onClick={() => setEnableInfoModal(!enableInfoModal)}
                    >
                        <div className="relative w-[38px] h-[38px]">
                            <BiSolidBadge className="w-full h-full text-[#FFD47C]" />
                            <p className="text-[11px] font-medium absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 capitalize">
                                {userProfile?.level?.im}
                            </p>
                        </div>
                        <p>{t(levelInfo?.levelKey)}</p>
                    </div>
                </div>
                <div className="flex text-lg text-[#000b2999] font-medium items-center gap-2">
                    <p>
                        {t("Profile.followers")}:{" "}
                        <span> {userProfile?.followersCount} </span>
                    </p>
                    <div className="bg-gray-400 h-[18px] w-[1px]" />
                    <p>
                        {t("Profile.following")}:{" "}
                        <span>{userProfile?.followingCount} </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ProfileHero;
