import React from "react";

const ClubActivityCards = ({
    clubs,
    setSelectedClub
}: {
    clubs: any;
    setSelectedClub: any;
}) => {
    return (
        <div className="grid md:grid-cols-2 gap-2 pt-9 p-4 md:px-16 w-[80%] m-auto">
            {clubs.map((club: any) => (
                <div
                    key={club._id} // Make sure to add a unique key when mapping over an array
                    className="rounded-[10px] shadow border border-[#000b29] border-opacity-[0.15] px-2 py-5 sm:px-4"
                    onClick={() => {setSelectedClub(club); localStorage.setItem('selectedClubID', club._id);}}
                >
                    <div className="flex gap-5 items-center w-full md:w-[calc(80%-1.125rem)]">
                        <div className="flex overflow-hidden  min-w-fit min-h-fit m-0 self-center hover:cursor-pointer">
                            <img
                                src={club.photo_url}
                                className="w-[102px] h-[102px] min-h-[50px] object-unset border border-[#000b2966] rounded-[6px]"
                                alt="Club Profile"
                            />
                        </div>
                        <div className="text-[#00081e] flex flex-col">
                            <div className="hover:cursor-pointer">
                                <h3 className="font-medium hover:cursor-pointer">
                                    {club.name}
                                </h3>
                                <p className="text-[19px]">{club.city}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ClubActivityCards;
