// @ts-nocheck

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClubProfileHero from "./ClubProfileHero";
import ClubProfileContent from "./ClubProfileContent";
import { useParams } from "react-router";
import { AuthContext } from "../AuthProvider";
import { getClubProfile, getClubProfileData, getCredits } from "../../Api/api";
import BookingModal from "../booking/BookingModal";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useBookingAtom } from "../../Utilities/clubStore";

import { HiPlus } from "react-icons/hi";

const publishableKey = process.env.REACT_APP_STRIPE_KEY as string;

const stripePromise = loadStripe(publishableKey);

const ClubProfileMain = () => {
    const currentUser = useContext(AuthContext);
    const [clubData, setClubData] = useState<any>(null);

    const [price, setPrice] = useState<number>(0);
    const [basePrice, setBasePrice] = useState<number>(0);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [mins, setMins] = useState<number>(0);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [modalPage, setModalPage] = useState(1);
    const [selectedSlot, setSelectedSlot] = useState<string>(
        moment().format("HH:mm")
    );
    const [availableSlotState, setAvailableSlotState] = useState(null);
    const [selectedCourt, setSelectedCourt] = useState<any>(null);
    const [userCredit, setUserCredit] = useState(0);
    const [modalActive, setModalActive] = useState(false);
    const [showModal, setshowModal] = useState<boolean>(true);
    const navigate = useNavigate();
    const [isSlot, setIsSlot] = useState(true);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const { id } = useParams<{ id: string }>();
    const [bookingAtom, setBookingAtom] = useBookingAtom();

    useEffect(() => {
        if (currentUser) {
            getCurrentCubProfile(id, currentUser?.uid);
        } else {
            getCubProfileData(id);
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser && clubData?._id) {
            getCredits(clubData._id as string)
                .then((resp) => {
                    setUserCredit(resp);
                })
                .catch(() => {});
        }
    }, [clubData]);

    const getCurrentCubProfile = async (clubId: any, playerId: string) => {
        try {
            getClubProfile(clubId, playerId).then((resp: any) => {
                if (resp?.status === 200) {
                    if (!resp.data._id && resp.data.club_id) {
                        resp.data._id = resp.data.club_id;
                    }
                    setClubData(resp?.data);
                    if (isSlot) {
                        setBookingAtom({
                            ...bookingAtom,
                            page: 2,
                            clubData: resp?.data
                        });
                    } else {
                        setBookingAtom({
                            ...bookingAtom,
                            page: 13,
                            clubData: resp?.data
                        });
                    }
                }
            });
        } catch (error: any) {
            console.log(
                "🚀 ~ file: ClubProfileMain.tsx:79 ~ getCubProfile ~ error:",
                error
            );
        }
    };

    const getCubProfileData = async (clubId: any) => {
        try {
            getClubProfileData(clubId).then((resp: any) => {
                if (resp?.status === 200) {
                    if (!resp.data._id && resp.data.club_id) {
                        resp.data._id = resp.data.club_id;
                    }
                    setClubData(resp.data);
                    setBookingAtom({
                        ...bookingAtom,
                        page: 1,
                        clubData: resp?.data
                    });
                }
            });
        } catch (error: any) {
            console.log(
                "🚀 ~ file: ClubProfileMain.tsx:92 ~ getCubProfileData ~ error:",
                error
            );
        }
    };

    const openOrCloseModal = () => {
        setModalActive(!modalActive);
    };

    return (
        <div className="bg-white text-secondary pb-20">
            <div className="">
                <ClubProfileHero clubData={clubData} />
            </div>
            <ClubProfileContent clubData={clubData} userCredit={userCredit} setIsSlot={setIsSlot}
                selectedActivity={selectedActivity}
                setSelectedActivity={setSelectedActivity} />
            <Elements stripe={stripePromise}>
                <BookingModal
                    price={price}
                    setPrice={setPrice}
                    mins={mins}
                    setMins={setMins}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    page={modalPage}
                    pageSetter={setModalPage}
                    show={modalActive}
                    onClose={openOrCloseModal}
                    currency={clubData?.currency}
                    selectedCourt={selectedCourt}
                    availableSlotState={availableSlotState}
                    selectedSlot={selectedSlot}
                    basePrice={basePrice}
                    setBasePrice={setBasePrice}
                    bookingData={clubData}
                    setIsShown={setIsShown}
                    isSlot={isSlot}
                    setSelectedActivity={setSelectedActivity}
                />
            </Elements>
        </div>
    );
};

export default ClubProfileMain;
