import React, { useEffect, useState } from "react";
import DiscoverActivities from "../Components/activities/DiscoverActivities";
import ParticipateInActivities from "../Components/activities/ParticipateInActivities";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { getSearchNearbyClubs } from "../Api/api";
import { SportType } from "./BookingPage";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import PopupInfo from "../Components/booking/PopupInfo";
import ClubActivityCards from "./ClubActivityCards";

const Activities = () => {
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [allClubActivities, setAllClubActivities] = useState<any>(null);
    const [clubActivities, setClubActivities] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);
    const [showActivities, setShowActivities] = useState<any>(false);
    const [date, setDate] = useState<Moment>(moment());
    const [clubs, setClubs] = useState<any>([]);
    const [sports, setSports] = useState<SportType[]>([
        "padel",
        "tennis",
        "squash",
        "badminton",
        "pickleball"
    ]);
    const [loadingClubs, setLoadingClubs] = useState<boolean>(false);
    const [selectedClub, setSelectedClub] = useState<any>(null);
    const { t } = useTranslation();

    const searchNearByClubs = async (clubName: string, date: any) => {
        setClubs([]);
        let data = {
            radius: 50,
            latitude: 78.9012,
            longitude: 12.3456,
            sports,
            clubName,
            selectedSport: [],
            date,
            currentUser
        };

        const defaultResult = await getSearchNearbyClubs(data);
        setLoadingClubs(false);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult.data.searched_clubs.length > 0
        ) {
            setClubs(
                defaultResult.data.searched_clubs.filter(
                    (club: any) => club.permissions.mobile_booking === true
                )
            );
        }
    };

    useEffect(() => {
        if (!loadingClubs && !clubs.length) {
            setLoadingClubs(true);
            searchNearByClubs("", moment(date)?.format("YYYY/MM/DD") ?? "");
        }
    }, [currentUser]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>{t("Title.activities")} | Padel Mates</title>
            </Helmet>
            <PopupInfo
                title=""
                subTitle=""
                isOpen={loadingClubs}
                setIsOpen={setLoadingClubs}
                isLoading={true}
            />
            <div className="text-secondary w-full">
                <DiscoverActivities
                    clubActivities={clubActivities}
                    allClubActivities={allClubActivities}
                    setAllClubActivities={setAllClubActivities}
                    setClubActivities={setClubActivities}
                    setLoading={setLoading}
                    setShowActivities={setShowActivities}
                    date={date}
                    setDate={setDate}
                    selectedClub={selectedClub}
                />
                {showActivities ? (
                    <ParticipateInActivities
                        allClubActivities={allClubActivities}
                        clubActivities={clubActivities}
                        setClubActivities={setClubActivities}
                        loading={loading}
                        date={date}
                        setDate={setDate}
                        setAllClubActivities={setAllClubActivities}
                        setLoading={setLoading}
                    />
                ) : clubs.length ? (
                    <ClubActivityCards
                        clubs={clubs}
                        setSelectedClub={setSelectedClub}
                    />
                ) : (
                    <div className="w-full text-center py-16">
                        <p>{t("Activities.searchFavoriteClub")}</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default Activities;
