// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const vapidKey = process.env.REACT_APP_VAPID_KEY;

const firebaseConfig = {
    apiKey: firebaseApiKey,
    authDomain: "padel-mates-e1167.firebaseapp.com",
    projectId: "padel-mates-e1167",
    storageBucket: "padel-mates-e1167.appspot.com",
    messagingSenderId: "1032184127928",
    appId: "1:839575022441:web:cd1a27956908374d9b6bf8",
    measurementId: "G-H93222W1Y9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
const analytics = getAnalytics(app);

// const messaging = getMessaging();
// export const message = getToken(messaging, {
//     vapidKey: vapidKey
// })
//     .then((currentToken) => {
//         if (currentToken) {
//             console.log(currentToken);

//             localStorage.setItem("fcmToken", currentToken);
//         } else {
//             console.log(
//                 "No registration token available. Request permission to generate one."
//             );
//         }
//     })
//     .catch((err) => {
//         console.log("An error occurred while retrieving token. ", err);
//     });
