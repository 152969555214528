import { atom, useAtom } from "jotai";

type ClubAtomState = {
    id: string;
    startTime: any;
    endTime: any;
};

type BookingAtomState = {
    name: string;
    photo_url: string;
    price: number;
    basePrice: number;
    startTime: string;
    endTime: string;
    mins: string;
    selectedCourt: any;
    selectedSlot: any;
    availableSlotState: any;
    page: number;
    clubData: any;
    show: boolean;
    currency: string;
    showMembershipModal: boolean;
    selectedMembership: any;
    clubActivity: any;
    isTournamentType: boolean;
    isTrainingType: boolean;
    isFromLoginButton?: boolean;
};

export const clubAtom = atom<ClubAtomState>({
    id: "",
    startTime: "",
    endTime: ""
});

export const bookingAtom = atom<BookingAtomState>({
    price: 0,
    basePrice: 0,
    startTime: "",
    endTime: "",
    mins: "",
    selectedCourt: null,
    selectedSlot: null,
    availableSlotState: null,
    page: 1,
    clubData: null,
    show: false,
    currency: "SEK",
    showMembershipModal: false,
    selectedMembership: null,
    clubActivity: null,
    name: "",
    photo_url: "",
    isTournamentType: false,
    isTrainingType: false,
    isFromLoginButton: false
});

export const useClubAtom = () => {
    return useAtom(clubAtom);
};

export const useBookingAtom = () => {
    return useAtom(bookingAtom);
};
