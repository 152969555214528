// @ts-nocheck

import { useContext, useEffect, useState } from "react";
import BookingCard from "./BookingCard";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../AuthProvider";
import { ActivityType, getActivities, getClubProfile } from "../../Api/api";
import { CgSpinner } from "react-icons/cg";
import { useBookingAtom } from "../../Utilities/clubStore";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BookingModal from "../booking/BookingModal";

const publishableKey = process.env.REACT_APP_STRIPE_KEY as string;

const stripePromise = loadStripe(publishableKey);
const Bookings = () => {
    const { t } = useTranslation();
    const [showTab, setShowTab] = useState(`${t("Profile.upcoming")}`);
    const tabs = [`${t("Profile.upcoming")}`, `${t("Profile.played")}`];
    const [bookings, setBookings] = useState<any>(null);
    const [getBookings, setGetBookings] = useState<boolean>(true);
    const [clubData, setClubData] = useState<any>(null);
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    const currentUser = useContext(AuthContext);

    useEffect(() => {
        if (currentUser && getBookings) {
            const data = {
                playerId: currentUser.uid,
                isPlayedActivity: showTab === "Played"
            };
            getActivities(data).then((activitiesResp: ActivityType[] | any) => {
                if (activitiesResp) {
                    const filteredActivities = activitiesResp.filter(
                        (activity: any) => activity.activityType
                    );
                    setBookings(filteredActivities);
                    setGetBookings(false);
                }
            });
        }
    }, [currentUser, getBookings, showTab]);

    useEffect(() => {
        const fetchClubData = async () => {
            try {
                if (currentUser) {
                    const clubData = await getClubProfile(bookingAtom?.clubData?._id, currentUser?.uid);
                    if (clubData?.status === 200) {
                        if (!clubData.data._id && clubData.data.club_id) {
                            clubData.data._id = clubData.data.club_id;
                        }
                        setClubData(clubData.data);
                    };
                }
            } catch (error) {
                console.log('error: ', error);
            }
        }
        if (bookingAtom?.show && (bookingAtom?.page === 131 || bookingAtom?.page === 4)) {
            fetchClubData();
        }
    }, [bookingAtom?.show, bookingAtom?.page]);

    return (
        <div className="bg-[#FBFBFB] flex flex-col px-2 sm:px-5 pt-5 pb-12 rounded-2xl shadow">
            <h2 className="text-2xl text-center font-semibold">
                {t("Profile.myBookings")}
            </h2>
            <div className="mt-8 mb-16 text-lg flex flex-wrap items-center justify-center gap-3">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        onClick={() => {
                            setGetBookings(true);
                            setShowTab(tab);
                        }}
                        className={`${
                            showTab === tab
                                ? "bg-secondary text-white"
                                : "bg-transparent text-secondary hover:bg-primary hover:text-secondary transform duration-200"
                        } w-full max-w-[172px] h-[42px] border border-secondary font-medium rounded-2xl`}
                    >
                        {tab}
                    </button>
                ))}
            </div>

            {getBookings ? (
                <div
                    className={`flex flex-col items-center justify-center h-full w-full`}
                >
                    <div className="flex flex-col gap-4 items-center justify-center">
                        <CgSpinner className="animate-spin text-[64px]" />
                    </div>
                </div>
            ) : null}
            {!getBookings && bookings && bookings.length ? (
                bookings.map((booking: ActivityType) => (
                    <div className="flex flex-col gap-7" key={booking._id}>
                        <BookingCard
                            booking={booking}
                            setGetBookings={setGetBookings}
                            showTab={showTab}
                            clubData={clubData}
                            setClubData={setClubData}
                        />

                        {/* <TeamCard />
                            <MatchCard />
                            <TrainingCard />
                        */}
                    </div>
                ))
            ) : !getBookings ? (
                <p className="text-[22px] text-[#666B78] text-center">
                    {t("Profile.noBookings")}
                </p>
            ) : (
                <></>
            )}
            <Elements stripe={stripePromise}>
                <BookingModal { ...bookingAtom } bookingData={clubData} setGetBookings={setGetBookings} isSlot={false} />
            </Elements>
        </div>
    );
};

export default Bookings;
