// @ts-nocheck

import { useStripe, useElements } from "@stripe/react-stripe-js";
import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState} from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import {
    IBookingData,
    applyCoupon,
    bookCourt,
    checkAvailableSlot,
    getClubProfile,
    getUserInfo,
    handleCreditPayment,
    updateUserInfo,
    getAmericanoTournamentPlayers,
    getTournamentPlayers,
    getTournamentById,
    isInTournament,
    leaveTournament,
    joinTeamAmericanoTournament,
    requestToJoinTeamAmericano,
    isRequestedteam,
    declineRequestTeamAmericano,
    joinTournament,
    resendEmail,
    activateEmail,
    userLogin,
    isInvitedTournament,
    getTournamentQueue,
    isRequested,
    deleteAmericanoTeam,
    isQueueTeam,
    isQueue,
    declineTournamentRequest,
    removeFromQueueForTeamAmericano,
    removeFromQueue,
    isInvitedTeamAmericano,
    getMembershipDiscounts,
    isInTournamentTeamAmericano
} from "../../Api/api";
import { AuthContext } from "../AuthProvider";
import BookingDetails from "./BookingDetails";
import CourtComponent from "./CourtComponent";
import ExtraTimeManager from "./ExtraTimeManager";
import PaymentComponent from "./PaymentComponent";
import PromoComponent from "./PromoComponent";
import ShareWithFriends from "./ShareWithFriends";
import SubTotalComponent from "./SubTotalComponent";
import BecomeMemberForm from "./BecomeMemberForm";
import { t } from "i18next";
import { CgSpinner } from "react-icons/cg";
import { FcGoogle } from "react-icons/fc";
import { BsEyeFill, BsEyeSlash, BsFacebook } from "react-icons/bs";
import {
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import { auth } from "../../firebase";
import { handleLoggedInUser } from "../../Pages/SignIn";
import { NOTIFICATION_TYPE, Store } from "react-notifications-component";
import { useBookingAtom } from "../../Utilities/clubStore";
// import { a } from "react-router-dom";
import Coupon from "../Coupon";
import Axios from "../../Api/Axios";
import useTogglePasswordView from "../../hooks/useTogglePasswordView";
import { ICard } from "../../Api/usePayments";
import { ConfirmCardPaymentData } from "@stripe/stripe-js";
import UpdateUserInfo from "./UpdateUserInfo";
import { FaArrowRight } from "react-icons/fa6";
import { HiPlus } from "react-icons/hi";
import { IoSearch } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoIosLock } from "react-icons/io";
import PopupInfo from "./PopupInfo";
import AxiosFastAPI from "../../Api/AxiosFastAPI";
import OTPInput from "react-otp-input";
import { getUserProfile } from "../../Api/api";
import { FaUserCircle } from "react-icons/fa";
import AlertPopup from "./AlertPopup";
import dummyProfileNew from "../../widgets/bookingWidget/bookingModal/images/dummyProfileNew.png";
import AddPlayer from "./AddPlayer";
import ProfileELOBadge from "../profile/ProfileEloBadge/ProfileEloBadge";
import { correctLevelWithPoints } from "../../Utilities/utils";
import { Modal } from "../ui/Modal";

interface BookingModalProps {
    bookingData: any;
    setPrice?: React.Dispatch<React.SetStateAction<number>>;
    page?: number;
    pageSetter?: React.Dispatch<React.SetStateAction<number>>;
    show?: boolean;
    startTime?: any;
    onClose?: () => void;
    setIsShown?: React.Dispatch<React.SetStateAction<boolean>>;
    setMins?: React.Dispatch<React.SetStateAction<number>>;
    mins?: any;
    setEndTime?: any;
    endTime?: any;
    setStartTime?: any;
    currency?: string;
    price?: any;
    selectedCourt?: any;
    availableSlotState?: any;
    selectedSlot?: any;
    basePrice?: number;
    setBasePrice?: any;
    isSlot?: boolean;
    setGetBookings?: any;
}
interface Player {
    profilePic: string;
    name: string;
    email?: string;
    // Add other properties if available
}

interface TournamentAmericano {
    player1: Player;
    player2: Player;
    // Add other properties if available
}

interface Tournament {
    profilePic: string;
    name: string;
}

interface ClosableOverlayProps {
    children: React.ReactNode;
    onClick: (evt: React.MouseEvent) => void;
}

export interface IPaymentIntent {
    success: boolean;
    message: string;
    clientSecret: string;
    subscriptionId: string;
    paymentIntentId: string;
    transactionId: string;
    paymentStatus: string;
    chargeId: string;
    receiptUrl?: string;
}

const ClosableOverlay: React.FC<ClosableOverlayProps> = ({
    children,
    onClick
}) => {
    return (
        <>
            <div
                className="wrapper-overlay fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000080] z-[4000]"
                onClick={onClick}
            >
                {children}
            </div>
        </>
    );
};

const BookingModal: React.FC<BookingModalProps> = ({
    bookingData,
    page,
    pageSetter,
    show,
    onClose,
    setPrice,
    setIsShown,
    setMins,
    mins,
    setEndTime,
    endTime,
    setStartTime,
    startTime,
    currency,
    price,
    selectedCourt,
    availableSlotState,
    selectedSlot,
    basePrice,
    isSlot = true,
    setGetBookings,
    setSelectedActivity
}) => {
    // console.log("BookingModal Render call check");
    const [paymentMethodId, setPaymentMethodId] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState<boolean>(false);
    const [bookingStatus, setBookingStatus] = useState<any>(true);
    const [isLoginError, setIsLoginError] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [isLoadingPayment, setIsLoadingPayment] = useState(false);
    const [infoPopupTitle, setInfoPopupTitle] = useState(
        `${t("processingThePayment")}...`
    );
    const [infoPopupSubtitle, setInfoPopupSubtitle] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [selectedPlayerValue, setSelectedPlayerValue] = useState<any>(null);
    const { showPassword, togglePasswordView } = useTogglePasswordView();
    const [tournamentAmericanoData, setTournamentAmericanoData] = useState<
        TournamentAmericano[]
    >([]);
    const [tournamentData, setTournamentData] = useState<Tournament[]>([]);
    const [tournamentDataMain, setTournamentDataMain] = useState<any>(null);
    const [found, setFound] = useState<boolean>(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const currentUser = useContext(AuthContext);
    const selectedDateFromStorage = localStorage.getItem("selectedDate");
    const selectedDate: any = selectedDateFromStorage
        ? selectedDateFromStorage.replace(/-/g, "/").replace(/["\\]/g, "")
        : new Date();

    const [errorMessage, setErrorMessage] = useState<string | undefined>("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [applyingVoucher, setApplyingVoucher] = useState<boolean>(false);
    const [voucherSuccessMessage, setVoucherSuccessMessage] = useState<
        string | undefined
    >("");
    const [voucherErrorMessage, setVoucherErrorMessage] = useState<
        string | undefined
    >("");
    const [voucher, setVoucher] = useState<any>(null);
    const [discountAmount, setDiscountAmount] = useState<Number>(0);
    const [bookingAtom, setBookingAtom] = useBookingAtom();

    const [isSelected, setIsSelected] = useState("card");
    const [userCredit, setUserCredit] = useState(0);
    const [clubData, setClubData] = useState<any>(null);

    const [accessCode, setAccessCode] = useState(null);

    const [cardDetails, setCardDetails] = useState<any>();

    const [cardElement, setCardElement] = useState<any>(null);

    const [saveCardForFutureUse, setSaveCardForFutureUse] = useState(false);

    const [receiptUrl, setReceiptUrl] = useState("");

    const [userName, setUserName] = useState("");
    const [lockName, setName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [profilePic, setProfilePic] = useState("");
    const [showHeading, setshowHeading] = useState<boolean>(false);
    const [userLevel, setUserLevel] = useState<number | null>(null);
    const [playerObject, setplayerObject] = useState<Player[]>([]);
    const [isRequest, setIsReuest] = useState(false);

    const [userData, setUserData] = useState({});
    const [invite, setInvited] = useState(false);
    const [queue, setQueue] = useState([]);
    const [isQueued, setIsQueued] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState(false);

    const [membershipDiscount, setMembershipDiscount] = useState({});
    const [membershipDiscountAmount, setMembershipDiscountAmount] = useState(0);
    const [membershipData, setMembershipData] = useState({});

    const [shouldApplyForQueue, setShouldApplyForQueue] = useState(false);
    const [isLeaveModal, setIsLeaveModal] = useState<boolean>(false);
    const [fillColor, setFillColor] = useState<string | null>(null);
    
    const [inputCode, setInputCode] = useState("");
    const [openOtpModal, setOpenOtpModal] = useState(false);
    const [isPopupInProgress, setIsPopupInProgress] = useState<boolean>(false);

    const confirmationFunc = () => {
        if (tournamentDataMain?.gameCode === inputCode) {
            setInputCode('');
            setOpenOtpModal(false);
            if (bookingAtom.clubActivity?.tournament_type === "team_americano") {
                setBookingAtom({
                    ...bookingAtom,
                    page: 31
                });
            }
            else {
                setBookingAtom({
                    ...bookingAtom,
                    page: 14
                });
            }
        } else {
            Store.addNotification({
                title: null,
                message: t("invalidCode"),
                type: "danger",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000
                }
            });
        }
      }

    const handleFillChange = (fill: string) => {
        setFillColor(fill);
      };

    const totalDiscount = () => {
        let discountValue = 0; // Corrected typo in variable name

        if (membershipDiscount && currentUser) {
            discountValue = membershipDiscount[currentUser?.uid] ?? 0; // Use nullish coalescing to default to 0
        }

        if (voucher?.amount_in_percentage) {
            discountValue += voucher?.amount_in_percentage ?? 0; // Ensure fallback to 0 if undefined
        }

        if (discountValue > 100) {
            return 100;
        }

        return discountValue || 0; // Return 0 if the result is falsy
    };

    useEffect(()=>{
        const isUserCoachCenter = tournamentDataMain?.email === currentUser?.email && tournamentDataMain?.isCoach === 'center';
        const isInvitationPresent = !!invite;
        let isTournamentFull;
        // const isTournamentFull = tournamentDataMain?.players?.length >= tournamentData.numberOfPlayers && !replacementUserId;
        if(bookingAtom?.clubActivity?.current_no_of_players >= bookingAtom?.clubActivity?.no_of_players){
            isTournamentFull = true;
            setShouldApplyForQueue(!isUserCoachCenter && !isInvitationPresent && !found && isTournamentFull);
        }
        else{
            isTournamentFull = false;
            setShouldApplyForQueue(!isUserCoachCenter && !isInvitationPresent && !found && isTournamentFull);
        }
    }, [tournamentDataMain?.email, tournamentDataMain?.isCoach, invite, currentUser?.email, found, bookingAtom?.clubActivity?.current_no_of_players, bookingAtom?.clubActivity?.no_of_players]);


    const getCubProfile = async (clubId: any, playerId: string) => {
        try {
            const resp = await getClubProfile(clubId, playerId);
            if (resp?.status === 200) {
                setClubData({ ...resp.data });
            }
        } catch (error: any) {
            console.log("loading => getCubProfile error", error);
        }
    };

    useEffect(() => {
        if (!voucher) {
            localStorage.removeItem("voucherSuccessMsg");
        }
    }, [voucher]);

    useEffect(() => {
        if ((bookingAtom.page === 13 || bookingAtom.page === 14 ) && bookingAtom &&
            bookingAtom !== null &&
            bookingAtom?.clubActivity?.start_datetime !== null) {
            let startTime = new Date(bookingAtom?.clubActivity?.start_datetime);
            const formattedDate = moment.tz(startTime, bookingAtom?.clubActivity?.timezone)?.format("YYYY-MM-DD");
            localStorage.setItem("selectedDate", formattedDate);
        }
    },[bookingAtom, bookingAtom?.clubActivity?.start_datetime])

    useEffect(() => {
        console.log(
            "bookingAtom value:: " + JSON.stringify(bookingAtom?.clubActivity)
        );
        if (
            bookingAtom &&
            bookingAtom !== null &&
            bookingAtom?.clubActivity?.start_datetime !== null &&
            bookingAtom?.clubActivity?.stop_datetime !== null
        ) {
            let startTime = new Date(bookingAtom?.clubActivity?.start_datetime);
            let endTime = new Date(bookingAtom?.clubActivity?.stop_datetime);
            let difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
            let resultInMinutes = Math.round(difference / 60000);
            const startTimeString = moment.tz(bookingAtom?.clubActivity?.start_datetime, bookingData?.timezone).format("HH:mm");
            const endTimeString = moment.tz(bookingAtom?.clubActivity?.stop_datetime, bookingData?.timezone).format("HH:mm");

            setBookingAtom({
                ...bookingAtom,
                startTime: startTimeString,
                endTime: endTimeString,
                mins: resultInMinutes.toString()
            });
        }
    }, [bookingAtom?.clubActivity?.start_datetime]);

    useEffect(() => {
        if (bookingData?._id && currentUser?.uid) {
            getCubProfile(bookingData?._id, currentUser?.uid);
        }
    }, [bookingData?._id, currentUser?.uid, bookingAtom.show]);

    const minutesSetter = (amountOfMin: any): void => {
        setBookingAtom({ ...bookingAtom, mins: amountOfMin });
    };
    const priceSetter = (moneyValue: any): void => {
        setBookingAtom({ ...bookingAtom, price: moneyValue });
    };

    const calculateHours = (): void => {
        console.log("first");
    };

    const createBooking = (resp: any) => {
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");

        const bookingDataForEndpoint = {
            total_price: Number(bookingAtom.price.toFixed(2)),
            discount: totalDiscount(),
            code: voucher?.code || "",
            discountCode: voucher?.code || '',
            info: `Court booked by ${
                currentUser.displayName
            } for ${bookingData?.name} on ${moment(selectedDate).format(
                "DD MMM YYYY HH:mm"
            )}`,
            transaction_id: resp.data.transactionId,
            payment_status: resp.data.paymentStatus,
            court_ids: [bookingAtom.selectedCourt._id],
            players: [],
            start_datetime: startTime.utc().valueOf(),
            stop_datetime: endTime.utc().valueOf(),
            membershipData: membershipData
        };
        const bookData: IBookingData = {
            club_id: bookingData._id,
            bookData: bookingDataForEndpoint
        };
        bookCourt(bookData)
            .then((bookingRes: any) => {
                setBookingStatus(bookingRes.success);
                if (bookingRes.success) {
                    setAccessCode(bookingRes?.user?.access_code);
                    setBookingAtom({
                        ...bookingAtom,
                        page: 4
                    });
                    return bookingRes;
                }
            })
            .catch((e) => setBookingStatus(false));
    };

    const MAX_RETRY_ATTEMPTS = 5;
    const RETRY_INTERVAL_MS = 4000; // 4 seconds

    const handleBookingRetries = async (resp: any, retryCount: number = 0) => {
        if (retryCount >= MAX_RETRY_ATTEMPTS) {
            setBookingStatus(false); // Handle the booking failure state here if needed
            return;
        }

        try {
            const bookingResp: any = await createBooking(resp);
            if (bookingResp.success) {
                console.log("loading => Booking status is success!");
            } else {
                console.log(
                    "loading => Payment status is still processing. Retrying in 5 seconds..."
                );
                setTimeout(() => {
                    handleBookingRetries(resp, retryCount + 1);
                }, RETRY_INTERVAL_MS);
            }
            // Booking succeeded
        } catch (error) {
            console.log({ error });
        }
    };

    const handlePayment = async (event: any) => {
        console.log(
            "handlePayment called with page === :: " + bookingAtom.page
        );
        if (
            (bookingAtom.page === 14 ||
            bookingAtom.isTournamentType === true ||
            bookingAtom.isTrainingType === true) && !isSlot
        ) {
            handlePaymentTournament(event);
            return;
        }
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");
        const startDatetime = startTime.valueOf();
        const endDatetime = endTime.valueOf();
        if (isSelected === "card") {
            if (!stripe || !elements) return;

            if (cardDetails && !cardDetails?.complete) {
                setErrorMessage(`${t("BookingModal.fillCardFields")}`);
                setErrorOpen(true);
                setProcessing(false);
                return;
            }
            if (
                !cardDetails &&
                (paymentMethodId === undefined || paymentMethodId === "")
            ) {
                setErrorMessage(`${t("BookingModal.noCardAvailable")}`);
                setErrorOpen(true);
                setProcessing(false);
                return;
            }
            event.preventDefault();
            const _bookingData = {
                total_price: Number(bookingAtom.price.toFixed(2)),
                discount: totalDiscount(),
                code: voucher?.code || "",
                info: `Court booked by ${
                    currentUser.displayName
                } at ${bookingData?.name} on ${moment(selectedDate).format(
                    "DD MMM YYYY HH:mm"
                )}`,
                court_ids: [bookingAtom?.selectedCourt?._id],
                players: [],
                start_datetime: startTime.utc().valueOf(),
                stop_datetime: endTime.utc().valueOf()
            };
            try {
                //setProcessing(true);
                setIsLoadingPayment(true);
                setErrorMessage("");

                const paymentIntentParams: Record<string, any> = {
                    amount: Number(bookingAtom.price.toFixed(2)),
                    payFor: "booking",
                    clubId: bookingData._id,
                    isSupportAutoJoinFlow: true,
                    autoJoinAdditionalInfo: {
                        bookData: _bookingData
                    },
                    discountCode: voucher?.code || '',
                    startTime: startDatetime,
                    endTime: endDatetime,
                    membershipData: membershipData
                };

                /*
                As react-stripe-js doesn't provide details info about cardElement like mobile package,
                I am calling stripe token api to get details info like last4, expiryMonth, expiryYear etc.
                */
                let _cardDetailsFromStripeTokenForIntent;
                if (cardDetails && cardElement) {
                    const { token, error } = await stripe.createToken(
                        cardElement
                    );
                    if (token && !error) {
                        _cardDetailsFromStripeTokenForIntent = {
                            last4: token.card?.last4,
                            expiryMonth: token.card?.exp_month,
                            expiryYear: Number(
                                token.card?.exp_year.toString().slice(-2)
                            ),
                            complete: true,
                            brand: token.card?.brand,
                            validExpiryDate: "Valid",
                            validNumber: "Valid",
                            validCVC: "Valid"
                        };
                    }
                }

                // add cardDetails based on checkbox for future usage
                paymentIntentParams.cardDetails = saveCardForFutureUse
                    ? _cardDetailsFromStripeTokenForIntent || cardDetails
                    : null;
                console.log("payment intent 1");

                if (bookingAtom.price <= 0) {
                    const time = moment(selectedDate)?.format("DD MM YYYY");
                    const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
                    const startTime = moment.tz(
                        startTimeString,
                        "DD MM YYYY HH:mm",
                        bookingData?.timezone
                    );
                    const endTime = startTime
                        .clone()
                        .add(bookingAtom.mins, "minutes");

                    const bookingDataForEndpoint = {
                        total_price: Number(bookingAtom.price.toFixed(2)),
                        discount: totalDiscount(),
                        code: voucher?.code || "",
                        discountCode: voucher?.code || '',
                        info: `Court booked by ${
                            currentUser.displayName
                        } for ${bookingData?.name} on ${moment(
                            selectedDate
                        ).format("DD MMM YYYY HH:mm")}`,
                        transaction_id: "",
                        payment_status: "PAYMENT_SUCCESS",
                        court_ids: [bookingAtom.selectedCourt._id],
                        players: [],
                        start_datetime: startTime.utc().valueOf(),
                        stop_datetime: endTime.utc().valueOf(),
                        membershipData: membershipData
                    };
                    const bookData: IBookingData = {
                        club_id: bookingData._id,
                        bookData: bookingDataForEndpoint
                    };
                    bookCourt(bookData)
                        .then((bookingRes: any) => {
                            setBookingStatus(bookingRes.success);
                            if (bookingRes.success) {
                                setAccessCode(bookingRes?.user?.access_code);
                                setIsLoadingPayment(false);
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 4
                                });
                                return bookingRes;
                            }
                        })
                        .catch((e) => {
                            setBookingStatus(false)
                            setErrorMessage(e?.data?.detail?.message || "Some error occurred while trying to book!");
                            setErrorOpen(true);
                        });
                } else {
                    try {
                        const { data: intent } = await Axios.post(
                            "/payment/createPaymentIntent",
                            paymentIntentParams
                        );

                        const confirmCardPaymentParams: ConfirmCardPaymentData = {};

                        if (paymentMethodId && !cardDetails) {
                            confirmCardPaymentParams.setup_future_usage =
                                "off_session";
                        }

                        if (cardDetails && cardElement) {
                            confirmCardPaymentParams.payment_method = {
                                card: cardElement,
                                billing_details: {
                                    name: userName,
                                    email: userEmail
                                }
                            };
                        } else if (paymentMethodId) {
                            confirmCardPaymentParams.payment_method =
                                paymentMethodId; // only send for existing cards
                        }

                        const confirmPayment = await stripe.confirmCardPayment(
                            intent.clientSecret,
                            confirmCardPaymentParams
                        );

                        // Clear card details
                        setCardDetails(null);

                        if (!confirmPayment?.error) {
                            let responseFromChargeCheck =
                                await checkPaymentChargeWithCondition(
                                    intent?.paymentIntentId
                                );
                            if (responseFromChargeCheck?.data?.success) {
                                setAccessCode(
                                    responseFromChargeCheck.data?.user?.access_code
                                );
                                setIsLoadingPayment(false);
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 4
                                });
                                setReceiptUrl(
                                    responseFromChargeCheck?.data?.receiptUrl
                                );
                            }
                            // return responseFromChargeCheck.data;
                        }
                    } catch (error) {
                        console.log('error: ', error);
                        setErrorMessage(error?.response?.data?.message);
                        setErrorOpen(true);
                    }
                }
            } catch (error) {
                console.log(error);
                setErrorMessage(error?.response?.data?.message || "Some error occurred while trying to book!");
                setErrorOpen(true);
            }
            setProcessing(false);
            setIsLoadingPayment(false);
        } else if (isSelected === "credit") {
            console.log("this speak 1");

            event.preventDefault();
            // if (
            //     (!clubData && !bookingData?.following) ||
            //     (clubData && !clubData?.following)
            // ) {
            //     setErrorMessage(`${t("BookingModal.notFollowerOfClub")}`);
            //     setErrorOpen(true);
            //     setProcessing(false);
            //     return;
            // } else
            if (userCredit < bookingAtom.price) {
                setErrorMessage(`${t("BookingModal.enoughCredits")}`);
                setErrorOpen(true);
                setProcessing(false);
                return;
            }

            const payFor = "booking";
            const clubId = bookingData._id as string;
            const totalPrice = bookingAtom.price;
            const credit = bookingAtom.price;
            const startTime = startDatetime;
            const endTime = endDatetime;

            const getSport = (obj: any) =>
                Object.keys(obj).find((i) => obj[i] === true);

            const gameType = getSport(
                bookingAtom?.selectedCourt?.sport ||
                    bookingData?.sports_available
            );

            try {
                setIsLoadingPayment(true);
                setProcessing(true);
                setErrorMessage("");

                const _bookingData = {
                    total_price: Number(bookingAtom.price.toFixed(2)),
                    discount: totalDiscount(),
                    code: voucher?.code || "",
                    info: `Court booked by ${
                        currentUser.displayName
                    } at ${bookingData?.name} on ${moment(selectedDate).format(
                        "DD MMM YYYY HH:mm"
                    )}`,
                    court_ids: [bookingAtom?.selectedCourt?._id] || [],
                    players: [],
                    start_datetime: startTime.valueOf(),
                    stop_datetime: endTime.valueOf()
                };

                const payload = {
                    clubId: clubId,
                    payFor:
                        bookingAtom?.clubActivity?.activity === "Training"
                            ? "training"
                            : bookingAtom?.clubActivity?.activity ===
                              "Tournament"
                            ? "tournament"
                            : payFor,
                    gameType: gameType?.toLowerCase() === "other" ? "" : gameType,
                    sourceId: bookingAtom?.clubActivity?._id,
                    source: "WEBSITE",
                    credit: bookingAtom.price,
                    totalPrice: totalPrice,
                    endTime: endTime,
                    startTime: startTime,
                    isForSplitPayment: false,
                    split_players_ids: [],
                    splitDiscount: 0,
                    splitAmount: 0,
                    courtType: gameType,
                    isSupportAutoJoinFlow: true,
                    autoJoinAdditionalInfo: {
                        bookData: _bookingData
                    },
                    paymentMethod: "creditPay",
                    discountCode: voucher?.code || '',
                    membershipData: membershipData
                };

                handleCreditPayment(payload)
                    .then(async (resp) => {
                        const response = {
                            data: {
                                success: resp.success,
                                message: resp.message,
                                chargeId: resp.chargeId,
                                paymentStatus: resp.paymentStatus,
                                transactionId: resp.transactionId,
                                receiptUrl: resp.receiptUrl
                            }
                        };
                        if (response?.data?.success) {
                            setBookingAtom({
                                ...bookingAtom,
                                page: 4
                            });

                            setReceiptUrl(response?.data?.receiptUrl);
                        }
                        setProcessing(false);
                        setIsLoadingPayment(false);
                    })
                    .catch((err) => {
                        console.log(
                            "🚀  file: PaymentComponent.jsx:78  handleCreditPayment ~ err:",
                            err
                        );
                        setErrorMessage(
                            err?.response?.data?.message ||
                                "Some error occurred while trying to book!"
                        );
                        setErrorOpen(true);
                        setProcessing(false);
                        setIsLoadingPayment(false);
                    });
            } catch (error) {
                console.log(
                    "🚀  file: PaymentComponent.jsx:78  handleCreditPayment ~ error:",
                    error
                );
                setIsLoadingPayment(false);
                setProcessing(false);
                setErrorMessage(error?.response?.data?.message || "Some error occurred while trying to book!");
                setErrorOpen(true);
            }
        }
    };

    const scheduleTournament = async (activityRecordId) => {
        try {
            await AxiosFastAPI.get(
                `/home/activity/tournament/schedule`,
                {
                  params: {
                    activity_records_id: activityRecordId,
                  },
                }
              );
        } catch (error) {
            console.log('error: ', error);
        }
    }

    const handlePaymentTournament = async (event: any) => {
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.startTime}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");
        const startDatetime = startTime.valueOf();
        const endDatetime = endTime.valueOf();

        if (isSelected === "card") {
            if (!stripe || !elements) return;

            if (cardDetails && !cardDetails?.complete) {
                setErrorMessage(`${t("BookingModal.fillCardFields")}`);
                setErrorOpen(true);
                setProcessing(false);
                return;
            }
            if (
                !cardDetails &&
                (paymentMethodId === undefined || paymentMethodId === "")
            ) {
                setErrorMessage(`${t("BookingModal.noCardAvailable")}`);
                setErrorOpen(true);
                setProcessing(false);
                return;
            }

            event.preventDefault();
            try {
                setProcessing(true);
                setErrorMessage("");

                if (
                    bookingAtom.clubActivity?.tournament_type ===
                    "team_americano"
                ) {
                } else {
                }

                    let autoJoinAdditionalInfo = {
                        teamAmericanoDto: selectedPlayerValue,
                        isTeamAmericano: true,
                        playerObject:[],
                        start_datetime: startTime.utc().valueOf(),
                        stop_datetime: endTime.utc().valueOf()
                    };


                const paymentIntentParams: Record<string, any> = {
                    payFor: tournamentDataMain?.type?.toLowerCase(),
                    clubId: bookingData._id,
                    amount: Number(bookingAtom.price.toFixed(2)),
                    sourceId: bookingAtom.clubActivity._id,
                    gameType: "padel",
                    startTime: startDatetime,
                    endTime: endDatetime,
                    isSupportAutoJoinFlow: true,
                    autoJoinAdditionalInfo:
                        bookingAtom.clubActivity?.tournament_type ===
                        "team_americano"
                            ? autoJoinAdditionalInfo
                            : null,
                    discountCode: voucher?.code || '',
                    paymentMethod: "cards",
                    membershipData: membershipData
                };

                /*
                As react-stripe-js doesn't provide details info about cardElement like mobile package,
                I am calling stripe token api to get details info like last4, expiryMonth, expiryYear etc.
                */
                let _cardDetailsFromStripeTokenForIntent;
                if (cardDetails && cardElement) {
                    const { token, error } = await stripe.createToken(
                        cardElement
                    );
                    if (token && !error) {
                        _cardDetailsFromStripeTokenForIntent = {
                            last4: token.card?.last4,
                            expiryMonth: token.card?.exp_month,
                            expiryYear: Number(
                                token.card?.exp_year.toString().slice(-2)
                            ),
                            complete: true,
                            brand: token.card?.brand,
                            validExpiryDate: "Valid",
                            validNumber: "Valid",
                            validCVC: "Valid"
                        };
                    }
                }

                // add cardDetails based on checkbox for future usage
                paymentIntentParams.cardDetails = saveCardForFutureUse
                    ? _cardDetailsFromStripeTokenForIntent || cardDetails
                    : null;
                console.log("payment intent 2");
                if (bookingAtom.price <= 0) {
                    if (tournamentDataMain?.matchType !== 'team_americano') {
                        try{
                            setIsLoadingPayment(true);
                            const res = await joinTournament(tournamentDataMain._id, {
                                name: userData?.name,
                                email: userData?.email,
                                profilePic: userData?.profilePic,
                                punctuality: userData?.punctuality * 1,
                                lang: userData?.lang,
                                fcmToken: userData?.fcmToken ? userData?.fcmToken : "",
                                isCoach: userData?.isCoach,
                                level: userData?.level,
                                gender: userData?.gender,
                                pro: userData?.pro,
                                userId: userData?._id,
                                tournamentId: tournamentDataMain?._id,
                                chargeid: 'chargeId' + tournamentDataMain?._id,
                                transactionId: 'transactionId' + tournamentDataMain?._id,
                                discountCode: voucher?.code || '',
                                // chatId: tournamentDataMain.chatId,
                                });
                                setIsLoadingPayment(false);
                                setGetBookings && setGetBookings(true);
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 131,
                                    show: !bookingAtom.show
                                });

                                Store.addNotification({
                                    title: null,
                                    message: res?.message,
                                    type: "success",
                                    container: "bottom-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 3000
                                    }
                                });
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 13,
                                    show: true
                                });
                        }
                        catch (error) {
                            console.log("Error", error);
                            setBookingStatus(false);
                            setIsLoadingPayment(false);
                        }
                    }
                    else {
                        let selectedPlayer;
                        if(playerObject && playerObject?.length >= 1) {
                            selectedPlayer = {
                                player1: { _id: userId, email: userEmail, name: userName },
                                player2: playerObject[0]
                            };
                        }
                        else if (lockPlayer !== "") {
                            selectedPlayer = {
                                player1: { _id: userId, email: userEmail, name: userName },
                                player2: {
                                    name: lockPlayer,
                                    email: `${lockPlayer}_${Math.floor(Math.random() * 100)}@mailinator.com`,
                                    punctuality: 0,
                                    lang: "en",
                                    fcmToken: "",
                                    isCoach: false,
                                    profilePic: "https://uploads.padelmates.co/images/new%20locl.png",
                                    level: { point: 6, name: "name6", des: "des6", im: 6, img: 12 },
                                    tournamentId: tournamentDataMain._id
                                }
                            }
                            setlockPlayer("");
                        } else if (selectedPlayerValue && Object.values(selectedPlayerValue)?.length > 1) {
                            selectedPlayer = selectedPlayerValue
                        } else {
                            alert("Add or invite player to join");
                        }
                        try {
                            setIsLoadingPayment(true);
                            const res = await joinTeamAmericanoTournament(tournamentDataMain._id, {
                                ...selectedPlayer,
                                chargeid: 'chargeId' + tournamentDataMain?._id,
                                transactionId: 'transactionId' + tournamentDataMain?._id,
                                discountCode: voucher?.code || '',
                                // chatId: tournamentDataMain.chatId,
                            })
                              setIsLoadingPayment(false);
                              setGetBookings && setGetBookings(true);
                              setBookingAtom({
                                ...bookingAtom,
                                page: 131,
                                show: !bookingAtom.show
                            });
                            Store.addNotification({
                                title: null,
                                message: res?.message,
                                type: "success",
                                container: "bottom-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 3000
                                }
                            });
                            setBookingAtom({
                                ...bookingAtom,
                                page: 13,
                                show: true
                            });
                        }
                        catch (error) {
                            setIsLoadingPayment(false);
                            setBookingStatus(false);
                            setErrorMessage(error?.response?.data?.message || "Some error occurred while trying to join tournament!");
                            setErrorOpen(true);
                        }
                    }
                } else {
                    try {
                        setIsLoadingPayment(true);
                        const { data: intent } = await Axios.post(
                            "/payment/createPaymentIntent",
                            paymentIntentParams
                        );

                        const confirmCardPaymentParams: ConfirmCardPaymentData = {};

                        if (paymentMethodId && !cardDetails) {
                            confirmCardPaymentParams.setup_future_usage =
                                "off_session";
                        }

                        if (cardDetails && cardElement) {
                            confirmCardPaymentParams.payment_method = {
                                card: cardElement,
                                billing_details: {
                                    name: userName,
                                    email: userEmail
                                }
                            };
                        } else if (paymentMethodId) {
                            confirmCardPaymentParams.payment_method =
                                paymentMethodId; // only send for existing cards
                        }

                        const confirmPayment = await stripe.confirmCardPayment(
                            intent.clientSecret,
                            confirmCardPaymentParams
                        );

                        // Clear card details
                        setCardDetails(null);

                        if (!confirmPayment?.error) {
                            let responseFromChargeCheck =
                                await checkPaymentChargeWithCondition(
                                    intent?.paymentIntentId
                                );
                            if (responseFromChargeCheck?.data?.success) {
                                setAccessCode(
                                    responseFromChargeCheck.data?.user?.access_code
                                );
                                setIsLoadingPayment(false);
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 4
                                });
                                setReceiptUrl(
                                    responseFromChargeCheck?.data?.receiptUrl
                                );
                                setGetBookings && setGetBookings(true);
                            }
                            // return responseFromChargeCheck.data;
                        }

                        setIsLoadingPayment(false);
                    } catch (error) {
                        console.log('error: ', error);
                        setErrorMessage(error?.response?.data?.message || "Some error occurred while trying to book!");
                        setErrorOpen(true);
                    }
                }
            } catch (error) {
                console.log({ error });
                setErrorMessage(error?.response?.data?.message || "Some error occurred while trying to book!");
                setErrorOpen(true);
                setProcessing(false);
                setIsLoadingPayment(false);
            } finally {
                setIsLoadingPayment(false);
                setProcessing(false);
            }
            //setProcessing(false);
        } else if (isSelected === "credit") {
            console.log("this speak 2");
            event.preventDefault();

            // if (
            //     (!clubData && !bookingData?.following) ||
            //     (clubData && !clubData?.following)
            // ) {
            //     setErrorMessage(`${t("BookingModal.notFollowerOfClub")}`);
            //     setErrorOpen(true);
            //     setProcessing(false);
            //     return;
            // } else
            if (userCredit < bookingAtom.price) {
                setErrorMessage(`${t("BookingModal.enoughCredits")}`);
                setErrorOpen(true);
                setProcessing(false);
                return;
            }

            const payFor = "booking";
            const clubId = bookingData._id as string;
            const totalPrice = bookingAtom.price;
            const credit = bookingAtom.price;
            const startTime = startDatetime;
            const endTime = endDatetime;

            const getSport = (obj: any) =>
                Object.keys(obj).find((i) => obj[i] === true);

            const gameType = getSport(
                bookingAtom?.selectedCourt?.sport ||
                    bookingData?.sports_available
            );

            try {
                //setProcessing(true);
                setIsLoadingPayment(true);
                setErrorMessage("");

                const _bookingData = {
                    total_price: Number(bookingAtom.price.toFixed(2)),
                    discount: totalDiscount(),
                    code: voucher?.code || "",
                    info: `Court booked by ${
                        currentUser.displayName
                    } at ${bookingData?.name} on ${moment(selectedDate).format(
                        "DD MMM YYYY HH:mm"
                    )}`,
                    court_ids: bookingAtom.selectedCourt
                        ? [bookingAtom.selectedCourt._id]
                        : [],
                    players: [],
                    start_datetime: startTime.valueOf(),
                    stop_datetime: endTime.valueOf()
                };

      if (
        bookingAtom.clubActivity?.tournament_type ===
        "team_americano"
    ) {
    } else {
    }

    let autoJoinAdditionalInfo = {
        teamAmericanoDto: selectedPlayerValue,
        isTeamAmericano: true,
        playerObject:[],
        start_datetime: startTime,
        stop_datetime: endTime

    };


                const payload = {
                    clubId: clubId,
                    payFor:
                        bookingAtom?.clubActivity?.activity === "Training"
                            ? "training"
                            : bookingAtom?.clubActivity?.activity ===
                              "Tournament"
                            ? "tournament"
                            : payFor,
                    gameType: gameType?.toLowerCase() === "other" ? "" : gameType,
                    sourceId: bookingAtom?.clubActivity?._id,
                    source: "WEBSITE",
                    totalPrice: totalPrice,
                    credit: bookingAtom.price,
                    endTime: endTime,
                    startTime: startTime,
                    isForSplitPayment: false,
                    split_players_ids: [],
                    splitDiscount: 0,
                    splitAmount: 0,
                    courtType: gameType,
                    isSupportAutoJoinFlow: true,
                    autoJoinAdditionalInfo: bookingAtom.clubActivity?.tournament_type ===
                    "team_americano"
                        ? autoJoinAdditionalInfo
                        : null,
                    paymentMethod: "creditPay",
                    discountCode: voucher?.code || '',
                    membershipData: membershipData
                };

                handleCreditPayment(payload)
                    .then(async (resp) => {
                        const response = {
                            data: {
                                success: resp.success,
                                message: resp.message,
                                chargeId: resp.chargeId,
                                paymentStatus: resp.paymentStatus,
                                transactionId: resp.transactionId,
                                receiptUrl: resp.receiptUrl
                            }
                        };
                        // await handleBookingRetries(response);
                        if (response?.data?.success) {
                            setIsLoadingPayment(false);
                            setBookingAtom({
                                ...bookingAtom,
                                page: 4
                            });

                            setReceiptUrl(response?.data?.receiptUrl);
                        }
                        setProcessing(false);
                        setGetBookings && setGetBookings(true);
                        setIsLoadingPayment(false);
                    })
                    .catch((err) => {
                        console.log(
                            "🚀  file: PaymentComponent.jsx:78  handleCreditPayment ~ err:",
                            err
                        );
                        setErrorMessage(
                            err?.response?.data?.message ||
                                "Some error occurred while trying to book!"
                        );
                        setErrorOpen(true);
                        setIsLoadingPayment(false);
                        setProcessing(false);
                    });
            } catch (error) {
                setIsLoadingPayment(false);
                console.log(
                    "🚀  file: PaymentComponent.jsx:78  handleCreditPayment ~ error:",
                    error
                );
                setErrorMessage(error?.response?.data?.message || "Some error occurred while trying to book!");
                setErrorOpen(true);
                setProcessing(false);
            }
        }
    };

    async function checkPaymentChargeWithCondition(
        paymentintentid: string,
        maxAttempts: number = 6,
        delay: number = 3000
    ) {
        let attempt = 0;
        while (attempt < maxAttempts) {
            try {
                const url = `/payment/check-charge-auto-join-v2?paymentintentid=${paymentintentid}`;
                const response = await axiosGetWithRetry(url);

                if (
                    response.data &&
                    response.data.success &&
                    response.data.receiptUrl !== undefined &&
                    response.data.receiptUrl !== null
                ) {
                    // Condition met, return the response
                    return response;
                } else {
                    // Condition not met, increment attempt and continue the loop
                    await new Promise((resolve: any) =>
                        setTimeout(resolve, delay)
                    );
                    attempt++;
                }
            } catch (error) {
                // Handle or rethrow the error as appropriate
                throw error;
            }
        }
        throw new Error("Condition not met after maximum attempts");
    }

    useEffect(() => {
        const fetchUserLevel = async () => {
            try {
                const userProfile = await getUserProfile();
                setUserData(userProfile);

                // Assuming the API response contains a `level` property
                const level = userProfile.level.im;
                setUserLevel(level);

                console.log("User Level:", userLevel);

                // You can now use the user level for your validation or other logic
                return userLevel;
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };
        fetchUserLevel();
    }, [userLevel, currentUser?.email]);

    const axiosGetWithRetry = async (
        url: string,
        config = {},
        maxRetries = 3,
        retryDelay = 5000
    ) => {
        let lastError;

        for (let attempt = 1; attempt <= maxRetries; attempt++) {
            try {
                // Attempt the POST request
                // console.log("Attempt #" + attempt + " Axios Get: " + url);
                const response = await Axios.get(url, config);
                return response; // Return response if successful
            } catch (error) {
                lastError = error; // Store the error from the attempt

                if (attempt < maxRetries) {
                    // Wait for the retryDelay, increased by attempt count
                    await new Promise((resolve: any) =>
                        setTimeout(resolve, retryDelay * attempt)
                    );
                }
            }
        }

        // If all retries fail, throw the last error
        throw lastError;
    };
    useEffect(() => {
        const fetchData = async () => {
            setErrorMessage("");
            try {
                setIsButtonLoading(true);
                setProcessing(true);

                console.log(
                    "bookingAtom.clubActivity._id value of before getTournamentById:  " +
                        bookingAtom.clubActivity._id
                );

                const tourData: any = await getTournamentById(
                    bookingAtom.clubActivity._id
                );
                console.log(
                    "tourData value of getTournamentById:  " +
                        JSON.stringify(tourData)
                );
                if(bookingAtom?.show){
                    setTournamentDataMain(tourData);
                }

                try {
                    // Fetch tournament queue
                    const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
                    if (Array.isArray(tournamentQueue)) {
                      // const filterData = filterQueueList(tournamentQueue);
                      setQueue(tournamentQueue);
                    }
                  } catch (error) {

                  }

                setTournamentAmericanoData([]);
                if (
                    bookingAtom &&
                    bookingAtom.clubActivity &&
                    bookingAtom.clubActivity._id &&
                    bookingAtom.clubActivity?.tournament_type ===
                        "team_americano"
                ) {
                    try {
                        const isQueuedResponse = await isQueueTeam(currentUser?.email, bookingAtom.clubActivity._id);
                        if (isQueuedResponse === true) {
                            setIsQueed(isQueuedResponse);
                        }

                        const inTournament = await isInTournamentTeamAmericano(currentUser?.email, bookingAtom.clubActivity._id);
                        if (inTournament === true) {
                            setFound(inTournament);
                        }
                      } catch (error) {

                      }
                    if (userEmail !== tourData?.email) {
                        isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                            if (res === true) {
                                setIsReuest(res);
                            }
                        });
                    }
                    try {
                        // Check if user is invited
                        const invitedResponse = await isInvitedTeamAmericano(
                            bookingAtom.clubActivity._id,
                            currentUser?.email
                        );
                        if (invitedResponse?.success === true) {
                          setInvited(invitedResponse?.success);
                        //   setInviterEmail(invitedResponse?.inviterEmail);
                        }
                      } catch (error) {

                    }
                    await scheduleTournament(bookingAtom?.clubActivity?._id);
                    const data = await getAmericanoTournamentPlayers(
                        bookingAtom?.clubActivity?._id
                    );

                    console.log(
                        "tournamentAmericanoData:  " + JSON.stringify(data)
                    );
                    console.log(
                        "my useremail before is available in tournamentAmericanoData:  " +
                            userEmail
                    );

                    data &&
                        data?.length > 0 &&
                        data?.map((item: any) => {
                            if (
                                item?.player1?.email === userEmail ||
                                item?.player2?.email === userEmail
                            ) {
                                console.log(
                                    "my user is available in tournamentAmericanoData:  " +
                                        userEmail
                                );

                                setFound(true);
                            }
                        });
                    setTournamentAmericanoData(data);
                } else if (
                    bookingAtom &&
                    bookingAtom.clubActivity &&
                    bookingAtom.clubActivity._id &&
                    (bookingAtom.clubActivity?.tournament_type === "Americano" ||
                    bookingAtom.clubActivity?.tournament_type === "Mexicano" || bookingAtom.clubActivity?.tournament_type === "Course" || bookingAtom.clubActivity?.tournament_type === "Group" || bookingAtom.clubActivity?.tournament_type === "Personal")
                ) {
                    const queued = await isQueue(userEmail, tourData._id);
                    setIsQueued(queued);
                    if (userEmail && userEmail !== tourData.email) {
                        const requested = await isRequested(userEmail, tourData._id);
                            setIsReuest(requested);
                    }
                    if (userEmail) {
                        const invited = await isInvitedTournament(userEmail, bookingAtom?.clubActivity?._id);
                            if (invited) {
                            setInvited(invited);
                        }
                    }
                    await isInTournament(currentUser?.email, bookingAtom.clubActivity._id).then(
                        (res: any) => {
                            setFound(res);
                        }
                    );
                    // const data = await getTournamentPlayers(
                    //     bookingAtom.clubActivity._id
                    // );
                    // setTournamentData(data);
                }
            } catch (error) {
                setProcessing(false);
                setErrorMessage("Error fetching tournament data.");
                console.error("Error fetching tournament data:", error);
            } finally {
                setProcessing(false);
                setIsButtonLoading(false);
            }
        };

        // console.log(" bookingAtom?.page: " + bookingAtom?.page);
        if (bookingAtom?.page === 13 || bookingAtom?.page === 31 || bookingAtom?.page === 131) {
            fetchData();
        }
    }, [bookingAtom?.clubActivity?._id, bookingAtom?.page, currentUser?.email]);

    const handleUpdateUserInfo = async (formData: {
        name: string;
        phone: string;
    }) => {
        setBookingAtom({
            ...bookingAtom,
            page: 1
        });

        updateUserInfo(formData).then((data) => {
            let type = "success",
                message = t(`${t("BookingModal.nameAndPhoneUpdated")}!`);
            if (data?.success) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 3
                });
            } else {
                type = "danger";
                message = t(`${t("BookingModal.errorOnSavingNamePhone")}.`);
            }
            Store.addNotification({
                title: null,
                message: message,
                type: type as NOTIFICATION_TYPE,
                insert: "bottom",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000
                }
            });
        });
    };

    const errorJoinTeamAmericano = () => {
        alert("This player is already in the tournament");
    };

    const sendRequestForQueue = async (player) => {
        try {
            setIsConfirmButtonLoading(true);
            await joinTeamAmericanoTournament(tournamentDataMain._id, player);
            setIsQueued(true);
            const tourData: any = await getTournamentById(
                bookingAtom.clubActivity._id
            );
            setTournamentDataMain(tourData);
            if (userEmail != tourData?.email) {
                isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                    console.log(res);
                    if (res == true) {
                        setIsReuest(res);
                    }
                });
            }
            try {
                // Fetch tournament queue
                const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
                if (Array.isArray(tournamentQueue)) {
                    // const filterData = filterQueueList(tournamentQueue);
                    setQueue(tournamentQueue);
                }
            } catch (error) {

            }
            setBookingAtom({
                ...bookingAtom,
                page: 13
            });
            setplayerObject([]);
            setIsConfirmButtonLoading(false);
        } catch (error) {
            const tourData: any = await getTournamentById(
                bookingAtom.clubActivity._id
            );
            setTournamentDataMain(tourData);
            if (userEmail != tourData?.email) {
                isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                    console.log(res);
                    if (res == true) {
                        setIsReuest(res);
                    }
                });
            }
            try {
                // Fetch tournament queue
                const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
                if (Array.isArray(tournamentQueue)) {
                    // const filterData = filterQueueList(tournamentQueue);
                    setQueue(tournamentQueue);
                    if (tournamentQueue?.length > 0){
                        setIsQueued(true);
                    }
                }
            } catch (error) {

            }
            setBookingAtom({
                ...bookingAtom,
                page: 13
            });
            setplayerObject([]);
            setIsConfirmButtonLoading(false);
        } finally {
            setplayerObject([]);
            setIsConfirmButtonLoading(false);
            // setIsApplyingForQueue(false);
            setSelectedPlayerValue({});
        }
      };

      const joinTeamAmericano = async () => {
        let available = true;

        if (
            tournamentDataMain?.require_player_level &&
            tournamentDataMain?.level.length > 0
        ) {
            const minLevel = tournamentDataMain.level[0].im;
            const maxLevel = tournamentDataMain.level[tournamentDataMain?.level.length - 1].im;

            console.log("Player Level:", userLevel);
            console.log("Min Level:", minLevel);
            console.log("Max Level:", maxLevel);

            if (!(minLevel <= userLevel && userLevel <= maxLevel)) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 15, // Error page
                    errorMessage: `You cannot join this activity because your level ${userLevel} is not within the level range of this activity, which is between ${minLevel} and ${maxLevel}.`
                });
                return;
            }
        }

        if (playerObject && playerObject?.length >= 1) {
            tournamentAmericanoData &&
                tournamentAmericanoData?.length > 0 &&
                tournamentAmericanoData?.map((item) => {
                    if (
                        item?.player1?.email === playerObject[0]?.email ||
                        item?.player2?.email === playerObject[0]?.email
                    ) {
                        available = false;
                    }
                });

            let selectedPlayer = {
                player1: { _id: userId, email: userEmail, name: userName },
                player2: playerObject[0]
            };
            setSelectedPlayerValue(selectedPlayer);

            if (available) {
                if (tournamentDataMain?.paid === true) {
                    if (tournamentDataMain?.email === userEmail) {
                        let createMatch =
                            (tournamentAmericanoData ? tournamentAmericanoData?.length + 1 : 1) == tournamentDataMain.numberOfPlayers;

                        joinTeamAmericanoTournament(tournamentDataMain._id, {
                            ...selectedPlayer,
                            chatId: tournamentDataMain.chatId,
                            createMatch: createMatch,
                            discountCode: voucher?.code || '',
                        })
                            .then(async (res: any) => {
                                setplayerObject([]);
                                const tourData: any = await getTournamentById(bookingAtom.clubActivity._id);
                                setTournamentDataMain(tourData);
                                if (userEmail != tourData?.email) {
                                    isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                                        if (res == true) {
                                            setIsReuest(res);
                                        }
                                    });
                                }
                            })
                            .catch((err: any) => {
                                errorJoinTeamAmericano();
                            });
                    } else {
                        if (shouldApplyForQueue) {
                            await sendRequestForQueue({ ...selectedPlayer });
                            setplayerObject([]);
                        } else {
                            handleJoinTournament();
                            setplayerObject([]);
                        }
                    }
                } else {
                    if (userEmail == tournamentDataMain?.email) {
                        if (tournamentAmericanoData?.length == tournamentDataMain.numberOfPlayers) {
                            alert("Tournament is Full");
                        } else {
                            if (userEmail) {
                                let createMatch =
                                    (tournamentAmericanoData ? tournamentAmericanoData?.length + 1 : 1) == tournamentDataMain.numberOfPlayers;
                                joinTeamAmericanoTournament(tournamentDataMain._id, selectedPlayer)
                                    .then(async (res: any) => {
                                        setplayerObject([]);
                                        const tourData: any = await getTournamentById(bookingAtom.clubActivity._id);
                                        setTournamentDataMain(tourData);
                                        if (userEmail != tourData?.email) {
                                            isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                                                if (res == true) {
                                                    setIsReuest(res);
                                                }
                                            });
                                        }
                                    })
                                    .catch((err: any) => {
                                        console.log(err);
                                        errorJoinTeamAmericano();
                                    });
                            }
                        }
                    } else {
                        if (userEmail) {
                            requestToJoinTeamAmericano(tournamentDataMain._id, { ...selectedPlayer })
                                .then((res) => {
                                    setplayerObject([]); // Clear after join request
                                    setIsReuest(true);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }
                    }
                }
            } else {
                errorJoinTeamAmericano();
            }
        } else if (lockPlayer !== "") {
            let selectedPlayer = {
                player1: { _id: userId, email: userEmail, name: userName },
                player2: {
                    name: lockPlayer,
                    email: `${lockPlayer}_${Math.floor(Math.random() * 100)}@mailinator.com`,
                    punctuality: 0,
                    lang: "en",
                    fcmToken: "",
                    isCoach: false,
                    profilePic: "https://uploads.padelmates.co/images/new%20locl.png",
                    level: { point: 6, name: "name6", des: "des6", im: 6, img: 12 },
                    tournamentId: tournamentDataMain._id
                }
            };

            setSelectedPlayerValue(selectedPlayer);

            if (shouldApplyForQueue) {
                await sendRequestForQueue({ ...selectedPlayer });
                setplayerObject([]);
                setlockPlayer("");
            } else {
                setBookingAtom({ ...bookingAtom, page: 14 });
                setplayerObject([]);
                setlockPlayer("");
            }
        } else {
            alert("Add or invite player to join");
        }
    };


    async function takeBackRequestAmericano() {
        try {
            await declineRequestTeamAmericano(
                tournamentDataMain?._id,
                userEmail
            );
            setIsReuest(false);
            // Fetch tournament queue
            const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
            if (Array.isArray(tournamentQueue)) {
                // const filterData = filterQueueList(tournamentQueue);
                setQueue(tournamentQueue);
            }
        } catch (error) {}
        finally {}
    }

    async function takeBackRequest() {
        try {
          await declineTournamentRequest(tournamentDataMain._id, userEmail, userEmail);
          setIsReuest(false);
          // Fetch tournament queue
          const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
          if (Array.isArray(tournamentQueue)) {
              const filterData = filterQueueList(tournamentQueue);
              setQueue(filterData);
          }
        } catch (error) {
        } finally {}
    }

    const removeFromQueueAmericano = async () => {
        try {
          const result = await removeFromQueueForTeamAmericano(tournamentDataMain._id, userEmail);
          if (result?.data?.success) {
            // getData();
            // Fetch tournament queue
            const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
            if (Array.isArray(tournamentQueue)) {
                // const filterData = filterQueueList(tournamentQueue);
                setQueue(tournamentQueue);
            }
            setIsLeaveModal(false);
            setIsQueued(false);
          } else {
            setIsLeaveModal(false);
          }
        } catch (error) {
            setIsLeaveModal(false);
        } finally {
        }
      }

    const removeFromQueueFunction = async () => {
        try {
          // Call the removeFromQueue API
          await removeFromQueue(tournamentDataMain._id, userEmail);

          // Fetch tournament queue
          const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
          if (Array.isArray(tournamentQueue)) {
              const filterData = filterQueueList(tournamentQueue);
              setQueue(filterData);
          }

          // Update states based on the user's email
          if (userEmail === userData.email) {
            setFound(false);
          }
          setIsLeaveModal(false)
          setIsQueued(false);

        } catch (err) {
          setIsLeaveModal(false)
        } finally {}
    }

    const insertLock = () => {
        setName("");
        if (
            tournamentAmericanoData?.length ==
            tournamentDataMain.numberOfPlayers
        ) {
            alert("Tournament is Full");
        } else {
            joinTournament(tournamentDataMain._id, {
                name: lockPlayer,
                email:
                    lockPlayer +
                    "_" +
                    Math.floor(Math.random() * 100) +
                    "@mailinator.com",
                punctuality: 0,
                lang: "en",
                fcmToken: "",
                isCoach: false,
                profilePic:
                    "https://uploads.padelmates.co/images/new%20locl.png",
                level: {
                    point: 6,
                    name: "name6",
                    des: "des6",
                    im: 6,
                    img: 12
                },
                tournamentId: tournamentDataMain._id
            }).then(async (res) => {
                setFound(true);
                setlockPlayer("");
                const tourData: any = await getTournamentById(
                    bookingAtom.clubActivity._id
                );
                console.log(
                    "tourData value of getTournamentById:  " +
                        JSON.stringify(tourData)
                );
                setTournamentDataMain(tourData);
                if (userEmail != tourData?.email) {
                    isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                        console.log(res);
                        if (res == true) {
                            setIsReuest(res);
                        }
                    });
                }
            });
        }
    };

       const _leaveTournament = async () => {
        let checkDate2 = new Date().getTime();
        if (
            checkDate2 >=
            parseInt(tournamentDataMain?.timeStampReal) - 48 * 60 * 60 * 1000
        ) {
            setErrorMessage(`${t("BookingModal.cancellation_desc")}`);
            setErrorOpen(true);
            setBookingAtom({ ...bookingAtom, page: 131 });
            setIsLeaveModal(false)
        } else {
            try {
                setProcessing(true);
                const response = await leaveTournament(
                    userEmail,
                    tournamentDataMain?._id
                );
                if (response.status < 200 || response.status >= 300) {
                    setErrorMessage(response.data);
                    setErrorOpen(true);
                    setProcessing(false);
                    setIsLeaveModal(false)
                    return;
                }
                setProcessing(false);
                setGetBookings && setGetBookings(true);
                setFound(false);
                const tourData: any = await getTournamentById(
                    bookingAtom.clubActivity._id
                );

                setTournamentDataMain(tourData);
                if (userEmail != tourData?.email) {
                    isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                        console.log(res);
                        if (res == true) {
                            setIsReuest(res);
                        }
                    });
                }
                setBookingAtom({
                    ...bookingAtom, page: 131,
                    clubActivity: {
                        ...bookingAtom?.clubActivity,
                        current_no_of_players: tourData?.players?.length || 0,
                    }
                });
                setIsLeaveModal(false)
            } catch (error) {
                setIsLeaveModal(false)
                console.log('error: ', error);
            }
        }
    };

    async function deleteTeamAmericano(item, left) {
        const payload = {
            player1: {
            email: item?.player1?.email,
            profilePic: item?.player1?.profilePic,
            },
            player2: {
            email: item?.player2?.email,
            profilePic: item?.player2?.profilePic,
            },
        };
        const response = await deleteAmericanoTeam(
            tournamentDataMain?._id,
            payload
        );
        return response;
      }

     const _leaveTeamAmericano = async () => {
        let checkDate2 = new Date().getTime();
        if (
            checkDate2 >=
            parseInt(tournamentDataMain?.timeStampReal) - 48 * 60 * 60 * 1000
        ) {
            setErrorMessage(`${t("BookingModal.cancellation_desc")}`);
            setErrorOpen(true);
            setBookingAtom({ ...bookingAtom, page: 131 });
            setIsLeaveModal(false)
        } else {
            try {
                setProcessing(true);
                const teamDeletion = tournamentAmericanoData?.find(
                    (o) => o.player1?.email === userEmail || o.player2?.email === userEmail
                );
                const response = await deleteTeamAmericano(teamDeletion, true);
                if (response.status < 200 || response.status >= 300) {
                    setErrorMessage(response.data);
                    setErrorOpen(true);
                    setProcessing(false);
                    setIsLeaveModal(false)
                    return;
                }
                setGetBookings && setGetBookings(true);
                setProcessing(false);
                setFound(false);
                const tourData: any = await getTournamentById(
                    bookingAtom.clubActivity._id
                );

                setTournamentDataMain(tourData);
                if (userEmail !== tourData?.email) {
                    isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                        console.log(res);
                        if (res === true) {
                            setIsReuest(res);
                        }
                    });
                }
                await scheduleTournament(bookingAtom?.clubActivity?._id);
                const data = await getAmericanoTournamentPlayers(
                    bookingAtom?.clubActivity?._id
                );

                data &&
                    data?.length > 0 &&
                    data?.map((item: any) => {
                        if (
                            item?.player1?.email === userEmail ||
                            item?.player2?.email === userEmail
                        ) {
                            console.log(
                                "my user is available in tournamentAmericanoData:  " +
                                    userEmail
                            );

                            setFound(true);
                        }
                    });
                setTournamentAmericanoData(data);
                setBookingAtom({
                    ...bookingAtom, page: 131,
                    clubActivity: {
                        ...bookingAtom?.clubActivity,
                        current_no_of_players: data?.length || 0,
                    }
                });
                setIsLeaveModal(false)
            } catch (error) {
                setIsLeaveModal(false)
                console.log('error: =-=', error);
            }
        }
    };

    const filterQueueList = (res) => {
        const res2 = res.filter((value, index, self) => {
          return self.findIndex((v) => v.email === value.email) === index;
        });
        return res2;
      };

    const applyForQueue = async () => {
        try {
            await joinTournament(tournamentDataMain._id, {
            name: userData?.name,
            email: userData.email,
            profilePic: userData.profilePic,
            punctuality: userData.punctuality * 1,
            lang: userData.lang,
            fcmToken: userData?.fcmToken ? userData?.fcmToken : "",
            isCoach: userData.isCoach,
            level: userData.level,
            pro: userData.pro,
            userId: userData._id,
            tournamentId: tournamentDataMain._id,
            // chatId: tournamentDataMain.chatId,
            });
            setIsQueued(true);
            const tourData: any = await getTournamentById(
                bookingAtom.clubActivity._id
            );
            setTournamentDataMain(tourData);
            if (userEmail !== tourData?.email) {
                isRequestedteam(currentUser?.email, bookingAtom?.clubActivity?._id).then((res) => {
                    console.log(res);
                    if (res === true) {
                        setIsReuest(res);
                    }
                });
            }
            try {
                // Fetch tournament queue
                const tournamentQueue = await getTournamentQueue(bookingAtom?.clubActivity?._id);
                if (Array.isArray(tournamentQueue)) {
                const filterData = filterQueueList(tournamentQueue);
                  setQueue(filterData);
                }
              } catch (error) {

              }
        } catch (error) {
            console.log("🚀 ~ applyForQueue ~ error:", error);
        } finally {}
      }

    const handleJoinTournament = () => {
        if (
            tournamentDataMain?.require_player_level &&
            tournamentDataMain?.level.length > 0
        ) {
            const minLevel = tournamentDataMain.level[0]?.im;
            const maxLevel =
                tournamentDataMain.level[tournamentDataMain.level.length - 1]
                    ?.im;

            if (minLevel === undefined || maxLevel === undefined) {
                console.error("Levels are not defined properly.");
                return;
            }

            console.log("Player Level:", userLevel);
            console.log("Min Level:", minLevel);
            console.log("Max Level:", maxLevel);

            if (!(minLevel <= userLevel && userLevel <= maxLevel)) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 15,
                    errorMessage: `You cannot join this activity because your level ${userLevel} is not within the level range of this activity, which is between ${minLevel} and ${maxLevel}.`
                });
                return; // Exit the function to prevent joining the tournament
            }
        }

        if(shouldApplyForQueue){
            applyForQueue();
        }
        else{
            setBookingAtom({
                ...bookingAtom,
                page: 14
            });
        }
    };

    const handleCheckForNameAndPhoneNumber = async () => {
        setProcessing(true);
        setVoucherErrorMessage("");
        setVoucherSuccessMessage("");

        try {
            const {
                status,
                user: { name, phone }
            } = await getUserInfo();

            setUserName(name);
            setUserPhone(phone);

            if (!name || !phone) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 12
                });
            } else {
                if (bookingAtom?.clubActivity?.activity ===
                    "Tournament" || bookingAtom?.clubActivity?.activity === "Training") {
                    setBookingAtom({
                        ...bookingAtom,
                        page: 3
                    });
                } else {
                    await handleCheckSlotAvailability();
                }
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        } finally {
            setProcessing(false);
        }
    };


    (window as any)["setBookingAtom"] = setBookingAtom;
    (window as any)["bookingAtom"] = bookingAtom;

    const handleCheckSlotAvailability = async () => {
        const time = moment(selectedDate)?.format("DD MM YYYY");
        const startTimeString = `${time} ${bookingAtom.selectedSlot}`;
        const startTime = moment.tz(
            startTimeString,
            "DD MM YYYY HH:mm",
            bookingData?.timezone
        );
        const endTime = startTime.clone().add(bookingAtom.mins, "minutes");
        const startDatetime = startTime.valueOf();
        const endDatetime = endTime.valueOf();
        const clubId = bookingData?._id;
        const courtId = bookingAtom.selectedCourt?._id;

        const payload = {
            startDatetime,
            endDatetime,
            clubId,
            courtId
        };

        checkAvailableSlot(payload).then((resp) => {
            if (resp?.is_slot_available) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 3
                });
            } else {
                setErrorMessage(`${t("BookingModal.selectedSlotNotAvailable")}`);
                setErrorOpen(true);
            }
        });
    };

    const signInWithFacebook = async () => {
        if (isPopupInProgress) {
            console.log("Popup request is already in progress.");
            return; // Prevent duplicate popups
        }
        const provider = new FacebookAuthProvider();
        provider.setCustomParameters({
            'display': 'popup'
            });

        setIsPopupInProgress(true); // Lock the popup to prevent duplicates
        try {
            const userCreds = await signInWithPopup(auth, provider);
            handleLoggedInUser(userCreds, null);
        } catch (error: any) {
            if (error?.code === "auth/popup-closed-by-user") {
                console.log("The authentication popup was closed by the user.");
            } else if (error.code === "auth/captcha-check-failed") {
                console.error("CAPTCHA check failed.");
            } else if (error.code === "auth/account-exists-with-different-credential") {
                Store.addNotification({
                    title: null,
                    message: error?.message,
                    type: "danger",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                });
            } else {
                console.log("An unexpected error occurred during sign-in:", error);
            }
        } finally {
            setIsPopupInProgress(false); // Allow new popup requests
        }   
    };

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then((userCreds) => {
            // Signed in
            handleLoggedInUser(userCreds, null);
        });
    };

    const onLogin = (e: React.FormEvent) => {
        setLoginErrorMessage("");
        e.preventDefault();

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError(`${t("Auth.passwordRequired")}`);
        } else {
            setPasswordError("");
        }

        if (email && password) {
            setIsLoginError(false);
        } else {
            setIsLoginError(true);
        }

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                handleLoggedInUser(userCredential, null)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setIsLoginError(true);
                setLoginErrorMessage(t("Auth.incorrectUsernameOrPassword"));
            });
    };

    const handleCardElementSelect = (element: any) => {
        setCardElement(element);
    };

    useEffect(() => {
        if (currentUser === undefined || currentUser === null) {
            setBookingAtom({
                ...bookingAtom,
                page: 1
            });
        } else if (
            bookingAtom.page === 1 &&
            currentUser !== undefined &&
            currentUser !== null
        ) {
            if (isSlot) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 2
                });
            } else {
                setBookingAtom({
                    ...bookingAtom,
                    page: 13
                });
            }
        }
    }, [currentUser]);

    useEffect(() => {
        const getUser = async () => {
            const userData = await getUserInfo();
            setUserName(userData?.user?.name);
            setProfilePic(userData?.user?.profilePic);
            setUserEmail(userData?.user?.email);
            setUserId(userData?.user?._id);
        };
        getUser();
    }, [currentUser]);

    // This is in-place, if the user decides to click on "Book xx price" on another court or time, so that the booking modal can reset". Book button sets the page to 1 usually.
    useEffect(() => {
        if (bookingAtom.page === 2) {
            setProcessing(false);
            setErrorMessage("");
        }
    }, [bookingAtom.page]);

    const checkNullAndUndefined = (value) => {
        if (value === undefined || value === null) {
          return true;
        }
        return false;
      };

    const checkFirebaseIDExistsInMemberships = (data, firebaseId) => {
        if (!checkNullAndUndefined(data.memberships) && data.memberships[firebaseId]) {
          return data.memberships[firebaseId];
        }
        return null;
      }

    const checkFirebaseIDExists = (data, firebaseID) => {
        if (checkNullAndUndefined(data.apply_discount_for_whole_booking)) {
          return false;
        }
        return data.apply_discount_for_whole_booking.includes(firebaseID);
      }

    useEffect(()=>{
        const membershipDiscountCalc = (membershipDiscount: any) => {
            const discountPercentage = membershipDiscount[currentUser?.uid] / 100;
            const discVal = discountPercentage
                ? bookingAtom.price * discountPercentage
                : 0;

            if(isSlot){
                if (checkFirebaseIDExists(membershipDiscount, currentUser?.uid)) {
                    const totalPrice = bookingAtom.price - discVal;
                    setBookingAtom({ ...bookingAtom, price: totalPrice });
                    setMembershipDiscountAmount(discVal);
                }
                else {
                    let individualDiscount;
                    if(bookingAtom?.selectedCourt?.double){
                        individualDiscount = bookingAtom?.price / 4
                    }
                    else{
                        individualDiscount = bookingAtom?.price / 2
                    }

                    const discountedPrice = discountPercentage ? individualDiscount * discountPercentage : 0;
                    const totalPrice = bookingAtom?.price - discountedPrice;
                    setBookingAtom({ ...bookingAtom, price: totalPrice });
                    setMembershipDiscountAmount(discountedPrice);
                }
            } else {
                const totalPrice = bookingAtom.price - discVal;
                setBookingAtom({ ...bookingAtom, price: totalPrice });
                setMembershipDiscountAmount(discVal);
            }
        }
        if(membershipDiscount && Object.keys(membershipDiscount).length > 0){
            membershipDiscountCalc(membershipDiscount)
        }
    }, [membershipDiscount]);

    const getMembershipDiscountFunction = async () =>{
        if (isSlot) {
            const time = moment(selectedDate)?.format("DD MM YYYY");
            const startTimeString = `${time} ${bookingAtom?.selectedSlot}`;
            const startTime = moment.tz(
                startTimeString,
                "DD MM YYYY HH:mm",
                bookingAtom?.clubData?.timezone
            );
            const endTime = startTime.clone().add(bookingAtom?.mins, "minutes");
            try {
                const data = await getMembershipDiscounts({
                    activity_type: 'booking',
                    sport_type: bookingAtom?.selectedCourt?.sport_type || 'PADEL',
                    club_id: bookingAtom?.selectedCourt?.club_id ?? bookingAtom?.clubData?._id,
                    start_datetime: startTime?.utc().valueOf(),
                    end_datetime: endTime?.utc().valueOf(),
                    player_ids: [
                        currentUser?.uid,
                        //...members.map((member) => member.firebaseUID),
                    ],
                });
                setMembershipDiscount((prevDiscount) => ({
                    ...prevDiscount,
                    ...data,
                }));
                let memberShipData = {};
            try {
                memberShipData.data = checkFirebaseIDExistsInMemberships(data, currentUser?.uid);

                if (checkFirebaseIDExists(data, currentUser?.uid)) {
                //   setFirebaseUIDAvailableForNormalBooking(true);
                //   pricePerPerson = courtPrice;
                  memberShipData.apply_discount_for_whole_booking = true;
                } else {
                //   setFirebaseUIDAvailableForNormalBooking(false);
                //   pricePerPerson = courtPrice / totalPlayers;
                  memberShipData.apply_discount_for_whole_booking = false;
                }
                memberShipData.user_name = currentUser?.displayName;
                memberShipData.user_email = currentUser?.email;
                if (!checkNullAndUndefined(userData)) {
                    memberShipData.latitude = userData?.location?.latitude;
                    memberShipData.longitute = userData?.location?.longitude;
                }
                memberShipData.membershipDiscount = data?.[currentUser?.uid];

                } catch (error) {

                } finally{
                  setMembershipData(memberShipData);
                }
            }
            catch (error) {
                console.log("Error", error);
            }
        }
        else {
            // Extract sport where value is true
            const result = bookingAtom?.clubActivity?.court_info?.map((item) => {
            const trueSport = Object.keys(item.sport).find((key) => item.sport[key]);
                return trueSport || null; // Return the key or null if none are true
            });
            try {
                const data = await getMembershipDiscounts({
                    activity_type: bookingAtom?.clubActivity?.activity?.toLowerCase() + "s",
                    sport_type: result ? result?.[0] : 'PADEL',
                    club_id: bookingAtom?.selectedCourt?.club_id ?? bookingAtom?.clubData?._id,
                    start_datetime: bookingAtom?.clubActivity?.start_datetime,
                    end_datetime: bookingAtom?.clubActivity?.stop_datetime,
                    player_ids: [
                        currentUser?.uid,
                        //...members.map((member) => member.firebaseUID),
                    ],
                });
                setMembershipDiscount((prevDiscount) => ({
                    ...prevDiscount,
                    ...data,
                }));
                let memberShipData = {};
                try {
                    memberShipData.data = checkFirebaseIDExistsInMemberships(data, currentUser?.uid);
                    memberShipData.apply_discount_for_whole_booking = checkFirebaseIDExists(data,currentUser?.uid);
                    memberShipData.user_name = currentUser?.displayName;
                    memberShipData.user_email = currentUser?.email;
                    if (!checkNullAndUndefined(userData)) {
                        memberShipData.latitude = userData?.location?.latitude;
                        memberShipData.longitute = userData?.location?.longitude;
                    }
                    memberShipData.membershipDiscount = data?.[currentUser?.uid];
                } catch (error) {

                } finally {
                    setMembershipData(memberShipData);
                }
            }
            catch (error) {
                console.log("Error", error);
            }
        }
    }

    useEffect(() => {
        if (currentUser === undefined || currentUser === null) {
            setBookingAtom({
                ...bookingAtom,
                page: 1
            });
        }
        if (
            bookingAtom.page === 1 &&
            currentUser !== undefined &&
            currentUser !== null
        ) {
            if (isSlot) {
                setBookingAtom({
                    ...bookingAtom,
                    page: 2
                });
            } else {
                setBookingAtom({
                    ...bookingAtom,
                    page: 13
                });
            }
            if(bookingAtom.show && currentUser){
                getMembershipDiscountFunction();
            }
        }
        else if (bookingAtom.page === 2 || bookingAtom.page === 13) {
            // console.log("its use effect change log 2");

            setProcessing(false);
            setErrorMessage("");
            if(!isSlot && bookingAtom.show && currentUser){
                getMembershipDiscountFunction();
            }
        }
    }, [bookingAtom.show, currentUser]);

    useEffect(() => {
        if (voucher) {
            calculatePrice(voucher);
        }
    }, [voucher, bookingAtom.mins]);

    const handleApplyVoucher = (vouchercode: string) => {
        if (vouchercode && vouchercode.length) {
            setVoucherErrorMessage("");
            setVoucherSuccessMessage("");
            setApplyingVoucher(true);
            applyCoupon(
                bookingData._id,
                vouchercode,
                setApplyingVoucher,
                setVoucherErrorMessage
            ).then((voucherResp: any) => {
                if (voucherResp?.data?.status) {
                    setApplyingVoucher(false);
                    setVoucher(voucherResp?.data);
                    const message = voucherResp?.data.max_discount_amount
                        ? `${t("youWillGet")} ${voucherResp?.data.amount_in_percentage}% ${t("discount")} ${t("upTo")} ${voucherResp?.data.max_discount_amount}`
                        : `${t("youWillGet")} ${voucherResp?.data.amount_in_percentage}% ${t("discount")}`;
                    setVoucherSuccessMessage(message);
                } else {
                    setApplyingVoucher(false);
                    setVoucherErrorMessage("failed to apply coupon");
                    setTimeout(() => {
                        setVoucherErrorMessage("");
                    }, 3000);
                }
            });
        }
    };

    const calculatePrice = (voucher: any) => {
        let discountPercentage = voucher.amount_in_percentage / 100;
        let actualPrice = bookingAtom.price;
        let totalPlayers = 4;
        if(bookingAtom?.selectedCourt?.double){
            totalPlayers = 4;
        } else {
            totalPlayers = 2;
        }
        if (isIndividualCouponCode()) {
            actualPrice = bookingAtom.price / totalPlayers;
        }
        let discVal = discountPercentage
            ? actualPrice * discountPercentage
            : 0;
        let totalPrice = bookingAtom.price - discVal;
        setBookingAtom({ ...bookingAtom, price: totalPrice });
        setDiscountAmount(discVal);
    };

    const isIndividualCouponCode = (voucher: any) => {
        if (voucher?.is_individual_discount && voucher?.sport_types?.length) {
          const sportsExists = voucher?.sport_types?.some(
              sport => sport.toLowerCase() === getSportType().toLowerCase()
          );

          return sportsExists;
        }
        return false;
    }

    const closeOverlyOnClickHandlder = (event: React.MouseEvent) => {
        if (
            event.target === event.currentTarget ||
            (event.target as HTMLEmbedElement).classList.contains(
                "wrapper-overlay"
            )
        ) {
            setBookingAtom({
                ...bookingAtom,
                clubActivity: {},
                show: !bookingAtom.show,
                isTournamentType: false,
                isTrainingType: false,
            });
            setInputCode('');
            setVoucher(null);
            setDiscountAmount(0);
            setVoucherSuccessMessage("");
            setTournamentDataMain(null);
            setSelectedActivity && setSelectedActivity("");
        }
    };

    // for add player
    const [searchInput, setSearchInput] = useState("");
    const [searchResults, setSearchResults] = useState<
        Array<{
            name: string;
            level: any;
            profilePic: string;
            sportType: string;
            centerName: string;
            id: string;
        }>
    >([]);

    interface Player {
        _id?: string;
        name?: string;
        email?: string;
        fcmToken?: any;
        profilePic?: any;
        level?: Level;
        points?: number;
        pro?: boolean;
        count?: number;
        ava?: number;
        pun?: number;
        lang?: string;
        isCoach?: boolean;
        onboarded?: boolean;
        ageRange?: string;
        gender?: string;
    }

    interface Level {
        name: string;
        im: number;
        des: string;
    }

    const handleAddPlayer = (item: Player) => {
        if (playerObject.length > 2) {
            setBookingAtom({
                ...bookingAtom,
                page: 31
            });
            alert("You can add only 2 players");
        } else {
            setplayerObject((pre) => [...pre, item]);
            setBookingAtom({
                ...bookingAtom,
                page: 31
            });
            setSearchInput("");
            setSearchResults([])
        }
    };

    const [lockPlayer, setlockPlayer] = useState("");

    const handleLockPlayer = () => {
        setBookingAtom({
            ...bookingAtom,
            page: 31
        });
    };

    const [otp, setOtp] = useState("");
    const [Loading, setLoading] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    const [isError, setisError] = useState(false);
    const [user, setUser] = useState({
        name: "",
        email: "",
        firebaseUID: "",
        _id: "",
        isCoach: false || "",
        onboarded: ""
    });
    // const fcmToken = localStorage.getItem("fcmToken");
    // const navigate = useNavigate();

    const input = {
        width: "50px",
        height: "50px",
        borderRight: "1px solid #a0aec0",
        outline: "none"
    };

    console.log("page no>>>>", bookingAtom.page);

    // console.log("bookingData>>>>", bookingData);
    // console.log("bookingAtom>>>>", bookingAtom);
    // console.log("currentUser", currentUser);
    // console.log("isSelected", isSelected);
    // console.warn("USer Credit", userCredit);
    const modalTitle = isQueued
    ? t("rUSureLeaveWaiting")
    : found === true
    ? bookingAtom.isTrainingType
      ? t("rUSureLeaveTraining")
      : t("rUSureLeaveTournament")
    : null;

    if (!bookingAtom.show) {
        return null;
    }
    const displayPopup = () => {
        if (errorOpen) {
            return (<AlertPopup
                isOpen={errorOpen}
                setIsOpen={setErrorOpen}
                message={errorMessage}
            />
            )
        }
    }
    if (bookingAtom.page === 1) {
        if (currentUser) {
            return (
                <>
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                Loading...
                            </h4>
                            <button
                                className="btn"
                                id="modal-close-btn"
                                onClick={closeOverlyOnClickHandlder}
                            >
                                <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>

                        <div className="my-10 flex flex-col gap-4 items-center justify-center">
                            <p className="text-xl">Please wait...</p>
                            <CgSpinner className="animate-spin text-[64px]" />
                        </div>
                    </div>
                </ClosableOverlay>
                {displayPopup()}
                </>
            );
        } else {
            return (
                <>
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                {t("login")}
                            </h4>
                            <button
                                className="outline-none"
                                id="modal-close-btn"
                                onClick={() =>
                                    setBookingAtom({
                                        ...bookingAtom,
                                        show: !bookingAtom.show
                                    })
                                }
                            >
                                <AiOutlineClose className="w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>

                        <div className="overflow-y-auto px-5 py-10">
                            <div className="max-w-[511px] mx-auto w-full flex flex-col justify-center items-center">
                                <div className="flex flex-col justify-center items-center w-full">
                                    <h1 className="text-2xl md:text-3xl xl:text-4xl 2xl:text-[42px] text-center font-bold">
                                        {t("Auth.signInTitle")}
                                    </h1>
                                    <button
                                        className="mt-[35px] flex items-center justify-center gap-3 shadow-md rounded-full h-[56px] max-w-[400px] mx-auto w-full hover:scale-105 hover:opacity-90 transform duration-200"
                                        onClick={signInWithGoogle}
                                    >
                                        <FcGoogle className="w-6 h-6" />
                                        <p className="text-lg font-semibold">
                                            {t("Auth.signInGoogle")}
                                        </p>
                                    </button>
                                    <button
                                        disabled={isPopupInProgress}
                                        className="mt-6 flex items-center justify-center gap-3 shadow-md rounded-full h-[56px] max-w-[400px] mx-auto w-full bg-[#576dff] text-white hover:scale-105 hover:opacity-90 transform duration-200"
                                        onClick={signInWithFacebook}
                                    >
                                        <BsFacebook className="w-6 h-6" />
                                        <p className="text-lg font-semibold">
                                            {t("Auth.signInFacebook")}
                                        </p>
                                        {isPopupInProgress && <CgSpinner className="animate-spin text-[24px]" />}
                                    </button>
                                </div>
                                <div className="mt-10 flex items-center gap-2 w-full max-w-[400px] mx-auto">
                                    <div className="bg-[#d9d9d9] w-full h-[1px]" />
                                    <p className="text-[#222222b3] text-lg font-semibold">
                                        Or
                                    </p>
                                    <div className="bg-[#d9d9d9] w-full h-[1px]" />
                                </div>
                                {isLoginError && loginErrorMessage && (
                                    <label className="text-[#FF3A3A] text-center mt-5">
                                        {loginErrorMessage}
                                    </label>
                                )}

                                <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                                    <div className="w-full max-w-[400px] mx-auto">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => {
                                                const newEmail =
                                                    e.currentTarget.value;
                                                setEmail(newEmail);
                                                if (newEmail) {
                                                    if (
                                                        !emailPattern.test(
                                                            newEmail
                                                        )
                                                    ) {
                                                        setEmailError(
                                                            `${t(
                                                                "Auth.invalidEmail"
                                                            )}`
                                                        );
                                                    } else {
                                                        setEmailError("");
                                                    }
                                                } else {
                                                    setEmailError(
                                                        `${t(
                                                            "Auth.emailRequired"
                                                        )}`
                                                    );
                                                }
                                            }}
                                            placeholder={t("Auth.emailAddress")}
                                            className={`${
                                                emailError
                                                    ? "border-red-500 outline-red-500"
                                                    : "border-[#000b2966] outline-primary"
                                            } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                            required
                                        />
                                        {emailError && (
                                            <p className="text-red-500 mt-1 ml-2 text-xs">
                                                {emailError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="w-full max-w-[400px] mx-auto">
                                        <div className="relative">
                                            <input
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                value={password}
                                                onChange={(e) => {
                                                    const newPassword =
                                                        e.currentTarget.value;
                                                    setPassword(newPassword);
                                                    newPassword
                                                        ? setPasswordError("")
                                                        : setPasswordError(
                                                              `${t(
                                                                  "Auth.passwordRequired"
                                                              )}`
                                                          );
                                                }}
                                                placeholder={t("Auth.password")}
                                                className={`${
                                                    passwordError
                                                        ? "border-red-500 outline-red-500"
                                                        : "border-[#000b2966] outline-primary"
                                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                                required
                                            />

                                            <button
                                                type="button"
                                                onClick={togglePasswordView}
                                                className={`absolute top-1/2 -translate-y-1/2 right-2 p-1 text-slate-950 ${
                                                    showPassword
                                                        ? "text-opacity-50"
                                                        : "text-opacity-30"
                                                }`}
                                            >
                                                {showPassword ? (
                                                    <BsEyeFill size={18} />
                                                ) : (
                                                    <BsEyeSlash size={18} />
                                                )}
                                            </button>
                                        </div>
                                        {passwordError && (
                                            <p className="text-red-500 mt-1 ml-2 text-xs">
                                                {passwordError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between flex-wrap gap-3 w-full max-w-[390px] mx-auto">
                                        <label
                                            htmlFor="staySignIn"
                                            className="text-lg flex items-center gap-2 cursor-pointer"
                                        >
                                            <input
                                                id="staySignIn"
                                                type="checkbox"
                                                className="w-5 h-5 cursor-pointer"
                                            />{" "}
                                            {t("Auth.staySignedIn")}
                                        </label>
                                        <a
                                            className="text-lg hover:scale-105 hover:underline transform duration-200"
                                            style={{ color: "#222" }}
                                            href="/forgot-password"
                                        >
                                            {t("Auth.forgotPassword")}
                                        </a>
                                    </div>
                                    <input
                                        onClick={onLogin}
                                        type="submit"
                                        value={t("MenuUser.signIn")}
                                        className="bg-secondary text-white rounded-full w-full max-w-[400px] mx-auto shadow-md h-[56px] flex items-center justify-center py-1 px-4 mt-5 text-xl font-semibold hover:bg-primary hover:text-secondary hover:scale-105 transform duration-200 cursor-pointer"
                                    />
                                </form>
                                <p className="flex text-lg justify-between items-center flex-wrap gap-2 mt-[35px]">
                                    {t("Auth.dontHaveAccount")}
                                    <a
                                        style={{
                                            color: "#43EC9B",
                                            textDecoration: "none"
                                        }}
                                        href="/sign-up"
                                        className="font-semibold hover:scale-105 transform duration-200"
                                    >
                                        {t("MenuUser.signUp")}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </ClosableOverlay>
                {displayPopup()}
                </>
            );
        }
    } else if (bookingAtom.page === 2) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {t("BookingModal.checkout")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5 relative">
                        {processing ? (
                            <div className="absolute left-0 right-0 top-0 bottom-0 bg-white z-50">
                                <div className="flex flex-col gap-4 items-center justify-center">
                                    <p className="text-center mt-7">
                                        {t("BookingModal.pleaseWait")}
                                    </p>
                                    <CgSpinner className="animate-spin text-[64px]" />
                                </div>
                            </div>
                        ) : null}

                        <CourtComponent
                            bookingData={bookingData}
                            isBooked={false}
                            minutes={bookingAtom.mins}
                            endTime={bookingAtom.endTime}
                            selectedCourt={bookingAtom.selectedCourt}
                            startTime={bookingAtom.startTime}
                            accessCode={accessCode}
                            bookingStart={bookingAtom?.clubActivity?.start_datetime}
                            timeZone={bookingAtom?.clubActivity?.timezone}
                            isSlot={isSlot}
                            startDateTime={bookingAtom?.clubActivity?.start_datetime}
                            stopDateTime={bookingAtom?.clubActivity?.stop_datetime}
                        />
                        <hr className="w-full" />
                        <div className="py-5">
                            {/* <ExtraTimeManager
                                changeTime={calculateHours}
                                minutesSetter={minutesSetter}
                                priceSetter={priceSetter}
                                selectedCourt={bookingAtom.selectedCourt}
                                availableSlotState={
                                    bookingAtom.availableSlotState
                                }
                                minutes={bookingAtom.mins}
                                setMins={setMins}
                                time={bookingAtom.selectedSlot}
                                price={bookingAtom.price}
                                currency={currency}
                                setEndTime={setEndTime}
                                basePrice={bookingAtom.basePrice}
                            />
                            <hr className="w-full mt-4 mb-3" /> */}
                            <Coupon
                                voucher={voucher}
                                setVoucher={setVoucher}
                                clubId={bookingData?._id}
                            />
                            <div className="text-center">
                                <SubTotalComponent
                                    currentPrice={bookingAtom.price}
                                    bookingData={bookingData}
                                    discountAmount={discountAmount}
                                    voucher={voucher}
                                    membershipDiscount={membershipDiscount[currentUser?.uid]}
                                    membershipDiscountAmount={membershipDiscountAmount}
                                    tournamentData={tournamentDataMain}
                                    clubData={clubData}
                                />

                                {/* <div className="font-semibold text-[#FF3A3A] text-center mt-7">
                                    {errorMessage}
                                </div> */}
                                <button
                                    id="continueToPay"
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl mt-7 w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() => {
                                        handleCheckForNameAndPhoneNumber();
                                    }}
                                >
                                    {t("BookingModal.continue")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 3) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <PopupInfo
                        title={infoPopupTitle}
                        subTitle={infoPopupSubtitle}
                        isOpen={isLoadingPayment}
                        setIsOpen={setIsLoadingPayment}
                        isLoading={true}
                    />
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {t("BookingModal.Payment")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5">
                        <BookingDetails
                            startTime={bookingAtom.startTime}
                            endTime={bookingAtom.endTime}
                            minutes={bookingAtom.mins}
                            finalPrice={bookingAtom.price}
                            bookingData={bookingData}
                            selectedCourt={bookingAtom.selectedCourt}
                            showBookingDetails={false}
                            accessCode={accessCode}
                            isBooked={false}
                            receiptUrl={receiptUrl}
                            isTournamentType={bookingAtom.isTournamentType}
                            isTrainingType={bookingAtom.isTrainingType}
                            bookingStart={bookingAtom?.clubActivity?.start_datetime}
                            timeZone={bookingAtom?.clubActivity?.timezone}
                            isSlot={isSlot}
                            startDateTime={bookingAtom?.clubActivity?.start_datetime}
                            stopDateTime={bookingAtom?.clubActivity?.stop_datetime}
                        />

                        <div className="relative">
                            {processing ? (
                                <div className="absolute w-full h-full bg-white z-50">
                                    <div className="flex flex-col gap-4 items-center justify-center">
                                        <p className="text-center">
                                            {bookingAtom?.isTournamentType ||
                                            bookingAtom?.isTrainingType
                                                ? t("BookingModal.holdRequest")
                                                : t("BookingModal.hold")}
                                        </p>
                                        <CgSpinner className="animate-spin text-[64px]" />
                                    </div>
                                </div>
                            ) : null}
                            <div
                                className={`${
                                    processing ? "h-0 overflow-hidden" : ""
                                }`}
                            >
                                <PaymentComponent
                                    price={bookingAtom.price}
                                    setSelectedCard={setPaymentMethodId}
                                    selectedCard={paymentMethodId}
                                    clubId={bookingData?._id}
                                    currency={currency}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    userCredit={userCredit}
                                    setUserCredit={setUserCredit}
                                    setErrorMessage={setErrorMessage}
                                    saveCardForFutureUse={saveCardForFutureUse}
                                    setSaveCardForFutureUse={
                                        setSaveCardForFutureUse
                                    }
                                    cardDetails={cardDetails}
                                    setCardDetails={setCardDetails}
                                    handleCardElementSelect={
                                        handleCardElementSelect
                                    }
                                />
                            </div>

                            <SubTotalComponent
                                currentPrice={bookingAtom.price}
                                bookingData={bookingData}
                                discountAmount={discountAmount}
                                voucher={voucher}
                                membershipDiscount={membershipDiscount[currentUser?.uid]}
                                membershipDiscountAmount={membershipDiscountAmount}
                                tournamentData={tournamentDataMain}
                                clubData={clubData}
                            />

                            <div className="my-10">
                                {/* <div id="payError" className="font-semibold text-[#FF3A3A] text-center mb-5">
                                    {errorMessage}
                                </div> */}
                                <div className="flex items-center justify-center gap-5">
                                    <button
                                        className="bg-white text-secondary border border-secondary px-4 py-1 h-[64px] flex items-center justify-center w-[calc(50%-20px)] rounded-[24px] text-[19px] font-semibold hover:bg-[#FF3A3A] hover:border-[#FF3A3A] hover:text-white transform duration-200"
                                        onClick={() => {
                                            setBookingAtom({
                                                ...bookingAtom,
                                                page: 2
                                            });
                                        }}
                                        disabled={processing}
                                    >
                                        {t("back")}
                                    </button>
                                    <button
                                        id="payNowBtn"
                                        className="bg-secondary text-white border border-secondary px-4 py-1 h-[64px] flex items-center justify-center w-[calc(50%-20px)] rounded-[24px] text-[19px] font-semibold hover:bg-primary hover:border-primary hover:text-secondary transform duration-200"
                                        onClick={handlePayment}
                                        disabled={processing}
                                    >
                                        {t("BookingModal.pay")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 10) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {t("BookingModal.promoCode")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5">
                        <PromoComponent />
                        <div className="mt-10">
                            <BookingDetails
                                startTime={bookingAtom.startTime}
                                endTime={bookingAtom.endTime}
                                minutes={bookingAtom.mins}
                                finalPrice={bookingAtom.price}
                                bookingData={bookingData}
                                selectedCourt={bookingAtom.selectedCourt}
                                showBookingDetails={false}
                                accessCode={accessCode}
                                isBooked={false}
                                receiptUrl={receiptUrl}
                                isTournamentType={bookingAtom.isTournamentType}
                                isTrainingType={bookingAtom.isTrainingType}
                                bookingStart={bookingAtom?.clubActivity?.start_datetime}
                                timeZone={bookingAtom?.clubActivity?.timezone}
                                isSlot={isSlot}
                                startDateTime={bookingAtom?.clubActivity?.start_datetime}
                                stopDateTime={bookingAtom?.clubActivity?.stop_datetime}
                            />
                            <ShareWithFriends />
                            <div className="flex flex-col items-center justify-center my-8 gap-2">
                                <img
                                    src="/images/verified.svg"
                                    alt="booked logo"
                                />
                                <p>{t("BookingModal.booked")}</p>
                            </div>
                            <div className="mb-10 flex items-center justify-center">
                                <button
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() =>
                                        setBookingAtom({
                                            ...bookingAtom,
                                            show: !bookingAtom.show
                                        })
                                    }
                                >
                                    {t("BookingModal.bookNow")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 11) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed w-[96%] md:min-w-[530px] md:w-[30%] bg-white text-secondary z-[10000] flex flex-col gap-0 rounded-md shadow-sm max-h-[80%]">
                    <div className="text-secondary pt-4 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-bold px-8">
                        <h4>{t("BookingModal.becomeMember")}</h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className=" overflow-y-auto px-5">
                        <BecomeMemberForm
                            saveCardForFutureUse={saveCardForFutureUse}
                            setSaveCardForFutureUse={setSaveCardForFutureUse}
                            cardDetails={cardDetails}
                            setCardDetails={setCardDetails}
                            handleCardElementSelect={handleCardElementSelect}
                            cardElement={cardElement}
                        />
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 12) {
        return (
            <>
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                {t("BookingModal.checkout")}
                            </h4>
                            <button
                                className="btn"
                                id="modal-close-btn"
                                onClick={closeOverlyOnClickHandlder}
                            >
                                <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>
                        <div className="overflow-y-auto px-5">
                            <CourtComponent
                                bookingData={bookingData}
                                isBooked={false}
                                minutes={bookingAtom.mins}
                                endTime={bookingAtom.endTime}
                                selectedCourt={bookingAtom.selectedCourt}
                                startTime={bookingAtom.startTime}
                                accessCode={accessCode}
                                bookingStart={bookingAtom?.clubActivity?.start_datetime}
                                timeZone={bookingAtom?.clubActivity?.timezone}
                                isSlot={isSlot}
                                startDateTime={bookingAtom?.clubActivity?.start_datetime}
                                stopDateTime={bookingAtom?.clubActivity?.stop_datetime}
                            />
                            <hr className="w-full" />
                            <div className="py-5">
                                <UpdateUserInfo
                                    onBack={() => {
                                        setBookingAtom({
                                            ...bookingAtom,
                                            page: 2
                                        });
                                    }}
                                    onSubmit={handleUpdateUserInfo}
                                    userName={userName}
                                    userPhone={userPhone}
                                />
                            </div>
                        </div>
                    </div>
                </ClosableOverlay>
                {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 13 || bookingAtom.page === 131) {
        return (
            <>
                <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                    <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                            <h4 className="text-2xl lg:text-3xl font-semibold">
                                {bookingAtom?.isTrainingType === true
                                    ? t("BookingModal.trainingGroup")
                                    : t("BookingModal.infoTournament")}
                            </h4>
                            <button
                                className="btn"
                                id="modal-close-btn"
                                onClick={closeOverlyOnClickHandlder}
                            >
                                <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                            </button>
                        </div>
                        <div className="overflow-y-auto px-5 relative">
                            {processing ? (
                                <div className="absolute left-0 right-0 top-0 bottom-0 bg-white z-50">
                                    <div className="flex flex-col gap-4 items-center justify-center">
                                        <p className="text-center mt-7">
                                            {t("BookingModal.pleaseWait")}
                                        </p>
                                        <CgSpinner className="animate-spin text-[64px]" />
                                    </div>
                                </div>
                            ) : null}
                            <hr className="w-full" />
                            <div className="py-5">
                                <div>
                                    <div className="relative rounded-[10px] shadow border border-[#000b29] border-opacity-[0.15] py-4 px-4">
                                        <div className="flex gap-8 flex-wrap items-center">
                                        <img
                                            src={
                                                tournamentDataMain?.banner_images_url?.[0]?.bannerImg
                                                ? tournamentDataMain.banner_images_url[0].bannerImg
                                                : bookingAtom?.isTournamentType
                                                ? "/images/tournamentCardBackgroundNew.png"
                                                : bookingAtom?.isTrainingType
                                                ? "/images/trainingCardBackground.png"
                                                : bookingData?.photo_url // Optional fallback if none of the conditions are true
                                            }
                                            alt="Activity Banner"
                                            className="w-[280px] h-[140px] rounded-[8px] shadow shadow-gray-500"
                                        />
                                        <div className="flex flex-col gap-2">
                                        <div className="flex flex-col gap-2 h-full w-full">
                                                <div className="flex flex-col">
                                                    <p id="activityType" className="flex items-center gap-2 font-medium uppercase text-[16px]">
                                                        <img
                                                            src="/images/trophy.svg"
                                                            alt="trophy"
                                                        />
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.activity
                                                        }{" "}
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.tournament_type
                                                        }
                                                    </p>
                                                    <p className="text-sm font-medium">
                                                        {bookingAtom
                                                            ?.clubActivity
                                                            ?.start_datetime
                                                            ? moment
                                                                  .tz(
                                                                      bookingAtom
                                                                          ?.clubActivity
                                                                          ?.start_datetime,
                                                                      bookingAtom
                                                                          ?.clubActivity
                                                                          ?.timezone
                                                                  )
                                                                  .format("ddd")
                                                            : ""}{" "}
                                                        {bookingAtom
                                                            ?.clubActivity
                                                            ?.start_datetime
                                                            ? moment
                                                                  .tz(
                                                                      bookingAtom
                                                                          ?.clubActivity
                                                                          ?.start_datetime,
                                                                      bookingAtom
                                                                          ?.clubActivity
                                                                          ?.timezone
                                                                  )
                                                                  .format(
                                                                      "MM/DD HH:mm"
                                                                  )
                                                            : ""}{" "}
                                                        -{" "}
                                                        {bookingAtom
                                                            ?.clubActivity
                                                            ?.stop_datetime
                                                            ? moment
                                                                  .tz(
                                                                      bookingAtom
                                                                          ?.clubActivity
                                                                          .stop_datetime,
                                                                      bookingAtom
                                                                          ?.clubActivity
                                                                          ?.timezone
                                                                  )
                                                                  .format(
                                                                      "HH:mm"
                                                                  )
                                                            : ""}
                                                    </p>
                                                    <h4 className="font-bold text-[22px]">
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.title
                                                        }
                                                    </h4>
                                                    <p className="flex items-center gap-2 font-medium text-[16px]">
                                                        <img
                                                            src="/images/location.svg"
                                                            alt="location"
                                                        />
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.address
                                                        }{" "}
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.city
                                                        }{" "}
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.country
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 font-medium w-full sm:w-auto">
                                                <div className="flex gap-2 flex-wrap items-center">
                                                    <img
                                                        src="/images/badge.svg"
                                                        alt="badge"
                                                        className={`${"w-6"}`}
                                                    />
                                                    <p
                                                        className={`${"text-sm"} font-medium`}
                                                    >
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.min_level
                                                        }
                                                        -
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.max_level
                                                        }
                                                    </p>
                                                </div>
                                                <div
                                                    className={`text-white ${"text-[13px]"} h-[31px] flex gap-[1px] items-center cursor-pointer`}
                                                >
                                                    <button
                                                        className={`${"text-[13px] rounded-l-full h-[31px] px-2.5"} bg-secondary font-medium`}
                                                    >
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.current_no_of_players
                                                        }
                                                        /
                                                        {
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.no_of_players
                                                        }
                                                    </button>
                                                    <button
                                                        className={`${"text-[13px] rounded-r-full w-[100px] sm:w-auto h-[31px] px-2.5"} bg-secondary font-semibold`}
                                                    >
                                                        {bookingAtom
                                                            ?.clubActivity
                                                            ?.payment_per_person !==
                                                        0
                                                            ? bookingAtom?.clubActivity?.payment_per_person?.toFixed(
                                                                  0
                                                              )
                                                            : 0}{" "}
                                                        {bookingAtom
                                                            ?.clubActivity
                                                            ?.currency
                                                            ? bookingAtom
                                                                  ?.clubActivity
                                                                  ?.currency
                                                            : "kr"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    {/* <div className="font-semibold text-[#FF3A3A] text-center mt-4">
                                        {errorMessage}
                                    </div> */}
                                    {!isButtonLoading && <>
                                        {shouldApplyForQueue ?
                                        <button
                                            id="waitingBtn"
                                            className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl mt-3 w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                            onClick={() => {
                                                if (
                                                    bookingAtom.clubActivity
                                                        ?.tournament_type ===
                                                    "team_americano"
                                                ) {
                                                    if(isQueued){
                                                        // removeFromQueueAmericano();
                                                        setIsLeaveModal(true)
                                                    }
                                                    else{
                                                        setBookingAtom({
                                                            ...bookingAtom,
                                                            page: 31
                                                        });
                                                    }
                                                }
                                                else{
                                                    if(isQueued){
                                                        // removeFromQueueFunction();
                                                        setIsLeaveModal(true)
                                                    }
                                                    else{
                                                        handleJoinTournament();
                                                    }
                                                }
                                            }}
                                        >
                                            {isQueued === true ? t("leaveWaitingList") : t('joinWaitingList')}
                                        </button>
                                        :
                                        <button
                                        id="notWaitingBtn"
                                        className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl mt-3 w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                        onClick={() => {
                                            if (
                                                bookingAtom.clubActivity
                                                    ?.tournament_type ===
                                                "team_americano"
                                            ) {
                                                if (found == true) {
                                                    // _leaveTeamAmericano();
                                                    setIsLeaveModal(true)
                                                } else {
                                                    if (isRequest === true) {
                                                        takeBackRequestAmericano();
                                                    } else {
                                                        if (tournamentDataMain?.gameCode) {
                                                            setOpenOtpModal(true);
                                                        }
                                                        else {
                                                            setBookingAtom({
                                                                ...bookingAtom,
                                                                page: 31
                                                            });
                                                        }
                                                    }
                                                }
                                            } else {
                                                if (found == true) {
                                                    // _leaveTournament();
                                                    setIsLeaveModal(true)
                                                } else if (isRequest === true) {
                                                    takeBackRequest();
                                                } else {
                                                    if (tournamentDataMain?.gameCode) {
                                                        setOpenOtpModal(true);
                                                    }
                                                    else {
                                                        handleJoinTournament();
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        {found === true
                                            ? bookingAtom.isTrainingType ===
                                            true
                                                ? t("BookingModal.leaveTraining")
                                                : t(
                                                    "BookingModal.leaveTournament"
                                                )
                                            : isRequest == true
                                            ? t("BookingModal.takeback")
                                            : bookingAtom.isTrainingType ===
                                            true
                                            ? t("BookingModal.bookTraining")
                                            : t("BookingModal.joinTournament")}
                                    </button>
                                }
                                    </>}
                                </div>
                                <div className="px-4">
                                    <h3 className="text-xl font-bold mb-2 mt-7">
                                        {t("admin")}
                                    </h3>
                                    <div className="relative">
                                        {/* Admin Heading */}

                                        {/* Admin Info */}
                                        <div className="flex flex-col gap-3">
                                            <div className="flex items-center gap-2">
                                                {/* Admin Photo */}
                                                                <img
                                                                    src={bookingAtom?.clubData?.photo_url||dummyProfileNew}
                                                                    alt={bookingAtom?.clubData?.name}
                                                                    className="min-w-10 max-w-10 h-10 rounded-full"
                                                                />
                                                           
                                                {/* Admin Name */}
                                                <div className=" text-right mr-6">
                                                    <span className="font-semibold ml-4">
                                                        {
                                                            bookingAtom?.clubData?.name
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!isButtonLoading && queue && queue?.length > 0 ?
                                    <div>
                                        <h3 className="text-xl font-bold mb-2 mt-7">
                                            {t("waitingList")}
                                        </h3>
                                        <div className="flex flex-col gap-2">
                                        {queue?.map((tournament, index)=>(
                                            <div key={index}>
                                                {(tournament?.player1 || tournament?.player2) ? (<div
                                            className="relative w-[100px] sm:w-auto"
                                        >
                                            <div className="flex flex-col gap-3">
                                                <div className="flex items-center">
                                                    <div className="flex items-center gap-1">
                                                    <div className="gap-1 relative flex flex-col items-center">
                                                            <img
                                                                src={tournament?.player1?.profilePic||dummyProfileNew}
                                                                alt={tournament?.player1?.name}
                                                                className="w-10 h-10 min-w-10 max-w-10 rounded-full"
                                                            />
                                                            {tournament?.player1 && !tournament?.player1?.email?.includes("@mailinator.com") && (
                                                            <>
                                                            <div className={`bg-secondary w-8 h-4 border 
                                                             ${
                                                                fillColor ? `border-${fillColor}` : 'border-lightYellow'
                                                              }
                                                            items-center justify-center self-center flex rounded-full z-10 absolute -bottom-2 `}>
                                                                <span className="text-[10px] text-white  ">
                                                                    {correctLevelWithPoints(tournament?.player1)}
                                                                </span>
                                                            </div>
                                                            <ProfileELOBadge player={tournament?.player1} gameType={tournament?.player1?.gameType} containerStyle='' onFillChange={handleFillChange} userData={userData} />
                                                            </>
                                                            )}
                                                            </div>
                                                    <div className="gap-1 relative flex flex-col items-center">
                                                            <img
                                                                src={tournament?.player2?.profilePic|| dummyProfileNew}
                                                                alt={tournament?.player2?.name}
                                                                className="w-10 h-10 rounded-full"
                                                            />
                                                        {tournament?.player2 && !tournament?.player2?.email?.includes("@mailinator.com") && (
                                                            <>
                                                            <div className={`bg-secondary w-8 h-4 border 
                                                             ${
                                                                fillColor ? `border-${fillColor}` : 'border-lightYellow'
                                                              }
                                                            items-center justify-center self-center flex rounded-full z-10 absolute -bottom-2 `}>
                                                                <span className="text-[10px] text-white  ">
                                                                    {correctLevelWithPoints(tournament?.player2)}
                                                                </span>
                                                            </div>
                                                            <ProfileELOBadge player={tournament?.player2} gameType={tournament?.player2?.gameType} containerStyle='' onFillChange={handleFillChange} userData={userData} />
                                                            </>
                                                            )}
                                                            </div>
                                                    </div>
                                                    <div className="flex items-center mr-4 gap-4 ms-7">
                                                        <span>
                                                            {
                                                                tournament
                                                                    ?.player1
                                                                    ?.name
                                                            }
                                                            &nbsp;&&nbsp;
                                                            {
                                                                tournament
                                                                    ?.player2
                                                                    ?.name
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>) : (
                                                    <div
                                                        className="relative mb-3"
                                                    >
                                                        <div className="flex flex-col gap-3">
                                                            <div className="flex  items-center">
                                                                <div className="flex items-center gap-1">
                                                                <div className="gap-1 relative flex flex-col items-center">
                                                                    <img
                                                                        src={tournament?.profilePic||dummyProfileNew}
                                                                        alt={tournament?.name}
                                                                        className="w-10 h-10 rounded-full"
                                                                    />
                                                                {tournament && !tournament?.email?.includes("@mailinator.com") && (
                                                            <>
                                                            <div className={`bg-secondary w-8 h-4 border 
                                                             ${
                                                                fillColor ? `border-${fillColor}` : 'border-lightYellow'
                                                              }
                                                            items-center justify-center self-center flex rounded-full z-10 absolute -bottom-2 `}>
                                                                <span className="text-[10px] text-white  ">
                                                                    {correctLevelWithPoints(tournament)}
                                                                </span>
                                                            </div>
                                                            <ProfileELOBadge player={tournament} gameType={tournament?.gameType} containerStyle='' onFillChange={handleFillChange} userData={userData} />
                                                            </>
                                                            )}
                                                            </div>
                                                                </div>
                                                                <div className="flex items-center mr-4 gap-4 ms-7">
                                                                    <span>
                                                                        {
                                                                            tournament?.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                    : null}
                                    <div className="flex content-center items-center ">
                                    <h3 className="text-xl font-bold mb-2 mt-7">
                                    {bookingAtom?.clubActivity
                                        ?.tournament_type !==
                                        "team_americano"? t("players"):t("teams")}
                                    </h3>
                                    {!isButtonLoading && <span className="mb-2 mt-7 ml-3">
                                    ({bookingAtom?.clubActivity?.current_no_of_players ?? 0}/{bookingAtom?.clubActivity?.no_of_players})
                                    </span>}
                                    </div>
                                    {isButtonLoading ? <div className="flex justify-center items-center"><CgSpinner className="animate-spin text-[64px]" /></div> :
                                    (<div className="flex flex-col gap-2">
                                    {tournamentAmericanoData &&
                                        tournamentAmericanoData?.length > 0 &&
                                        tournamentAmericanoData?.map(
                                            (tournament, index) => (
                                                <div
                                                    key={index}
                                                    className="relative w-[100px] sm:w-auto mb-3"
                                                >
                                                    <div className="flex flex-col gap-3">
                                                        <div className="flex items-center">
                                                            <div className="flex items-center gap-1">
                                                            <div className="gap-1 relative flex flex-col items-center">
                                                                <img
                                                                    src={tournament?.player1?.profilePic|| dummyProfileNew}
                                                                    alt={tournament?.player1?.name}
                                                                    className="min-w-10 max-w-10 h-10 rounded-full"
                                                                />
                                                             {tournament?.player1 && !tournament?.player1?.email?.includes("@mailinator.com") && (
                                                            <>
                                                            <div className={`bg-secondary w-8 h-4 border 
                                                             ${
                                                                fillColor ? `border-${fillColor}` : 'border-lightYellow'
                                                              }
                                                            items-center justify-center self-center flex rounded-full z-10 absolute -bottom-2 `}>
                                                                <span className="text-[10px] text-white  ">
                                                                    {correctLevelWithPoints(tournament?.player1)}
                                                                </span>
                                                            </div>
                                                            <ProfileELOBadge player={tournament?.player1} gameType={tournament?.player1?.gameType} containerStyle='' onFillChange={handleFillChange} userData={userData} />
                                                            </>
                                                            )}
                                                            </div>
                                                             <div className="gap-1 relative flex flex-col items-center ">
                                                                <img
                                                                    src={tournament?.player2?.profilePic|| dummyProfileNew}
                                                                    alt={tournament?.player2?.name}
                                                                    className="min-w-10 max-w-10 h-10 rounded-full"
                                                                />
                                                            {tournament?.player2 && !tournament?.player2?.email?.includes("@mailinator.com") && (
                                                            <>
                                                            <div className={`bg-secondary w-8 h-4 border 
                                                             ${
                                                                fillColor ? `border-${fillColor}` : 'border-lightYellow'
                                                              }
                                                            items-center justify-center self-center flex rounded-full z-10 absolute -bottom-2 `}>
                                                                <span className="text-[10px] text-white  ">
                                                                    {correctLevelWithPoints(tournament?.player2)}
                                                                </span>
                                                            </div>
                                                            <ProfileELOBadge player={tournament?.player2} gameType={tournament?.player2?.gameType} containerStyle='' onFillChange={handleFillChange} userData={userData} />
                                                            </>
                                                            )}
                                                            </div>
                                                            <div className="flex items-center mr-4 gap-4 ms-7">
                                                                <span>
                                                                    {
                                                                        tournament
                                                                            ?.player1
                                                                            ?.name
                                                                    }
                                                                    &nbsp;&&nbsp;
                                                                    {
                                                                        tournament
                                                                            ?.player2
                                                                            ?.name
                                                                    }
                                                                </span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}

{Array.isArray(
                                        tournamentDataMain?.players
                                    ) &&
                                        bookingAtom?.clubActivity
                                            ?.tournament_type !==
                                            "team_americano" &&
                                        tournamentDataMain?.players?.length >
                                            0 &&
                                        tournamentDataMain?.players?.map(
                                            (
                                                tournament: any,
                                                index: number
                                            ) => (
                                                <div
                                                    key={index}
                                                    className="relative"
                                                >
                                                    <div className="flex flex-col gap-3">
                                                        <div className="flex items-center">
                                                            <div className="gap-1 relative flex flex-col items-center mb-3">
                                                                <img
                                                                    src={tournament?.profilePic||dummyProfileNew}
                                                                    alt={tournament?.name}
                                                                    className="min-w-10 max-w-10 h-10 rounded-full"
                                                                />
                                                            {tournament && !tournament?.email?.includes("@mailinator.com") && (
                                                            <>
                                                            {/* // border-${fillColor ?? 'yellow-400'}  */}

                                                            <div className={`bg-secondary w-8 h-4 border 
                                                             ${
                                                                fillColor ? `border-${fillColor}` : 'border-lightYellow'
                                                              }
                                                            items-center justify-center self-center flex rounded-full z-10 absolute -bottom-2 `}>
                                                                <span className="text-[10px] text-white  ">
                                                                    {correctLevelWithPoints(tournament)}
                                                                </span>
                                                            </div>
                                                            <ProfileELOBadge player={tournament} gameType={tournament?.gameType} containerStyle='' onFillChange={handleFillChange} userData={userData} />
                                                            </>
                                                            )}
                                                            </div>
                                                            <div className="flex items-center mr-4 gap-4 ms-7">
                                                                <span>
                                                                    {
                                                                        tournament?.name
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>)}
                                </div>
                            </div>
                        </div>
                        {isLeaveModal && (
                            <div className="fixed inset-0 flex justify-center items-center z-[5000] bg-[#00000080]">
                                <div className="bg-white p-5 lg:py-8 lg:px-16 font-semibold rounded-2xl w-full max-w-lg mx-auto z-[5100]">
                                    <h3 className="text-center text-2xl mb-10">
                                        {modalTitle}
                                    </h3>
                                    <div className="flex justify-around mt-10">
                                        <button
                                            onClick={() => {
                                                if (shouldApplyForQueue) {
                                                    if (
                                                        bookingAtom.clubActivity
                                                            ?.tournament_type ===
                                                        "team_americano"
                                                    ) {
                                                        if (isQueued) {
                                                            removeFromQueueAmericano();
                                                        }
                                                    } else if (isQueued) {
                                                        removeFromQueueFunction();
                                                    }
                                                } else if (
                                                    bookingAtom.clubActivity
                                                        ?.tournament_type ===
                                                    "team_americano"
                                                ) {
                                                    if (found == true) {
                                                        _leaveTeamAmericano();
                                                    }
                                                } else {
                                                    if (found == true) {
                                                        _leaveTournament();
                                                    }
                                                }
                                            }}
                                            className="bg-secondary text-white hover:text-secondary hover:bg-white hover:border hover:border-secondary rounded-full px-10 h-[49px] text-[19px]"
                                        >
                                            Yes
                                        </button>
                                        <button
                                            onClick={() => {
                                                setIsLeaveModal(false);
                                            }}
                                            className="bg-secondary text-white hover:text-secondary hover:bg-white hover:border hover:border-secondary rounded-full px-10 h-[49px] text-[19px]"
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {openOtpModal && (
                            <>
                                <Modal open={openOtpModal} setOpen={setOpenOtpModal}>
                                    <div className="bg-white font-semibold rounded w-full max-w-lg sm:min-w-[512px] mx-auto z-[5100]">
                                        <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                                            <h4 className="text-2xl lg:text-3xl font-semibold">{t("enterCode")}</h4>
                                            <button
                                                className="btn"
                                                onClick={()=>{
                                                    setInputCode("");
                                                    setOpenOtpModal(false)
                                                }}
                                            >
                                                <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                                            </button>
                                        </div>
                                        <div className="overflow-y-auto px-5 relative">
                                            <div className="flex flex-col items-center my-12">
                                                <h5 className="mb-5 text-2xl sm:text-3xl font-medium">{t("pleaseEnterCode")}</h5>
                                                <OTPInput
                                                    value={inputCode}
                                                    onChange={(code)=>{setInputCode(code)}}
                                                    numInputs={4}
                                                    shouldAutoFocus
                                                    renderInput={(props) => <input {...props} />}
                                                    containerStyle={{ justifyContent: 'center' }}
                                                    inputStyle={{
                                                    width: '50px',
                                                    height: '50px',
                                                    margin: '0 10px',
                                                    fontSize: '20px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #ccc',
                                                    textAlign: 'center',
                                                    outlineColor: "#43EC9B"
                                                    }}
                                                />
                                                <button
                                                    onClick={confirmationFunc}
                                                    className="mt-5 py-2.5 px-5 bg-secondary text-white rounded-lg cursor-pointer"
                                                >
                                                    {t("Auth.verify")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                        )}
                    </div>
                </ClosableOverlay>
                {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 14) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="text-white bg-secondary py-5 flex items-center justify-between flex-wrap gap-4 md:rounded-t text-2xl font-semibold px-5">
                        <h4 className="text-2xl lg:text-3xl font-semibold">
                            {bookingAtom?.isTrainingType === true
                                ? t("BookingModal.trainingGroup")
                                : t("BookingModal.tournament")}
                        </h4>
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5 relative">
                        {processing ? (
                            <div className="absolute left-0 right-0 top-0 bottom-0 bg-white z-50">
                                <div className="flex flex-col gap-4 items-center justify-center">
                                    <p className="text-center mt-7">
                                        {t("BookingModal.pleaseWait")}
                                    </p>
                                    <CgSpinner className="animate-spin text-[64px]" />
                                </div>
                            </div>
                        ) : null}

                        <hr className="w-full" />
                        <div className="py-5">
                            <div>
                                <div className="relative rounded-[10px] shadow border border-[#000b29] border-opacity-[0.15] px-2 pt-5 pb-7 sm:px-4 w-full h-full">
                                    <div className="flex gap-3 flex-wrap items-end justify-between ">
                                        <div className="flex flex-col gap-2 justify-between items-stretch h-full w-full">
                                            <div className="flex flex-col gap-1 w-full">
                                                <p className="text-xs flex items-center gap-2 font-medium uppercase">
                                                    <img
                                                        src="/images/trophy.svg"
                                                        alt="trophy"
                                                    />
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.activity
                                                    }{" "}
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.tournament_type
                                                    }
                                                </p>
                                                <p className="text-sm font-medium">
                                                    {bookingAtom?.clubActivity
                                                        ?.start_datetime
                                                        ? moment.tz(
                                                              bookingAtom
                                                                  ?.clubActivity
                                                                  ?.start_datetime,
                                                                  bookingAtom
                                                                  ?.clubActivity
                                                                  ?.timezone
                                                          ).format("ddd")
                                                        : ""}{" "}
                                                    {bookingAtom?.clubActivity
                                                        ?.start_datetime
                                                        ? moment.tz(
                                                              bookingAtom
                                                                  ?.clubActivity
                                                                  ?.start_datetime,
                                                                  bookingAtom
                                                                  ?.clubActivity
                                                                  ?.timezone
                                                          ).format(
                                                              "MM/DD HH:mm"
                                                          )
                                                        : ""}{" "}
                                                    -{" "}
                                                    {bookingAtom?.clubActivity
                                                        ?.start_datetime
                                                        ? moment.tz(
                                                              bookingAtom
                                                                  ?.clubActivity
                                                                  ?.stop_datetime,
                                                                  bookingAtom
                                                                  ?.clubActivity
                                                                  ?.timezone
                                                          ).format("HH:mm")
                                                        : ""}
                                                </p>
                                                <h4 className="text-xl font-semibold">
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.title
                                                    }
                                                </h4>
                                                <p className="text-sm flex items-center gap-2 font-medium">
                                                    <img
                                                        src="/images/location.svg"
                                                        alt="location"
                                                    />
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.address
                                                    }{" "}
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity?.city
                                                    }{" "}
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.country
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex justify-between items-center gap-3 flex-wrap w-full font-medium">
                                            <div className="flex gap-2 flex-wrap items-center">
                                                <img
                                                    src="/images/badge.svg"
                                                    alt="badge"
                                                    className={`${"w-6"}`}
                                                />
                                                <p
                                                    className={`${"text-sm"} font-medium`}
                                                >
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.min_level
                                                    }
                                                    -
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.max_level
                                                    }
                                                </p>

                                                <div className="ml-2 flex">
                                                    {bookingAtom?.clubActivity
                                                        ?.players &&
                                                        Array.isArray(
                                                            bookingAtom
                                                                ?.clubActivity
                                                                ?.players
                                                        ) &&
                                                        bookingAtom?.clubActivity?.players?.map(
                                                            (
                                                                player: {
                                                                    profilePic:
                                                                        | string
                                                                        | undefined;
                                                                },
                                                                index:
                                                                    | React.Key
                                                                    | null
                                                                    | undefined
                                                            ) => (
                                                                <BsFillPersonFill
                                                                    key={index}
                                                                    className="w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400"
                                                                />
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                            <div
                                                className={`text-white ${"text-[13px]"} h-[31px] flex gap-[1px] items-center cursor-pointer`}
                                            >
                                                <button
                                                    className={`${"text-[13px] rounded-l-full h-[31px] px-2.5"} bg-secondary font-medium`}
                                                >
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.current_no_of_players
                                                    }
                                                    /
                                                    {
                                                        bookingAtom
                                                            ?.clubActivity
                                                            ?.no_of_players
                                                    }
                                                </button>
                                                <button
                                                    className={`${"text-[13px] rounded-r-full h-[31px] px-2.5"} bg-secondary font-semibold`}
                                                >
                                                    {bookingAtom?.clubActivity
                                                        ?.payment_per_person !==
                                                    0
                                                        ? bookingAtom?.clubActivity?.payment_per_person?.toFixed(
                                                              0
                                                          )
                                                        : 0}{" "}
                                                    {bookingAtom?.clubActivity
                                                        ?.currency
                                                        ? bookingAtom
                                                              ?.clubActivity
                                                              ?.currency
                                                        : "kr"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-full mt-4 mb-3" />
                            <Coupon
                                voucher={voucher}
                                setVoucher={setVoucher}
                                clubId={bookingData?._id}
                            />
                            <div className="text-center">
                                <SubTotalComponent
                                    currentPrice={bookingAtom.price}
                                    bookingData={bookingData}
                                    discountAmount={discountAmount}
                                    voucher={voucher}
                                    membershipDiscount={membershipDiscount[currentUser?.uid]}
                                    membershipDiscountAmount={membershipDiscountAmount}
                                    tournamentData={tournamentDataMain}
                                    clubData={clubData}
                                />

                                {/* <div className="font-semibold text-[#FF3A3A] text-center mt-7">
                                    {errorMessage}
                                </div> */}
                                <button
                                    id="continuePay"
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl mt-7 w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() => {
                                        handleCheckForNameAndPhoneNumber();
                                    }}
                                >
                                    {t("BookingModal.continue")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    }
    if (bookingAtom.page === 15) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-[10000]">
                    <div className="w-full max-w-lg bg-white text-secondary p-8 rounded-lg shadow-lg">
                        <h4 className="text-2xl font-semibold mb-4">
                            Require Level !!
                        </h4>
                        <p className="text-lg mb-6">
                            {bookingAtom.errorMessage}
                        </p>
                        <button
                            className="px-6 py-2 bg-secondary text-white rounded-lg font-semibold hover:bg-secondary-dark transition duration-300"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 31) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className=" xl:min-w-[730px] xl:min-h-[400px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col md:rounded shadow-sm max-h-full items-center gap-8 p-8 justify-center">
                    <h1 id="registerTeamHeader" className="text-black text-2xl font-bold">
                        {t("registerYourTeam")}
                    </h1>
                    <div className="flex flex-wrap justify-center items-center md:gap-8 gap-6">
                        <div className="flex flex-col items-center">
                            <img
                                className="rounded-full size-28 border"
                                src={profilePic || dummyProfileNew}
                                alt=""
                            />
                            <span>{userName}</span>
                        </div>
                        {playerObject?.map((item, index) => (
                            <div
                                key={index.toString()}
                                className="flex flex-col items-center"
                            >
                                <img
                                    className="rounded-full size-28 border"
                                    src={item?.profilePic || dummyProfileNew}
                                    alt=""
                                />
                                <span>{item?.name}</span>
                            </div>
                        ))}

                        {playerObject?.length === 0 && lockPlayer === "" && (
                            <div className="flex flex-col items-center ">
                                <div
                                    id="addPartnerDiv"
                                    onClick={() => {
                                        setBookingAtom({
                                            ...bookingAtom,
                                            page: 32
                                        });
                                    }}
                                    className="size-28 rounded-full border-2 border-secondary border-dashed flex justify-center items-center cursor-pointer"
                                >
                                    <HiPlus size={28} color="#000B29" />
                                </div>
                                <span>{t("addPartner")}</span>
                            </div>
                        )}

                        {lockPlayer !== "" ? (
                            <div className="flex flex-col items-center ">
                                <div className="size-28 rounded-full border-dashed flex justify-center items-center cursor-pointer bg-zinc-300">
                                    <IoIosLock size={80} color="white" />
                                </div>
                                <span>{lockPlayer}</span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <button
                        id="joinTeamAmericanoBtn"
                        disabled={
                            (playerObject?.length === 0 && lockPlayer === "") || isConfirmButtonLoading
                        }
                        onClick={() => {
                            joinTeamAmericano();
                        }}
                        className="bg-secondary text-white rounded-full py-3 w-[250px]"
                    >
                        {" "}
                        {t("confirmTeam")}
                    </button>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 32) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="xl:min-w-[730px]  md:min-h-[400px] w-[300px] sm:w-[55%] bg-white text-secondary z-[10000] flex flex-col md:rounded shadow-sm max-h-full sm:p-10 p-4 justify-center">
                    <div className="mx-auto space-y-8">
                        <div
                            id="clickLockPlayerDiv"
                            onClick={() => {
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 34
                                });
                            }}
                            className="flex items-center gap-4"
                        >
                            <FaArrowRight size={28} />
                            <h1 className="text-black text-2xl cursor-pointer">
                                {t("lockPlayer")}
                            </h1>
                        </div>
                        <div
                            id="clickAddPlayerDiv"
                            className="flex items-center gap-4 cursor-pointer"
                            onClick={() => {
                                setBookingAtom({
                                    ...bookingAtom,
                                    page: 33
                                });
                            }}
                        >
                            <FaArrowRight size={28} />
                            <h1 className="text-black text-2xl cursor-pointer">
                                {t("addPlayerFromApp")}
                            </h1>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 33) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <AddPlayer handleAddPlayer={handleAddPlayer} showHeading={showHeading} dummyProfileNew={dummyProfileNew} />
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else if (bookingAtom.page === 34) {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="xl:min-w-[730px]  md:min-h-[400px] w-[300px] sm:w-[55%] bg-white text-secondary z-[10000] flex flex-col md:rounded shadow-sm max-h-full sm:p-10 p-4 justify-center">
                    <div className="space-y-5">
                        <h1 className="text-black text-2xl font-bold cursor-pointer">
                            {t("enterName")}
                        </h1>
                        <form
                            onSubmit={handleLockPlayer}
                            className="flex flex-col gap-3"
                        >
                            <input
                                onFocus={() => setshowHeading(true)}
                                onBlur={() => setshowHeading(false)}
                                onChange={(e) => setlockPlayer(e.target.value)}
                                className="  outline-none text-lg bg-gray-200 px-4 py-2 rounded-lg"
                                type="text"
                                placeholder={t("Auth.name")}
                            />

                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="px-6 py-2 rounded-lg bg-secondary text-white uppercase"
                                >
                                    {t("lockPlayer")}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    } else {
        return (
            <>
            <ClosableOverlay onClick={closeOverlyOnClickHandlder}>
                <div className="fixed md:min-w-[730px] w-full md:w-[55%] bg-white text-secondary z-[10000] flex flex-col gap-0 md:rounded shadow-sm max-h-full">
                    <div className="flex justify-end p-5">
                        <button
                            className="btn"
                            id="modal-close-btn"
                            onClick={closeOverlyOnClickHandlder}
                        >
                            <AiOutlineClose className="pointer-events-none touch-none w-6 h-6 lg:w-8 lg:h-8 text-[#FF3A3A] hover:scale-110 transform duration-200" />
                        </button>
                    </div>
                    <div className="overflow-y-auto px-5">
                        <div className="flex flex-col mt-10">
                            <div className="flex flex-col items-center justify-center">
                                <img
                                    src="/images/verified.svg"
                                    alt="booked logo"
                                />
                                <h2 id="successText" className="text-[35px] font-bold text-center">
                                    {bookingAtom.isTournamentType === true ||
                                    bookingAtom.isTrainingType === true
                                        ? t("BookingModal.paymentSuccessful")
                                        : t("BookingModal.courtBooked")}
                                </h2>
                                <div className="text-[19px] mt-2 hover:underline">
                                    <a href="/payment-history">
                                        {t("BookingModal.seeReceipts")}
                                    </a>
                                </div>
                            </div>

                            <BookingDetails
                                startTime={bookingAtom.startTime}
                                endTime={bookingAtom.endTime}
                                minutes={bookingAtom.mins}
                                finalPrice={bookingAtom.price}
                                bookingData={bookingData}
                                selectedCourt={bookingAtom.selectedCourt}
                                showBookingDetails={true}
                                accessCode={accessCode}
                                isBooked={true}
                                receiptUrl={receiptUrl}
                                isTournamentType={bookingAtom.isTournamentType}
                                isTrainingType={bookingAtom.isTrainingType}
                                bookingStart={bookingAtom?.clubActivity?.start_datetime}
                                timeZone={bookingAtom?.clubActivity?.timezone}
                                isSlot={isSlot}
                                startDateTime={bookingAtom?.clubActivity?.start_datetime}
                                stopDateTime={bookingAtom?.clubActivity?.stop_datetime}
                            />

                            <div className="text-center my-10">
                                <button
                                    className="px-16 text-[19px] font-semibold py-4 bg-secondary text-white rounded-3xl w-full max-w-[350px] hover:bg-primary hover:text-secondary transform duration-200"
                                    onClick={() =>
                                       {if(tournamentDataMain?.type == 'Tournament'|| tournamentDataMain?.type == "Training"){
                                        setTimeout(()=>{
                                            setBookingAtom({
                                                ...bookingAtom,
                                                page: 13,
                                                show: true
                                            })
                                        },500)
                                        }else{
                                            setBookingAtom({
                                            ...bookingAtom,
                                            show: !bookingAtom.show
                                        })}
                                    }
                                }
                                >
                                    {t("BookingModal.done")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClosableOverlay>
            {displayPopup()}
            </>
        );
    }
};

export default BookingModal;
