import { useState } from "react";
import FindCentre from "../Components/homePagePlayer/FindCentre";
import { Helmet } from "react-helmet-async";
import { InfoPadelMates } from "../Components/homePagePlayer/InfoPadelMates.jsx";
import AppInfo from "../Components/homePagePlayer/AppInfo";
import InitialInfo from "../Components/homePagePlayer/InitialInfo";
import { ComponentHomeClubConnect } from "../Components/club/ComponentHomeClubConnect.jsx";

const HomepagePlayer = () => {
    const [currentUser, setCurrentUser] = useState(null);

    return (
        <>
            <Helmet>
                <title>Padel Mates</title>
                <meta
                    name="description"
                    content="Easily book court time and connect with your community. Padel Mates simplifies scheduling and helps you stay engaged with local events and activities."
                />
                <meta
                    name="keywords"
                    content="Club booking,Sports club,Training sessions,Match booking,Sports training,Club training,Sports matches,Athletic training,Sports events,Training programs"
                ></meta>
                <meta name="robots" content="index, follow" />

                {/* Open Graph tags */}
                <meta
                    property="og:title"
                    content="Book court and connect with the community"
                />
                <meta
                    property="og:description"
                    content="Easily book court time and connect with your community. Padel Mates simplifies scheduling and helps you stay engaged with local events and activities."
                />
                <meta
                    property="og:image"
                    content="https://fa07a7e96db29317b0819d7368104460.serveo.net/static/media/Background.451b4f49158396da2e4a.png"
                />
                <meta property="og:url" content="https://padelmates.se/" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Padel Mates" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:article:author" content="Author Name" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@PadelMates" />
                <meta name="twitter:creator" content="@PadelMates" />
                <meta
                    name="twitter:title"
                    content="Book court and connect with the community"
                />
                <meta
                    name="twitter:description"
                    content="Easily book court time and connect with your community. Padel Mates simplifies scheduling and helps you stay engaged with local events and activities."
                />
                <meta
                    name="twitter:image"
                    content="https://fa07a7e96db29317b0819d7368104460.serveo.net/static/media/Background.451b4f49158396da2e4a.png"
                />
                <meta name="twitter:image:alt" content="Club Logo" />

                {/* Canonical tag */}
                <link rel="canonical" href="https://padelmates.se/" />

                {/* Schema.org Structured Data */}
                <script type="application/ld+json">
                    {`
            {
              "@context": "https://padelmates.se/",
              "@type": "Organization",
              "name": "Padel Mates",
              "url": "https://padelmates.se/",
              "logo": "https://fa07a7e96db29317b0819d7368104460.serveo.net/static/media/Background.451b4f49158396da2e4a.png",
              "sameAs": [
                "https://www.facebook.com/padelmates",
                "https://www.twitter.com/padelmates"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+1-800-555-5555",
                "contactType": "Customer Service"
              }
            }
          `}
                </script>
            </Helmet>

            <div className="overflow-hidden">
                <FindCentre currentUser={currentUser} />

                <InitialInfo />

                {/* <InfoPadelMates /> */}

                <AppInfo />

                <ComponentHomeClubConnect />
            </div>
        </>
    );
};

export default HomepagePlayer;
