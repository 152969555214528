export const levelSystemMapping = {
    TEN: 10,
    SEVEN: 7,
    'FIVE.FIVE': 5.5,
    SIX: 6,
  };

  export const defaultLevelSystem = [
    { sportType: "PADEL", system: "TEN" },
    { sportType: "BADMINTON", system: "SEVEN" },
    { sportType: "SQUASH", system: "FIVE.FIVE" },
    { sportType: "PICKLEBALL", system: "SIX" },
    { sportType: "TENNIS", system: "SEVEN" },
    { sportType: "GOLF", system: "TEN" },
  ];