import { useContext, useState } from "react";
import { BsFillPersonFill, BsPlayFill } from "react-icons/bs";
import { Modal } from "../ui/Modal";
import BookingDetailsModal from "./modals/BookingDetailsModal";
import ConfirmCancelModal from "./modals/ConfirmCancelModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DownloadAppPopup from "../DownloadAppPopup";
import { useBookingAtom } from "../../Utilities/clubStore";
import { getClubProfile } from "../../Api/api";
import { AuthContext } from "../AuthProvider";
import dummyProfileNew from "../../widgets/bookingWidget/bookingModal/images/dummyProfileNew.png";



const BookingCard = ({
    booking,
    setGetBookings,
    showTab,
    clubData,
    setClubData
}: {
    booking: any;
    setGetBookings: any;
    showTab: string;
    clubData: any;
    setClubData: any;
}) => {
    const [openBookingModal, setOpenBookingModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    
    const { t } = useTranslation();
    const currentUser: any = useContext(AuthContext);

    const { start_datetime, stop_datetime, court_info, timezone, date, time, timeEnd } = booking;

    const courtType =
        court_info && court_info.length && court_info[0]?.indoor
            ? "indoor"
            : "outdoor";
    const isDouble =
        court_info && court_info.length && court_info[0]?.double
            ? "double"
            : "single";
    const sports =
        court_info && court_info.length && court_info[0]?.sport
            ? Object.entries(court_info[0]?.sport)
                  .filter(([key, value]) => value)
                  .map(([key]) => key)
            : [];

    const facilities =
        sports && sports.length ? [courtType, isDouble, ...sports] : [];

    const facilitiesString = facilities
        .filter((item) => item !== null)
        .join(" | ");

    const startDate = moment.utc(start_datetime).tz(timezone)?.calendar({
        sameDay: "[Today] HH:mm",
        nextDay: "[Tomorrow] HH:mm",
        nextWeek: "ddd DD MMM, HH:mm",
        sameElse: "ddd DD MMM, HH:mm"
    });

    const durationInHour = moment
        .duration(moment(stop_datetime).diff(moment(start_datetime)))
        .asHours();
    
    let playerCount = 0;

    // Check each player field
    if (booking.player1 && booking.player1 !== "") playerCount++;
    if (booking.player2 && booking.player2 !== "") playerCount++;
    if (booking.player3 && booking.player3 !== "") playerCount++;
    if (booking.player4 && booking.player4 !== "") playerCount++;

    return (
        <>
            <div
                onClick={async () => {
                    if (booking?.activityType === "tournament" || booking?.activityType === "training") {
                        const clubData = await getClubProfile(booking?.clubId, currentUser?.uid);
                        if (clubData?.status === 200) {
                            if (!clubData.data._id && clubData.data.club_id) {
                                clubData.data._id = clubData.data.club_id;
                            }
                            setClubData(clubData.data);
                        };
                        setBookingAtom({
                            ...bookingAtom,
                            show: true,
                            page: 13,
                            clubActivity: {
                                ...booking,
                                activity: booking?.type === "training" ? booking?.type : booking?.torunamentType,
                                tournament_type: booking?.type === "training" ? booking?.training_type : booking?.matchType === "Team Americano" ? "team_americano" : booking?.matchType,
                                min_level: booking?.level?.[0]?.im,
                                max_level: booking?.level?.[booking?.level?.length - 1]?.im,
                                no_of_players: booking?.numberOfPlayers,
                                address: clubData?.data?.address,
                                city: clubData?.data?.city,
                                country: clubData?.data?.country,
                                payment_per_person: booking?.amountPerPlayer,
                                current_no_of_players: booking?.type === "training" ? booking?.players?.length : booking?.current_no_of_players,
                            },
                            clubData: booking?.centerGame ? { name: booking?.clubName, photo_url: booking?.clubProfile, _id: booking?.clubId } : {},
                            price: booking?.amountPerPlayer,
                            isTournamentType: booking?.activityType === "tournament",
                            isTrainingType: booking?.activityType === "training"
                        });
                    } else {
                        setOpenBookingModal(true);
                    }
                }}
                className="w-full py-4 my-2 px-4 border border-secondary rounded-[10px] cursor-pointer"
            >
                <div className="flex gap-6 sm:gap-8 flex-wrap items-center justify-between h-full">
                    <div className="flex flex-wrap gap-8 items-center">
                        <img
                            src={
                                booking?.banner_images_url?.[0]?.bannerImg 
                                ? booking?.banner_images_url[0]?.bannerImg 
                                : booking?.activityType === "tournament" 
                                ? "/images/tournamentCardBackgroundNew.png" 
                                : booking?.activityType === "training" 
                                ? "/images/trainingCardBackground.png" 
                                : booking?.clubProfile ? booking?.clubProfile : `/images/padelMatesImage.jpg`
                            }
                            alt="Activity Banner"
                            className="w-full sm:w-[280px] h-[140px] rounded-[8px] shadow shadow-gray-500 bg-secondary object-cover"
                        />
                        <div className="flex flex-col gap-1 max-w-[300px] break-words">
                            {booking?.title || booking?.created_by ? <div className="flex items-center gap-1">
                                {/* <BsPlayFill className="w-6 h-6" /> */}
                                <p className="capitalize text-sm font-medium">
                                    {booking?.activityType === "Match" ? `Created By: ${booking?.created_by}` : booking?.title? `${booking?.title}` : ``}
                                </p>
                            </div> : null}
                            <p className="text-sm font-medium">
                                {start_datetime && stop_datetime ? `${startDate} - ${durationInHour} h` : `${date}, ${time} - ${timeEnd}`}
                            </p>
                            <h3 className="text-xl sm:text-[21px] font-semibold">
                                {" "}
                                {booking?.clubName}
                            </h3>
                            <h4 className="text-lg sm:text-[21px] font-semibold">
                                {booking?.court_info && booking?.court_info.length
                                    ? booking?.court_info[0]?.name
                                    : "-"}
                            </h4>
                            <p className="text-[15px] text-[#666B78] capitalize">
                                {facilitiesString}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 font-medium xl:items-end h-full xl:justify-between">
                        <div className="flex items-center gap-2">
                            {booking?.activityType === "tournament" || booking?.activityType === "training" ? <img src="/images/trophy.svg" alt="trophy" /> : <BsPlayFill className="w-6 h-6" />}
                            <p className="uppercase text-sm font-medium">
                                {" "}
                                {booking?.activityType || booking?.type}
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 font-medium xl:items-end">
                        {booking?.activityType === "tournament" || booking?.activityType === "training" ?
                            (<div className="flex ml-2">
                                {booking
                                    ?.players &&
                                    Array.isArray(
                                        booking?.players
                                    ) &&
                                    booking?.players?.slice(0, 4)?.map(
                                        (
                                            player: {
                                                profilePic:
                                                    | string
                                                    | undefined;
                                            },
                                            index:
                                                | React.Key
                                                | null
                                                | undefined
                                        ) => (
                                            <img src={player?.profilePic||dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" />                                        )
                                    )}
                            </div>) : (
                            <div className="flex ml-2">
                                {booking?.player1?.profilePic ? <img src={booking?.player1?.profilePic} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : booking?.player1 ?
                                <img src={dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : null}
                                {booking?.player2?.profilePic ? <img src={booking?.player2?.profilePic} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : booking?.player2 ?
                                <img src={dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : null}
                                {booking?.player3?.profilePic ? <img src={booking?.player3?.profilePic} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : booking?.player3 ?
                                <img src={dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : null}
                                {booking?.player4?.profilePic ? <img src={booking?.player4?.profilePic} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : booking?.player4 ?
                                <img src={dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> : null}
                            </div>
                            )}
                            <div className="flex gap-3 items-center flex-wrap">
                                <div className="flex gap-2 items-center flex-wrap">
                                    <img
                                    src="/images/badge.svg"
                                    alt="badge"
                                    className={`${"w-6"}`}
                                    />
                                    <p
                                        className={`${"text-sm"} font-medium`}
                                    >
                                        {booking?.level && booking?.level.length > 0
                                        ? `${booking?.level?.[0]?.im || 1} - ${
                                            booking?.level?.[booking?.level?.length - 1]?.im || 1
                                        }`
                                        : `1-1`}
                                    </p>
                                </div>
                                <div
                                    className={`text-white ${"text-[13px]"} h-[31px] flex gap-[1px] items-center cursor-pointer`}
                                >
                                    {(booking?.activityType === "tournament" || booking?.activityType === "training") && booking?.current_no_of_players ? <button
                                        className={`${"text-[13px] rounded-l-full h-[31px] px-2.5"} bg-secondary font-medium`}
                                    >
                                        {booking?.current_no_of_players}/{booking?.numberOfPlayers}
                                    </button> : (booking?.activityType === "tournament" || booking?.activityType === "training") && !booking?.current_no_of_players ? <button
                                        className={`${"text-[13px] rounded-l-full h-[31px] px-2.5"} bg-secondary font-medium`}
                                    >
                                        {booking?.players?.length}/{booking?.numberOfPlayers}
                                    </button> : !booking?.isSingle ? <button
                                        className={`${"text-[13px] rounded-l-full h-[31px] px-2.5"} bg-secondary font-medium`}
                                    >
                                        {playerCount}/4
                                    </button> : <button
                                        className={`${"text-[13px] rounded-l-full h-[31px] px-2.5"} bg-secondary font-medium`}
                                    >
                                        {playerCount}/2
                                    </button>}
                                    <button
                                        className={`${"text-[13px] rounded-r-full w-[100px] sm:w-auto h-[31px] px-2.5"} bg-secondary font-semibold`}
                                    >
                                        {booking.amountPerPlayer !==
                                        0
                                            ? booking.amountPerPlayer?.toFixed(2) : 0}{" "}
                                        {booking?.currency
                                            ? booking?.currency
                                            : "kr"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {booking?.activityType !== "Book Court" ?
                <DownloadAppPopup
                    isOpenAppDownloadModal={openBookingModal}
                    closeAppDownloadModal={() => setOpenBookingModal(false)}
                    isCancel={true}
                /> :
                <Modal open={openBookingModal} setOpen={setOpenBookingModal}>
                    <BookingDetailsModal
                        setOpenBookingModal={setOpenBookingModal}
                        setOpenCancelModal={setOpenCancelModal}
                        booking={booking}
                        setOpenConfirmCancel={setOpenConfirmCancel}
                        setGetBookings={setGetBookings}
                        showTab={showTab}
                    />
                </Modal>
            }
            {/* <Modal open={openCancelModal} setOpen={setOpenCancelModal}>
                <CancelModal
                    setOpenCancelModal={setOpenCancelModal}
                    setOpenConfirmCancel={setOpenConfirmCancel}
                />
            </Modal> */}
            <Modal open={openConfirmCancel} setOpen={setOpenConfirmCancel}>
                <ConfirmCancelModal
                    setOpenConfirmCancel={setOpenConfirmCancel}
                />
            </Modal>
        </>
    );
};

export default BookingCard;
