import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { FaArrowRight, FaLocationDot } from "react-icons/fa6"

const Career = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>{t("career")} | Padel Mates</title>
            </Helmet>
            <div className="max-w-6xl mx-auto px-6 py-12">
                <header className="text-center mb-10">
                    <h1 className="text-4xl font-bold mb-4 text-primary">
                        Join Our Team
                    </h1>
                    <p className="text-lg text-secondary">
                        Shape the future of sports matchmaking and club
                        management.
                    </p>
                </header>
                <div>
                <section onClick={()=> navigate(`/career/1`)} className="text-secondary rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary cursor-pointer">
                    <div>
                    <h2 className="text-2xl font-bold mb-4">Lead Solution Architect</h2>
                    <p className="flex gap-2 items-center mb-4 text-secondary/70"><FaLocationDot />Stockholm, Sweden</p>
                    <p className="mb-6">
                        As a Lead Solution Architect, you will play a vital role
                        in shaping the future of our products and systems.
                        Reporting directly to the CTO, you’ll lead the design of
                        scalable solutions, actively contribute to the codebase,
                        and coordinate efforts across multiple teams to ensure
                        the timely delivery of high-quality, production-ready
                        systems.
                    </p>
                    </div>
                    <button onClick={()=> navigate(`/career/1`)} className="bg-secondary text-white px-4 py-2 rounded-lg hover:bg-primary hover:text-secondary flex gap-2 items-center">See more <FaArrowRight /></button>
                </section>
                </div>
            </div>
        </>
    );
};

export default Career;
