import React, { useRef, useState } from "react";
import ReactCrop, {
    centerCrop,
    Crop,
    makeAspectCrop,
    PixelCrop
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import { Modal } from "../ui/Modal";

interface UploadImageProps {
    handleUpload?: any;
    uploadError?: any;
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

const UploadImage: React.FC<UploadImageProps> = ({
    handleUpload,
    uploadError
}) => {
    const aspect = 1;
    const { t } = useTranslation();
    const [imgSrcs, setImgSrcs] = useState<string[]>([]);
    const [currentImgIndex, setCurrentImgIndex] = useState(0);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crops, setCrops] = useState<Crop[]>([]);
    const [completedCrops, setCompletedCrops] = useState<PixelCrop[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadModal, setUploadModal] = useState<boolean>(false);

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setUploadModal(true);
            const newImgSrcs: string[] = [];
            Array.from(e.target.files).forEach((file) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    newImgSrcs.push(reader.result?.toString() || "");
                    if (newImgSrcs.length === e.target.files!.length) {
                        setImgSrcs(newImgSrcs);
                        setCrops(new Array(newImgSrcs.length).fill(undefined));
                        setCompletedCrops(
                            new Array(newImgSrcs.length).fill(undefined)
                        );
                    }
                });
                reader.readAsDataURL(file);
            });
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            const newCrop = centerAspectCrop(width, height, aspect);
            const newCrops = [...crops];
            newCrops[currentImgIndex] = newCrop;
            setCrops(newCrops);
        }
    }

    async function onDoneCropClick() {
        const image = imgRef.current;
        const completedCrop = completedCrops[currentImgIndex];
        if (!image || !completedCrop) {
            throw new Error("Crop canvas does not exist");
        }

        setIsLoading(true);
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const offscreen = new OffscreenCanvas(
            completedCrops[currentImgIndex].width * scaleX,
            completedCrops[currentImgIndex].height * scaleY
        );
        const ctx = offscreen.getContext("2d");
        if (!ctx) {
            throw new Error("No 2d context");
        }

        ctx.drawImage(
            image,
            completedCrops[currentImgIndex].x * scaleX,
            completedCrops[currentImgIndex].y * scaleY,
            completedCrops[currentImgIndex].width * scaleX,
            completedCrops[currentImgIndex].height * scaleY,
            0,
            0,
            offscreen.width,
            offscreen.height
        );

        const blob = await offscreen.convertToBlob({
            type: "image/png"
        });

        try {
            const res: any = await handleUpload(blob);
            if (res?.success) {
                handleClose();
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error: ", error);
            setIsLoading(false);
        }
    }

    const handleClose = () => {
        setUploadModal(false);
        setCurrentImgIndex(0);
        setImgSrcs([]);
        setCompletedCrops([]);
    };

    return (
        <>
            <div className="relative py-2">
                <div className="flex gap-2 sm:gap-5 flex-wrap sm:flex-nowrap items-center">
                    <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                        {t("AccountSettings.profilePic")}
                    </label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={onSelectFile}
                        className="min-w-[200px] w-full bg-white border-[1px] border-[#000b2999] rounded-[50px] text-secondary font-normal p-4 outline-primary"
                    />
                </div>
                {uploadError ? (
                    <p className="text-red-500 mt-1 ml-2 text-xs text-left">
                        {uploadError}
                    </p>
                ) : null}
            </div>
            <div className="flex pt-1 lg:justify-center">
                <Modal open={uploadModal} setOpen={handleClose}>
                    <div className="bg-white p-5 lg:py-8 lg:px-8 font-semibold rounded-2xl w-full mx-auto">
                        {Boolean(imgSrcs[currentImgIndex]) && (
                            <>
                                <div className="pb-5 font-medium text-lg capitalize">
                                    {t("AccountSettings.profilePic")}
                                </div>
                                <ReactCrop
                                    crop={crops[currentImgIndex]}
                                    onChange={(_: any, percentCrop: any) => {
                                        const newCrops = [...crops];
                                        newCrops[currentImgIndex] = percentCrop;
                                        setCrops(newCrops);
                                    }}
                                    onComplete={(c: any) => {
                                        const newCompletedCrops = [
                                            ...completedCrops
                                        ];
                                        newCompletedCrops[currentImgIndex] = c;
                                        setCompletedCrops(newCompletedCrops);
                                    }}
                                    aspect={aspect}
                                    className="max-h-[calc(100vh-200px)]"
                                >
                                    <img
                                        ref={imgRef}
                                        alt="Crop me"
                                        src={imgSrcs[currentImgIndex]}
                                        style={{
                                            transform: `scale(${1}) rotate(${0}deg)`
                                        }}
                                        onLoad={onImageLoad}
                                    />
                                </ReactCrop>
                            </>
                        )}
                        {Boolean(completedCrops[currentImgIndex]) && (
                            <>
                                <div className="mt-2 flex justify-end gap-3">
                                    <button
                                        onClick={handleClose}
                                        disabled={isLoading}
                                        className="border-secondary bg-transparent text-secondary hover:bg-secondary hover:text-white min-h-[40px] max-w-[350px] 2xl:max-w-[400px] transform cursor-pointer rounded-full px-6 py-1 flex items-center justify-center gap-2 border font-medium duration-300"
                                    >
                                        <span>{t("close")}</span>
                                    </button>
                                    <button
                                        id="shareActivityButton"
                                        onClick={onDoneCropClick}
                                        disabled={isLoading}
                                        className="border-secondary bg-secondary text-white hover:border-custom-gray-400 hover:bg-custom-gray-400 min-h-[40px] max-w-[350px] 2xl:max-w-[400px] transform cursor-pointer rounded-full px-6 py-1 flex items-center justify-center gap-2 border font-medium duration-300"
                                    >
                                        <span>
                                            {isLoading
                                                ? t("Profile.loading")
                                                : t("AccountSettings.upload")}
                                        </span>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default UploadImage;
