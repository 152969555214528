import React from "react";
import { IUserNotification } from "./Navbar";
import { IoNotificationsOffOutline } from "react-icons/io5";
import moment from "moment";
import { getFirstLettersFromWords } from "../../../Utilities/utils";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";

export const Notifications: React.FC<{
    userNotifications: IUserNotification[];
    isLoading?: boolean;
    onNotificationItemClick?: (notification: IUserNotification) => void;
    onSeeAllNotificationsInInbox: () => void;
}> = ({
    userNotifications = [],
    isLoading = false,
    onNotificationItemClick,
    onSeeAllNotificationsInInbox
}) => {
    const handleNotificationItemClick = (notification: IUserNotification) => {
        return () => {
            onNotificationItemClick && onNotificationItemClick(notification);
        };
    };

    const { t } = useTranslation();

    return (
        <div className="text-black bg-white rounded border shadow p-4 relative">
            {isLoading ? (
                <div
                    className={
                        "absolute top-0 left-0 h-full w-full flex items-center justify-center bg-white bg-opacity-95 "
                    }
                >
                    <CgSpinner className="animate-spin text-[64px]" />
                </div>
            ) : null}
            <h4 className="mb-3 text-secondary text-[17px] font-medium leading-tight">
                {t("MenuUser.notifications")} ({userNotifications.length})
            </h4>
            <hr className="w-full bg-gray-600 h-[1px]" />

            {userNotifications.length ? (
                <ul className=" max-h-[280px] sm:max-h-[379px] overflow-auto [&>*:last-child]:pb-0 [&>*:last-child>.ComponentMenuNotifications-desc]:border-none">
                    {userNotifications.map((userNotification) => {
                        const { _id, title, imageUrl, body, createdAt } =
                            userNotification;
                        return (
                            <li
                                className="cursor-pointer flex items-center justify-between gap-4 text-black py-2"
                                key={_id}
                                onClick={handleNotificationItemClick(
                                    userNotification
                                )}
                            >
                                <div className="w-[53px] h-[53px] overflow-hidden rounded-full bg-slate-950 flex justify-center items-center text-white text-xl">
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="user"
                                            className="w-full h-full"
                                        />
                                    ) : (
                                        getFirstLettersFromWords(title)
                                    )}
                                </div>
                                <div className="ComponentMenuNotifications-desc flex-1 border-b pb-2 leading-tight text-black ">
                                    <div className="text-[16px] font-medium mb-1">
                                        <b>{title}</b>
                                    </div>
                                    <div className="text-[16px] font-light text-[#535353] leading-tight mb-1">
                                        {body}
                                    </div>
                                    <div className="text-[16px] font-light text-[#5b5b5b]">
                                        {moment(createdAt).format("ddd DD MMM HH:mm")}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <div className="flex items-center justify-center min-h-[280px] sm:min-h-[379px] text-center">
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-[70px] h-[70px] bg-slate-950 bg-opacity-10 rounded-full flex items-center justify-center mb-3">
                            <IoNotificationsOffOutline size={34} />
                        </div>

                        <div className="text-center text-slate-950 text-opacity-80 text-[22px] font-normal leading-tight">
                            {t("MenuUser.noNotifications")}
                        </div>
                    </div>
                </div>
            )}

            {/* <hr className="w-full bg-gray-600 h-[1px]" />

      <div className="text-center text-emerald-400 text-[15px] font-semibold leading-tight mt-4">
        <button className="text-primary" onClick={onSeeAllNotificationsInInbox}>
          See all in inbox
        </button>
      </div> */}
        </div>
    );
};
