// @ts-nocheck

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../AuthProvider";
import OnboardingSports from "./OnboardingSports";
import OnboardingAge from "./OnboardingAge";
import OnboardingGender from "./OnboardingGender";
import { Modal } from "../ui/Modal";
import { userOnboard } from "../../Api/api";
import PopupInfo from "../booking/PopupInfo";
import { levels } from "../../Utilities/levels";
import DownloadAppPopup from "../DownloadAppPopup.tsx";

const sportsRatings = [
    { name: "Padel", level: 1 },
    { name: "Tennis", level: 1 },
    { name: "Badminton", level: 1 },
    { name: "Squash", level: 1 },
    { name: "Pickleball", level: 1 }
];

const OnboardingForm = () => {
    const navigate = useNavigate();
    const currentUser = useContext(AuthContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedSports, setSelectedSports] = useState<string[]>([]);
    const [selectedAge, setSelectedAge] = useState<string>("");
    const [selectedGender, setSelectedGender] = useState<string>("");
    const [enableRatingModal, setEnableRatingModal] = useState<boolean>(false);
    const [selectedRatings, setSelectedRatings] = useState<any>(sportsRatings);
    const [currentSport, setCurrentSport] = useState<string>("");
    const [currentRating, setCurrentRating] = useState<string>("");
    const [onboarding, setOnboarding] = useState<boolean>(false);
    const [ratings, setRatings] = useState<any>(null);

    const [isShowDownloadModal, showDownloadModal] = useState<boolean>(false);

    const [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams);

    useEffect(() => {
        if (currentSport) {
            console.log(
                "loading => levels",
                levels[currentSport.toLocaleLowerCase()]
            );
            const sportsLevels = Object.keys(
                levels[currentSport.toLocaleLowerCase()]
            );
            const sortedLevels = sportsLevels.sort(
                (a, b) => parseFloat(a) - parseFloat(b)
            );
            setRatings(sortedLevels);
            console.log("loading => levels", sortedLevels);
        }
    }, [currentSport]);

    const handleOnboardUser = () => {
        setOnboarding(true);
        const filteredSports = selectedRatings.filter((sport: any) =>
            selectedSports.includes(sport.name)
        );

        console.log("loading => onboarding done", filteredSports);

        const data = {
            ages: selectedAge,
            gender: selectedGender,
            gameOptions: filteredSports,
            userLocation: {
                lat: 78.9012,
                long: 12.3456
            }
        };

        userOnboard(data).then((onboardResp: any) => {
            console.log("loading => onboardResp", onboardResp);
            if (onboardResp?.success) {
                setOnboarding(false);
                console.log("searchParams: " + searchParams);
                if (queryParams?.show_download_modal == "true") {
                    showDownloadModal(true);
                } else {
                    navigate("/profile");
                }
            } else {
                setOnboarding(false);
            }
        });
    };

    const handleSportsSelect = (newRating: number) => {
        setSelectedRatings((prevRatings: any) => {
            const updatedRatings = prevRatings.map((sport: any) => {
                if (sport.name === currentSport) {
                    return { ...sport, level: newRating, active: true };
                }
                return sport;
            });
            return updatedRatings;
        });
    };

    // const steps = [1, 2, 3, 4, 5];

    return (
        <>
            <DownloadAppPopup
                isOpenAppDownloadModal={isShowDownloadModal}
                closeAppDownloadModal={() => {
                    showDownloadModal(false);
                    navigate("/downloadtheapp");
                }}
            />
            <PopupInfo
                title={"Onboarding"}
                subTitle={"Player is being onboarded"}
                isOpen={onboarding}
                setIsOpen={setOnboarding}
                isLoading={true}
            />
            <div className="w-full">
                {/* <div className="w-11/12 lg:w-2/6 mx-auto">
                <div className="bg-gray-200 h-1 flex items-center justify-between">
                    <div className="w-1/3 bg-secondary h-1 flex items-center">
                        <div className="bg-secondary h-6 w-6 rounded-full shadow flex items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-check"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#FFFFFF"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M5 12l5 5l10 -10" />
                            </svg>
                        </div>
                    </div>
                    <div className="w-1/3 flex justify-between bg-secondary h-1 items-center relative">
                        <div className="absolute right-0 -mr-2">
                            <div className="relative bg-white shadow-lg px-2 py-1 rounded mt-16 -mr-12">
                                <svg
                                    className="absolute top-0 -mt-1 w-full right-0 left-0"
                                    width="16px"
                                    height="8px"
                                    viewBox="0 0 16 8"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="Page-1"
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                    >
                                        <g
                                            id="Progress-Bars"
                                            transform="translate(-322.000000, -198.000000)"
                                            fill="#FFFFFF"
                                        >
                                            <g
                                                id="Group-4"
                                                transform="translate(310.000000, 198.000000)"
                                            >
                                                <polygon
                                                    id="Triangle"
                                                    points="20 0 28 8 12 8"
                                                />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <p className="text-secondary text-xs font-bold">
                                    Step 3: Analyzing
                                </p>
                            </div>
                        </div>
                        <div className="bg-secondary h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-check"
                                width={18}
                                height={18}
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#FFFFFF"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M5 12l5 5l10 -10" />
                            </svg>
                        </div>
                        <div className="bg-white h-6 w-6 rounded-full shadow flex items-center justify-center -mr-3 relative">
                            <div className="h-3 w-3 bg-secondary rounded-full" />
                        </div>
                    </div>
                    <div className="w-1/3 flex justify-end">
                        <div className="bg-white h-6 w-6 rounded-full shadow" />
                    </div>
                </div>
            </div> */}
                <div className="w-full my-10 text-center flex justify-center items-center">
                    {/* <div className="flex items-center justify-center space-x-4">
                {steps.map((step, index) => (
                    <div
                        key={step}
                        className={`w-6 h-6 flex items-center justify-center rounded-full ${
                            index < currentStep ? "bg-blue-500" : "bg-gray-300"
                        }`}
                    >
                        {index < currentStep - 1 && (
                            <div className="w-4 h-1 bg-blue-500 transform -translate-y-1"></div>
                        )}
                    </div>
                ))}
            </div> */}
                    {currentStep === 1 ? (
                        <OnboardingSports
                            setCurrentStep={setCurrentStep}
                            currentStep={currentStep}
                            selectedSports={selectedSports}
                            setSelectedSports={setSelectedSports}
                            setEnableRatingModal={setEnableRatingModal}
                            enableRatingModal={enableRatingModal}
                            setCurrentSport={setCurrentSport}
                        />
                    ) : currentStep === 2 ? (
                        <OnboardingAge
                            setCurrentStep={setCurrentStep}
                            selectedAge={selectedAge}
                            setSelectedAge={setSelectedAge}
                        />
                    ) : currentStep === 3 ? (
                        <OnboardingGender
                            setCurrentStep={setCurrentStep}
                            selectedGender={selectedGender}
                            setSelectedGender={setSelectedGender}
                            handleOnboardUser={handleOnboardUser}
                        />
                    ) : (
                        <div className="text-center py-10 w-[90%] md:w-[40%] bg-[#FBFBFB] rounded-3xl shadow">
                            <h2 className="text-3xl font-semibold text-center mb-4 capitalize">
                                Hey, {currentUser?.displayName?.split(" ")[0]}
                            </h2>
                            <p className="md:text-lg">
                                Welcome to Padel Mates! <br /> In order to get
                                the best possible experience, <br /> you need to
                                answer a few questions
                            </p>
                            <div className="mt-20">
                                <button
                                    className="font-semibold outline-none rounded-full bg-secondary text-white hover:bg-white hover:text-secondary hover:border hover:border-secondary w-auto mx-auto px-20 py-3"
                                    onClick={() => {
                                        //navigate("/onboarding?" + queryParams?.show_download_modal == "true" ? "show_download_modal=true" : "");
                                        setCurrentStep(1);
                                    }}
                                >
                                    Let's Begin
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                <Modal open={enableRatingModal} setOpen={setEnableRatingModal}>
                    <div className="bg-white p-5 lg:py-8 lg:px-16 font-semibold rounded-2xl w-full mx-auto">
                        {/* <div className="flex justify-center items-center">
                        <img src="/images/verified.svg" alt="verified icon" />
                    </div> */}
                        <h3 className="text-center text-2xl mb-10 capitalize">
                            what level are you in {currentSport}?
                        </h3>
                        <ul className="grid w-full gap-6 grid-cols-6">
                            {ratings?.map((rating: string) => (
                                <div className="flex flex-col" key={rating}>
                                    <li
                                        onClick={() => {
                                            setCurrentRating(rating);
                                            handleSportsSelect(
                                                parseFloat(rating)
                                            );
                                        }}
                                    >
                                        <label
                                            className={
                                                currentRating === rating
                                                    ? "flex justify-center items-center bg-primary text-secondary rounded-full h-[50px] w-[50px] cursor-pointer"
                                                    : "flex justify-center items-center border border-secondary rounded-full h-[50px] w-[50px] cursor-pointer"
                                            }
                                        >
                                            <div className="w-full text-lg text center flex justify-center items-center">
                                                {rating}
                                            </div>
                                        </label>
                                    </li>
                                </div>
                            ))}
                        </ul>
                        <div className="flex justify-center mt-10">
                            <button
                                onClick={() => {
                                    setEnableRatingModal(!enableRatingModal);
                                }}
                                className="bg-secondary text-white hover:text-secondary hover:bg-white hover:border hover:border-secondary rounded-full px-10 h-[49px] text-[19px]"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                    i
                </Modal>
            </div>
        </>
    );
};

export default OnboardingForm;
