import React, { useEffect, useState } from "react";
import {
    GoogleMap,
    InfoWindow,
    Marker,
    useJsApiLoader
} from "@react-google-maps/api";
import { ICentre } from "./interface";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const googleMapsApiKey = process.env.REACT_APP_GOOGLEAPI_MAP_API_KEY as string;

const Libraries: "places"[] = ["places"];

const markerIconPath = "/images/map-marker.svg";

interface IMapsProps {
    markers: ICentre[];
}

const ComponentMaps: React.FC<IMapsProps> = ({ markers = [] }) => {
    const { t } = useTranslation();

    const [gMap, setGMap] = useState<any>();
    const [centerPosition, setCenterPosition] = useState({
        lat: 0,
        lng: 0
    });
    const [zoom, setZoom] = useState(4);
    const maxLat = (Math.atan(Math.sinh(Math.PI)) * 180) / Math.PI;
    const [isMapScriptLoaded, setIsMapScriptLoaded] = useState(false);
    const [selectedMarker, setSelectedMarker] = React.useState<ICentre | null>(
        null
    );

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleMapsApiKey,
        libraries: Libraries
    });

    const onMarkerClick = (marker: ICentre) => {
        setSelectedMarker(marker);
    };
    useEffect(() => {
        if (isLoaded) {
            setIsMapScriptLoaded(isLoaded);
        }
    }, [isLoaded]);

    useEffect(() => {
        setSelectedMarker(null);
        if (gMap && isMapScriptLoaded) {
            const bounds = new google.maps.LatLngBounds();
            
            for (let i = 0; i < markers.length; i++) {
                const marker = markers[i];
    
                if (marker.loc && marker.loc.coordinates) {
                    const [lng, lat] = marker.loc.coordinates;
                    bounds.extend({ lat, lng });
                }
            }
    
            if (markers.length) {
                setCenterPosition(bounds.getCenter().toJSON());
                gMap?.fitBounds(bounds);
            } else {
                setCenterPosition({ lat: 0, lng: 0 });
                setZoom(4);
            }
        }
    }, [markers, gMap, isMapScriptLoaded]);
    

    useEffect(() => {}, [markers]);

    if (!isMapScriptLoaded) {
        return null;
    }
    return (
        <div className="w-full h-[400px] min-h-[400px] md:h-[30vh] relative">
            <GoogleMap
                center={centerPosition}
                zoom={zoom}
                options={{
                    maxZoom: 12,
                    restriction: {
                        strictBounds: true,
                        latLngBounds: {
                            north: maxLat, // max lat
                            south: -maxLat,
                            west: -180,
                            east: 180
                        }
                    }
                }}
                mapContainerClassName="w-full h-full"
                onLoad={(map) => {
                    setGMap(map);
                }}
            >
                {markers.map((marker) => {
                    const { _id, loc } = marker;

                    if (!loc || !loc.coordinates) {
                        return null; 
                    }
                
                    const [lng, lat] = loc.coordinates;
                    const latLng = { lat, lng };

                    return (
                        <Marker
                            key={_id}
                            position={latLng}
                            icon={markerIconPath}
                            onClick={() => onMarkerClick(marker)}
                        />
                    );
                })}
                {selectedMarker
                    ? (() => {
                          const { name, address, _id } = selectedMarker;
                          return (
                              <InfoWindow
                                  position={{
                                      lat: selectedMarker.loc.coordinates[1],
                                      lng: selectedMarker.loc.coordinates[0]
                                  }}
                                  onCloseClick={() => {
                                      setSelectedMarker(null);
                                  }}
                                  options={{
                                      pixelOffset: new google.maps.Size(0, -25)
                                  }}
                              >
                                  <div className="inline-flex gap-2 max-w-[250px]">
                                      {/* <div className="w-20">
                      <img
                        src={photo_url || generateGameType(gameType) || name}
                        alt={name}
                        className="w-full h-auto"
                      />
                    </div> */}
                                      <div className="">
                                          <h2 className="font-bold text-lg leading-snug mb-1">
                                              {name}
                                          </h2>
                                          <p>{address}</p>
                                          <Link
                                              to={`/club/${_id}`}
                                              className="mt-1 font-medium inline-block text-[13px] transition-all duration-150 text-slate-800 hover:text-slate-700"
                                          >
                                              {t("view_club")}
                                          </Link>
                                      </div>
                                  </div>
                              </InfoWindow>
                          );
                      })()
                    : null}
            </GoogleMap>
        </div>
    );
};

export default ComponentMaps;
