import React, { useState } from "react";
import { BiSolidChevronLeft, BiSolidChevronRight } from "react-icons/bi";

const ClubBannerSlider = ({ images, style }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images?.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === images?.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    return (
        <div className="w-full h-full object-cover max-h-[243px] hidden md:block relative">
            <div
                className="flex transition-transform duration-500"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images?.map((image: any, index: any) => (
                    <div className="min-w-full" key={index}>
                        <img
                            src={image?.bannerImgCropped}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-[100] object-cover max-h-[243px]"
                        />
                    </div>
                ))}
            </div>

            { images?.length >1 ?
            <>
            <button
                onClick={goToPrevious}
                className={`absolute size-8 top-1/2 left-3 flex justify-center items-center ${
                    currentIndex === 0 ? "bg-gray-500" : "bg-secondary"
                } text-white p-2 rounded-full  focus:outline-none`}
            >
                <BiSolidChevronLeft  />
            </button>

            <button
                onClick={goToNext}
                className={`absolute size-8 top-1/2 right-3 flex justify-center items-center ${
                    currentIndex === images?.length-1
                        ? "bg-gray-500"
                        : "bg-secondary"
                } text-white p-2 rounded-full focus:outline-none`}
            >
                <BiSolidChevronRight />
            </button>
            </>:null}
        </div>
    );
};

export default ClubBannerSlider;
