import React, { useContext, useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaArrowLeft } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { getFollowingsWithLevel, searchUserForActivity } from "../../Api/api";
import { useBookingAtom } from "../../Utilities/clubStore";
import { AuthContext } from "../AuthProvider";
import { useTranslation } from "react-i18next";

interface AddPlayerProps {
    handleAddPlayer: any;
    showHeading: boolean;
    dummyProfileNew: any;
}
function AddPlayer({ handleAddPlayer, showHeading, dummyProfileNew }: AddPlayerProps) {
    const { t } = useTranslation();
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    const [searchInput, setSearchInput] = useState("");
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
    const [dataCopy, setDataCopy] = React.useState([]);
    const [page, setPage] = useState(0);
    const limit = 20;
    const [loading, setLoading] = useState(false);
    const [noMoreData, setNoMoreData] = useState(false);
    const currentUser: any = useContext(AuthContext);
    const [searchResults, setSearchResults] = useState<
        Array<{
            name: string;
            level: any;
            profilePic: string;
            sportType: string;
            centerName: string;
            id: string;
        }>
    >([]);

    useEffect(() => {
        getFollowingsWithLevel("padel").then((resa) => {
            let sortedfollowing = resa.sort(function (a: any, b?: any) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            setSearchResults(sortedfollowing);
            setDataCopy(sortedfollowing);
        });
    }, [currentUser]);

    const handleSearch = async (searchInput: string) => {
        try {
            setLoading(true);
            setSearchResults([]);
            const response: any = await searchUserForActivity({
                name: searchInput,
                centerName: "",
                level: "",
                sportType: "padel",
                skip: 0,
                limit
            });
            const filterPlayers = response?.filter((item: any) => item?.email !== currentUser?.email);
            if (filterPlayers && filterPlayers.length) {
                setSearchResults(filterPlayers);
                setPage(limit);
                setNoMoreData(false);
            } else {
                if (page == 0) {
                    var updatedData: any = []
                    setSearchResults(updatedData);
                }
                setNoMoreData(true);
            }
            setLoading(false);
        } catch (error: any) {
            console.error(`Error in user filter: ${error}`);
        }
    };

    // Handle input change with custom debounce logic
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchInput(value);
        if (value === "") {
            setSearchResults(dataCopy);
        } 

        // Clear any existing debounce timeout
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        // Set a new timeout for 2 seconds
        debounceTimeout.current = setTimeout(() => {
            if (value) {
                handleSearch(value); // Call the search function after the delay
            }
        }, 2000); // 2-second delay
    };

    const loadMoreData = async () => {
        try {
            setLoading(true);
            if (loading || searchInput == "" || noMoreData) return;
            const response: any = await searchUserForActivity({
                name: searchInput,
                centerName: "",
                level: "",
                sportType: "padel",
                skip: page,
                limit
            });
            const filterPlayers = response?.filter((item: any) => item?.email !== currentUser?.email);
            if (filterPlayers && filterPlayers.length) {
                var newData = filterPlayers;

                // Get the current data state and append new data
                var updatedData;
                if (page != 0) {
                    updatedData = [...searchResults, ...newData];
                } else {
                    updatedData = [...newData];
                }

                // Set the updated data
                setSearchResults(updatedData);
                setPage(page + limit);
                setNoMoreData(false);
            } else {
                if (page == 0) {
                    var emptyData: any = []
                    setSearchResults(emptyData);
                }
                setNoMoreData(true);
            }
        } catch (error) {
            console.log("error: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className=" xl:min-w-[730px] h-[600px] md:min-h-[400px] w-[300px] sm:w-[55%] bg-white text-secondary z-[10000] flex flex-col md:rounded shadow-sm max-h-full sm:p-10 p-4 ">
            <div className="space-y-5">
                <FaArrowLeft
                    className="cursor-pointer"
                    size={20}
                    onClick={() => {
                        setBookingAtom({
                            ...bookingAtom,
                            page: 32
                        });
                    }}
                />
                <h1 className="text-black text-2xl font-bold cursor-pointer">
                    {t("addPlayer")}:
                </h1>
                <div>
                    <form className="border flex bg-gray-200 px-4 py-2 rounded-lg items-center space-x-3">
                        <IoSearch size={25} color="gray" />

                        <input
                            id="searchPlayer"
                            value={searchInput}
                            // onFocus={() => setShowHeading(true)}
                            onChange={handleInputChange}
                            // onBlur={() => setshowHeading(false)}
                            className="flex-1 bg-inherit outline-none text-lg"
                            type="text"
                            placeholder="Search"
                        />
                    </form>
                    {/* <div className=" md:space-x-2 space-y-1 py-1">
                                <div className="inline-flex justify-between bg-gray-200 items-center  px-2 py-1 rounded-lg gap-3">
                                    <span className="text-gray-400 text-xs">
                                        Search by location
                                    </span>
                                    <IoClose color="gray" size={20} />
                                </div>
                                <div className="inline-flex justify-between bg-gray-200 items-center  px-2 py-1 rounded-lg gap-3">
                                    <span className="text-gray-400 text-xs">
                                        Search by location
                                    </span>
                                    <IoClose color="gray" size={20} />
                                </div>
                            </div> */}
                </div>
                <div className="">
                    {showHeading && (
                        <h3 className="text-center">
                            {t("followYourMates")}
                        </h3>
                    )}
                    <h2 className="text-lg font-bold text-center">
                        {" "}
                        {t("addOneOfYourPlayer")}
                    </h2>
                </div>
            </div>
            <div className={`h-auto ${loading && !searchResults?.length ? "" : "overflow-y-auto"} p-2`}>
                {searchResults && searchResults.length > 0
                    ? searchResults.map((item, index) => (
                          <div
                              key={index.toString()}
                              className="flex items-center justify-start space-x-3 mt-10 border-b border-slate-200 pb-1"
                          >
                              <div className="relative">
                                  <img
                                      className="size-14 rounded-full border "
                                      src={item?.profilePic || dummyProfileNew}
                                      alt=""
                                  />
                                  <div
                                      style={{
                                          backgroundImage: `url(/images/badge.svg)`
                                      }}
                                      className="absolute top-0 right-0 z-20 text-[8px] rounded-full text-white bg-cover  object-center size-5 flex justify-center items-center"
                                  >
                                      {item.level?.im}
                                  </div>
                              </div>
                              <p className="w-[200px]">{item.name} </p>
                              <button
                                  id="addPlayerBtn"
                                  onClick={() => handleAddPlayer(item)}
                                  className="bg-secondary px-5 py-1 text-white rounded-md text-sm uppercase"
                              >
                                  {t("add")}
                              </button>
                          </div>
                      ))
                    : !loading ? <div className="flex justify-center items-center">{t("noPlayerFound")}</div> : ""}
                {searchInput && !noMoreData && <div className="flex justify-center items-center">
                    {loading ? (
                        <CgSpinner className="animate-spin text-[64px]" />
                    ) : searchResults?.length ? (
                        <button
                            id="addPlayerBtn"
                            onClick={loadMoreData}
                            className="bg-secondary px-5 py-1 text-white rounded-md mt-4"
                        >
                            {t("loadMore")}
                        </button>
                    ) : ""}
                </div>}
            </div>
        </div>
    );
}

export default AddPlayer;
