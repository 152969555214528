// @ts-nocheck

import React from "react";
import { t } from "i18next";

import {
    FaFacebook,
    FaTwitter,
    FaSnapchat,
    FaWhatsapp,
    FaInstagram
} from "react-icons/fa";

const ShareWithFriends = () => {
    return (
        <div className="flex flex-col items-center justify-center mt-10 gap-4">
            <h5 className="text-[17px] uppercase">
                {t("ShareWithFriends.share")}
            </h5>
            <div className="flex items-center flex-wrap gap-2">
                <button>
                    <FaFacebook className="w-11 h-11" />
                </button>
                <button>
                    <FaInstagram className="w-11 h-11" />
                </button>
                <button>
                    <FaSnapchat className="w-11 h-11" />
                </button>
                <button>
                    <FaTwitter className="w-11 h-11" />
                </button>
                <button>
                    <FaWhatsapp className="w-11 h-11" />
                </button>
            </div>
        </div>
    );
};

export default ShareWithFriends;
