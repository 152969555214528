import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { getClubActivities, getSearchNearbyClubs } from "../../Api/api";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoCalendar } from "react-icons/io5";
import { Combobox } from "@headlessui/react";

const DiscoverActivities = ({
    clubActivities,
    allClubActivities,
    setAllClubActivities,
    setClubActivities,
    setLoading,
    setShowActivities,
    date,
    setDate,
    selectedClub
}: {
    clubActivities: any;
    allClubActivities: any;
    setAllClubActivities: Dispatch<SetStateAction<any>>;
    setClubActivities: Dispatch<SetStateAction<any>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setShowActivities: Dispatch<SetStateAction<boolean>>;
    date: any;
    setDate: Dispatch<SetStateAction<any>>;
    selectedClub: any;
}) => {
    const { t } = useTranslation();
    const [clubs, setClubs] = useState([]);
    const [displayClub, setDisplayClub] = useState("");
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [level, setLevel] = useState<any>(null);
    const [tempDate, setTempDate] = useState(moment());
    const [showClubs, setShowClubs] = useState(false);
    const [clubInput, setClubInput] = useState<any>(null);
    const [selectedSport, setSelectedSport] = useState("all");
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
    }, []);

    const searchNearByClubs = async (clubName: string) => {
        setClubs([]);
        let data = {
            latitude: 78.9012,
            longitude: 12.3456,
            clubName,
            currentUser
        };

        const defaultResult = await getSearchNearbyClubs(data);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult.data.searched_clubs.length > 0
        ) {
            setClubs(defaultResult.data.searched_clubs);
        }
    };
    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (inputRef.current && !inputRef.current?.contains(event.target)) {
                setShowClubs(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectedClub) {
            handelClubClick(selectedClub);
        }
    }, [selectedClub]);

    const handleSubmit = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        const tempClub = clubs.find(
            (club: any) =>
                club.name.toLowerCase() === clubInput.toLowerCase()
        );

        if (tempClub) {
            handelClubClick(tempClub);
        }

        if (tempDate || level !== "all") {
            setDate(tempDate);
            setClubActivities(
                allClubActivities?.filter(
                    (item: {
                        start_datetime: number;
                        max_level: number;
                        min_level: number;
                    }) =>
                        moment(item.start_datetime).isSame(date, "day") ||
                        (item.max_level >= level && item.min_level <= level)
                )
            );
        } else {
            setClubActivities(allClubActivities);
        }
    };

    const handelClubClick = (club: any) => {
        setShowActivities(true);
        setShowClubs(false);
        setDisplayClub(club.name);
        getClubActivities(club._id)
            .then((clubActivitiesResp) => {
                localStorage.setItem('selectedClubID', club._id);
                if (selectedSport !== "all" && clubActivitiesResp?.data.length > 0){
                    const filteredActivities = clubActivitiesResp?.data?.filter((activity: any) => 
                        activity.court_info?.[0]?.sport?.[selectedSport] === true
                      );
                    setAllClubActivities(filteredActivities);
                    setClubActivities(filteredActivities);
                    setLoading(false);
                }
                else{
                    setAllClubActivities(clubActivitiesResp?.data);
                    setClubActivities(clubActivitiesResp?.data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            }).finally(() => {
                setLoading(false);
            })

            if (tempDate || level !== "all") {
                setDate(tempDate);
                setClubActivities(
                    allClubActivities?.filter(
                        (item: {
                            start_datetime: number;
                            max_level: number;
                            min_level: number;
                        }) =>
                            moment(item.start_datetime).isSame(date, "day") ||
                            (item.max_level >= level && item.min_level <= level)
                    )
                );
            } else {
                setClubActivities(allClubActivities);
            }
    };

    return (
        <div className="w-full min-h-[300px] flex flex-col justify-center items-center text-center text-secondary bg-[#FBFBFB] px-8 pt-8">
            <h1 className="lg:text-[45px] text-3xl font-semibold lg:mb-8">
                {t("Activities.title")}
            </h1>

            <form autoComplete="off" className="lg:flex-row lg:justify-center flex flex-col items-center flex-wrap py-6">
                <div className="relative p-2">
                    <div className="bg-white">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] top-[-3px] left-5 text-sm px-1">
                            {t("Discover.form.lable1")}
                        </label>
                        <select
                            className="min-w-[250px] h-[55px] bg-white outline-primary border border-[#000b2999] rounded-[50px] text-secondary p-4 appearance-none"
                            style={{
                                background:
                                    "url(/images/down-arrow.png) no-repeat 92% 50%"
                            }}
                            value={selectedSport}
                            onChange={(event)=>{setSelectedSport(event.target.value)}}
                        >
                            <option value="all">
                                {t("Discover.form.Select.opt-5")}
                            </option>
                            <option value="padel">
                                {t("Discover.form.Select.opt-2")}
                            </option>
                            <option value="tennis">
                                {t("Discover.form.Select.opt-1")}
                            </option>
                            <option value="badminton">Badminton</option>
                            <option value="pickleball">Pickleball</option>
                            <option value="squash">Squash</option>
                        </select>
                    </div>
                </div>
                <div className="relative p-2">
                    <label className="absolute bg-[#FBFBFB] text-[#000b2999] top-[-3px] left-[22px] text-[14px] px-1 z-10">
                        {t("Discover.form.lable2")}
                    </label>
                    <div className="relative">
                        <DatePicker
                            selected={tempDate.toDate()}
                            dateFormat={"dd/MM/yyyy"}
                            onChange={(date) => {
                                setTempDate(
                                    date ? moment(date, "YYYY-MM-DD") : moment()
                                );
                            }}
                            minDate={new Date()} 
                            className="min-w-[250px] w-full h-[60px] bg-white border-[1px] border-[#000b2999] rounded-[50px] text-secondary p-4 cursor-pointer outline-primary"
                        />
                        <IoCalendar
                            size={22}
                            className="text-gray-500 absolute right-[15px] top-[18px] z-0 pointer-events-none touch-none"
                        />
                    </div>
                </div>
                <Combobox>
                    {({ open }) => (
                        <>
                            <div className="relative p-2">
                                <Combobox.Label className="absolute bg-[#FBFBFB] text-[#000b2999] top-[-3px] left-[22px] text-[14px] px-1">
                                    {t("Discover.form.lable3")}
                                </Combobox.Label>
                                <Combobox.Input
                                    ref={inputRef}
                                    className="min-w-[250px] h-[55px] border-[1px] border-[#000b2999] rounded-[50px] placeholder-[#000b2999] text-secondary p-4 outline-primary"
                                    placeholder={t("Discover.form.placeholder")}
                                    value={displayClub}
                                    onChange={(event) => {
                                        setShowClubs(true);
                                        const inputValue = event.target.value;
                                        setDisplayClub(inputValue);
                                        searchNearByClubs(inputValue);
                                        setClubInput(inputValue);
                                    }}
                                    onKeyUp={(e: any) => {
                                        if (e.key === "Enter") {
                                            const tempClub = clubs.find(
                                                (club: any) =>
                                                    club.name === e.target.value
                                            );

                                            if (tempClub) {
                                                handelClubClick(tempClub);
                                            }
                                        }
                                    }}
                                />

                                {showClubs && clubs.length > 0 && (
                                    <Combobox.Options
                                        static
                                        className="absolute z-10 w-full bg-white shadow-md text-secondary h-max max-h-[300px] overflow-auto mt-2"
                                    >
                                        {clubs.map((club: any, index) => (
                                            <Combobox.Option
                                                key={index}
                                                value={club.name}
                                                onClick={() => {
                                                    handelClubClick(club);
                                                }}
                                            >
                                                {({ active }) => (
                                                    <div
                                                        className={`cursor-pointer px-3 py-2 text-left w-full min-h-[30px] h-max text-sm ${
                                                            active
                                                                ? "bg-secondary text-white"
                                                                : "hover:bg-secondary hover:text-white"
                                                        }`}
                                                    >
                                                        {club.name}
                                                    </div>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </>
                    )}
                </Combobox>

                <div
                    className="bg-secondary m-2 cursor-pointer text-white font-semibold lg:w-[170px] w-[250px] h-[60px] flex lg:justify-between justify-center gap-8 py-4 px-8 lg:gap-0 items-center rounded-[50px] hover:bg-primary hover:text-secondary transform duration-200 group"
                    onClick={(e) => handleSubmit(e)}
                >
                    {t("Discover.form.Submit")}
                    <BsSearch className="submit-icon group-hover:text-secondary" />
                </div>
            </form>
        </div>
    );
};

export default DiscoverActivities;
