// import "../styles/TermsPrivacy.css"

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.privacy")} | Padel Mates</title>
            </Helmet>
            <div className="px-4 py-8 sm:px-8">
                <div>
                    <p className="text-primary font-semibold text-center text-2xl mb-4">
                        PRIVACY POLICY
                        <br />
                        PADEL MATES
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            This privacy policy (the "Privacy Policy") aims to
                            provide you (the "User" or "you"), with information
                            on how we, Padel Mates International AB ("PADEL
                            MATES", "we" or "us") process your personal data in
                            different situations.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            This Privacy Policy applies when you are using our
                            services via our application "PADEL MATES" (the
                            "App"). Via the App, we provide individuals with a
                            platform and a community where our members can
                            contact and engage with other persons and groups of
                            persons interested in padel and other racket sports,
                            set up matches, organize tournaments, settle
                            payments and make bookings at sports facilities
                            around the world (the "Services"). This Privacy
                            Policy also applies when you are visiting and/or
                            make bookings via our website www.padelmates.se (the
                            "Website").
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            We are the data controller, which means that we are
                            responsible for the processing of your personal data
                            and to provide you with clear, concise and correct
                            information regarding the personal data processing
                            we carry out.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Although we have taken great care in ensuring that
                            this Privacy Policy includes all information you
                            need, please contact us at support@padelmates.se if
                            you have any questions.
                        </span>
                        <span lang="EN-US"> </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                Personal data and other key terms
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            It is important to us that the information in this
                            Privacy Policy is easy to understand. Below we
                            attempt to clarify certain terms and concepts used
                            in the Privacy Policy.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Personal data</b>" is any kind of information
                            that can be directly or indirectly attributed to
                            you. Common examples of personal data are name,
                            address, e-mail address and telephone number. User
                            ratings (skill level or sportsmanship level), win
                            rates, points, descriptions of playing styles,
                            strengths and weaknesses, Usage history, member id
                            are also examples of personal data, as well as
                            information you provide to us when contacting
                            customer support.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Legal basis</b>" is the basis we rely on to
                            process your personal data for different purposes.
                            Any processing activity we carry out needs to be
                            supported by such a legal basis, and we need to
                            inform you of which basis apply. For example, in
                            some cases our processing is justified as it is
                            necessary to perform our contract with you, and
                            other times we have a legal obligation to process
                            the personal data.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Purpose</b>" is why we need to process your
                            personal data in the first place. All processing of
                            personal data must be carried out for explicit and
                            legitimate purposes.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Retention time</b>" refers to the time period
                            during which we need to process your personal data
                            for a particular purpose. A lot of the personal data
                            will be processed by us for as long as you have an
                            account.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            "<b>Active account</b>" means an account that you
                            have not requested be deleted or removed or that has
                            not been banned by us.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <b>
                        <span
                            lang="EN-US"
                            style={{
                                fontSize: "14.0pt",
                                lineHeight: "107%",
                                fontFamily: '"Calibri",sans-serif'
                            }}
                        >
                            <br style={{ pageBreakBefore: "always" }} />
                        </span>
                    </b>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span
                                lang="EN-US"
                                style={{
                                    fontSize: "14.0pt",
                                    lineHeight: "107%"
                                }}
                            >
                                &nbsp;
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                The source of your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            The majority of the personal data is collected
                            directly from you when you register your account or
                            otherwise use the Services.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            In some situations, we have gathered your personal
                            data from other sources. For example, if you are a
                            member of one of our partnered sports facilities, we
                            may receive personal data related to you from such
                            partners.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                How and why we process your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            In the tables below we have attempted to clarify how
                            we process your personal data in different
                            situations or different parts of our services.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                When using the Application (Community and
                                Matchmaking Services)
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Creating and managing your
                                                    account
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, email,
                                                    phone number, postal address
                                                    (optional), image
                                                    (optional), gender, age,
                                                    credit card information or
                                                    other payment information,
                                                    match history and form,
                                                    match time preferences
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Organizing public matches or
                                                    tournaments in the App
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, image
                                                    (if available), gender, age,
                                                    ratings (skill and
                                                    sportsmanship), phone number
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Providing chat services
                                                    between users
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, image,
                                                    email, phone number, ratings
                                                    (skill and sportsmanship)
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Calculating, publishing and
                                                    updating the ratings (skill
                                                    and sportsmanship) of the
                                                    user
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, match
                                                    history and form, ratings
                                                    (skill and sportsmanship)
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Purchases of premium
                                                    subscriptions or other in
                                                    app purchases
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, email,
                                                    phone number, postal
                                                    address, credit/debit card
                                                    information or other payment
                                                    information
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of a contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    Payment information stored
                                                    for +7 years from date of
                                                    payment
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    When suspecting violations
                                                    of our terms and conditions
                                                    and for monitoring and
                                                    analyzing your use of the
                                                    Services
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, email,
                                                    phone number, chat history,
                                                    booking history and user
                                                    generated data (e.g.
                                                    interactions with other
                                                    users in the App)
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Customer support via email
                                                    or telephone
                                                </td>
                                                <td className="px-6 py-4">
                                                    Name, email, phone number
                                                    and any other personal data
                                                    provided by you as part of
                                                    the support matter
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as the support
                                                    matter is ongoing
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                When using the Application or Website (Booking
                                Services)
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Making and confirming
                                                    bookings made with relevant
                                                    sports facilities
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name, image
                                                    (if available), credit/debit
                                                    card information or other
                                                    payment information, email
                                                    and phone number.
                                                </td>
                                                <td className="px-6 py-4">
                                                    Performance of contract
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                    Payment information stored
                                                    for +7 years from date of
                                                    payment
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Provide you with information
                                                    on sports facilities
                                                    locations near you
                                                </td>
                                                <td className="px-6 py-4">
                                                    Location data
                                                </td>
                                                <td className="px-6 py-4">
                                                    Consent
                                                </td>
                                                <td className="px-6 py-4">
                                                    Location data is only
                                                    processed when you are using
                                                    our location services and
                                                    only if you have consented.
                                                    See also "Your right to
                                                    withdraw consent" below
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Place relevant booking
                                                    information in the calendar
                                                    of your device
                                                </td>
                                                <td className="px-6 py-4">
                                                    Limited calendar access
                                                </td>
                                                <td className="px-6 py-4">
                                                    Consent
                                                </td>
                                                <td className="px-6 py-4">
                                                    The access is contingent on
                                                    your consent, which can be
                                                    withdrawn at anytime. See
                                                    "Your right to withdraw
                                                    consent" below
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                Marketing and similar purposes
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Sending marketing
                                                    material/newsletters and
                                                    similar
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    Until you opt-out (see "Your
                                                    right to object" below)
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Sending questionnaires about
                                                    our Services
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    Until you opt-out (see "Your
                                                    right to object" below)
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    If you respond to voluntary
                                                    questionnaires; processing
                                                    and analyzing your responses
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    The access is contingent on
                                                    your consent, which can be
                                                    withdrawn at anytime. See
                                                    "Your right to withdraw
                                                    consent" below
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Sharing your personal data
                                                    with marketing partners
                                                </td>
                                                <td className="px-6 py-4">
                                                    First and last name and
                                                    email
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    The access is contingent on
                                                    your consent, which can be
                                                    withdrawn at anytime. See
                                                    "Your right to withdraw
                                                    consent" below
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-US"
                            style={{ fontSize: "12.0pt", lineHeight: "107%" }}
                        >
                            &nbsp;
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <span lang="EN-US">
                                To improve the Services and similar
                            </span>
                        </i>
                    </p>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium bg-gray-200 dark:border-neutral-500">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Purpose
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Categories of personal data
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Legal basis
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-4"
                                                >
                                                    Retention time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Developing new features in
                                                    the App or on the Website,
                                                    or improving our Services
                                                </td>
                                                <td className="px-6 py-4">
                                                    Name, email, user patterns
                                                    and behaviors, information
                                                    provided in questionnaires
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                            <tr className="border-b dark:border-neutral-500">
                                                <td className=" px-6 py-4 font-medium max-w-[300px]">
                                                    Maintenance, operation and
                                                    security of our IT systems,
                                                    the Website and the App
                                                </td>
                                                <td className="px-6 py-4">
                                                    May vary, but generally
                                                    limited to aggregated user
                                                    information
                                                </td>
                                                <td className="px-6 py-4">
                                                    Legitimate interest
                                                </td>
                                                <td className="px-6 py-4">
                                                    For as long as you have an
                                                    active account with us
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span
                                lang="EN-US"
                                style={{
                                    fontSize: "14.0pt",
                                    lineHeight: "107%"
                                }}
                            >
                                <br />
                            </span>
                        </b>
                        <b>
                            <span lang="EN-US">
                                Sharing and transfers of your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            In providing our Services, we will need to share
                            your personal data with different third parties:{" "}
                        </span>
                    </p>
                    <p
                        className="leading-6 text-sm"
                        style={{ textIndent: "-18.0pt" }}
                    >
                        <span lang="EN-US">
                            -
                            <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                        <span lang="EN-US">
                            We share necessary sets of your personal data with
                            certain suppliers, such as providers of hosting
                            services and software solutions, and are often
                            referred to as "data processors". These recipients
                            will only process your personal data in accordance
                            with our instructions. If you want more information
                            on the identity of such data processors, contact us
                            at support@padelmates.se.
                            <br />
                            <br />
                        </span>
                    </p>
                    <p
                        className="leading-6 text-sm"
                        style={{ textIndent: "-18.0pt" }}
                    >
                        <span lang="EN-US">
                            -
                            <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        </span>
                        <span lang="EN-US">
                            When using the booking services, we will need to
                            transfer your personal data to the relevant sports
                            facility where you have made a booking, which will
                            use your personal data for administering the booking
                            but also for other purposes (such as marketing).
                            Please refer to the privacy policies of each sports
                            facility for more information on its processing
                            activities (and links to each sports facility's
                            website are typically available in the App). We may
                            also transfer your information to payment service
                            providers (such as Stripe), who facilitate your
                            payment of booking fees and similar.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">Cookies</span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            On our Website, we use cookies to gather data for
                            different purposes. Cookies that are not "strictly
                            necessary" for the operation of our website need to
                            be approved by you before we can start using them.{" "}
                            <br />
                            <br />
                            Cookies are small text files that are placed on your
                            device when you visit the Website and use our
                            Services. They can be used to improve the Website
                            and make it easier for you to use or to help us
                            analyse and improve our Services.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            To provide you with information on which cookies we
                            use, and to handle your approval or consent to such
                            use, we use the tool react-cookie-consent. Via this
                            tool you can find detailed information on all
                            cookies we use, their purposes and manage if you
                            want to provide or withdraw your consent to any
                            specific cookie.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            It is important to note that some cookies are
                            strictly necessary for the Website to operate
                            properly, and such cookies will be set without your
                            prior approval or consent. For all other cookies you
                            are free to change your approvals at any time via
                            the tool react-cookie-consent available here.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">
                                Where we process your personal data
                            </span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Personal data that we collect from you in accordance
                            with this policy is always stored by us within a
                            country of the European Union or the European
                            Economic Area ("EU/EEA"). If, however, we need to
                            transfer your personal data to a country outside of
                            the EU/EEA, any such transfer will always be carried
                            out in compliance with applicable laws and we will
                            take appropriate actions to ensure that the
                            protection of your personal data is not undermined.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            For example, if the sports facility that you wish to
                            book a time slot with is located outside the EU/EEA,
                            relevant personal data will need to be transferred
                            to such sports facility. In these situations, we
                            have ensured an appropriate level of protection of
                            your personal data by entering into standard data
                            protection clauses with such sports facilities. For
                            more information, please contact
                            support@padelmates.se
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <b>
                            <span lang="EN-US">Your rights</span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            The protection of your personal data is a
                            fundamental right, and according to the GDPR you
                            have a number of explicit rights that you may
                            exercise in different situations.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to access
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">
                            You have the right to demand and receive
                            confirmation on whether or not we process personal
                            data which concerns you. If such personal data is
                            being processed, you have the right to receive
                            information regarding the processing and a copy,
                            free of charge, of the personal data being
                            processed. If you request additional copies, we are
                            entitled to charge you a reasonable administrative
                            fee.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-GB">
                                    Your right to rectification
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">
                            You have the right to request rectification of your
                            personal data if the information is incorrect,
                            including the right to have incomplete personal data
                            completed.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">Your right to erasure</span>
                            </u>
                        </i>
                        <u>
                            <span lang="EN-US">
                                <br />
                            </span>
                        </u>
                        <span lang="EN-GB">
                            You have the right to have your personal data erased
                            without undue delay and we are obligated to erase
                            personal data in the following situations:
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (i)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the personal data are no longer necessary in
                            relation to the purposes for which they were
                            collected or otherwise processed;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (ii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the processing is based on your consent and you
                            withdraw your consent, unless there are any other
                            legal grounds for the processing;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            you object to processing based on a "legitimate
                            interest" and there are no overriding legitimate
                            grounds for the processing (note however that you
                            always have the right to erasure of your personal
                            data used for direct marketing purposes);
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iv)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the personal data have been unlawfully processed; or
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (v)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the personal data have to be erased for compliance
                            with a legal obligation in Union or Member State law
                            to which we are subject.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Typically speaking, if you delete your account in
                            the Application, we will erase a majority of the
                            personal data we process about you - as the personal
                            data will no longer be necessary for us to process.
                            However, in certain situations we will need to
                            process your personal data, even if you request its
                            erasure, for example to comply with legal
                            obligations under EU or Swedish law or when
                            necessary for us to establish, exercise or defend
                            legal claims. In these situations, your right to
                            erasure is limited.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to restriction
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">
                            You have the right to restrict our processing of
                            your personal data in the following situations:
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (i)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the accuracy of the personal data is contested by
                            you, for a period enabling us to verify the accuracy
                            of the data;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (ii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            the processing is unlawful but you do not want to
                            erase the personal data and instead request that the
                            use of the data is restricted;
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iii)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            we no longer need the personal data for the purposes
                            of the processing, but you need them for the
                            establishment, exercise or defence of legal claims;
                            or
                        </span>
                    </p>
                    <p className="leading-6 text-sm">
                        <span lang="EN-US">
                            (iv)
                            <span>&nbsp;&nbsp;&nbsp;</span>
                        </span>
                        <span lang="EN-GB">
                            you have objected to processing based on "legitimate
                            interest", pending the verification whether our
                            legitimate grounds to process the data override
                            yours.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-GB">
                            To clarify " when you request restriction, the
                            personal data will continue to be stored, but PADEL
                            MATES will not process the relevant personal data
                            for any other purpose without your consent. Again,
                            if we need to process the personal data in order to
                            establish, exercise or defend against legal claims,
                            the restriction on processing will not apply in
                            relation to such processing. Lastly, if processing
                            is necessary for the protection of the rights of
                            another natural or legal person, the restrictions
                            may not apply.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-GB">
                                    Your right to data portability
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-US">
                            Whenever we process your personal data, by automated
                            means based on your <u>consent</u>
                            or based on a <u>contract</u>, you have the right to
                            get a copy of your data transferred to you or to
                            another party. This only includes the personal data
                            that you yourself have provided us with.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to object
                                    <br />
                                </span>
                            </u>
                        </i>
                        <span lang="EN-GB">You have the right to object</span>
                        <span lang="EN-GB"></span>
                        <span lang="EN-GB">
                            to our processing of personal data which is based on
                            a "legitimate interest." We will not continue to
                            process the personal data unless we can show
                            compelling legitimate grounds for the processing
                            which override your interests, rights and freedoms{" "}
                            <u>or</u> for the establishment, exercise or defence
                            of legal claims.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Importantly, however, you always have the right to
                            object to our processing of personal data for direct
                            marketing purposes, and if you do (often referred to
                            as "opt-out") we will stop processing the personal
                            data for such marketing purposes. In relation to
                            newsletters or marketing materials sent by email,
                            the easiest way to opt-out is to use the
                            "unsubscribe"-button in the email.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to withdraw consent
                                </span>
                            </u>
                        </i>
                        <u>
                            <span lang="EN-US">
                                {" "}
                                <br />
                            </span>
                        </u>
                        <span lang="EN-GB">
                            If you have provided us with your consent for any
                            processing, you have the right to at any time
                            withdraw it. If you do, we will no longer process
                            your personal data for these purposes.
                        </span>
                    </p>
                    <p
                        className="leading-6 mt-4 text-sm"
                        style={{ lineHeight: "normal" }}
                    >
                        <span lang="EN-GB">
                            PADEL MATES currently primarily relies on your
                            consent to process location information. The consent
                            is gathered from you in the application and if you
                            wish to withdraw the consent, you can do so by
                            following the instructions below.{" "}
                        </span>
                        <span lang="EN-GB">
                            Please note that if you choose to withdraw the
                            consent by disabling the location services feature
                            on your mobile device, this will limit the
                            performance of the App in the sense that the App
                            will no longer be able to provide information on
                            nearby sports courts.{" "}
                        </span>
                        <span lang="EN-GB">
                            Please feel free to contact us if you have any
                            issues.
                        </span>
                    </p>
                    <p
                        className="leading-6 mt-4 text-sm"
                        style={{ lineHeight: "normal" }}
                    >
                        <span lang="EN-GB">
                            For iOS: You should be able to adjust the settings
                            on your iOS mobile device to prevent our collection
                            of location information by disabling the location
                            services feature on your device. For more detailed
                            information on how to choose which applications that
                            use your iOS device's location, please click{" "}
                        </span>
                        <span lang="SV">
                            <a href="https://support.apple.com/en-gb/guide/personal-safety/ips3dbc70436/web">
                                <span lang="EN-GB">here</span>
                            </a>
                        </span>
                        <span lang="EN-GB">. </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-GB">
                            For Android: You should be able to adjust your
                            settings on your Android mobile device to prevent
                            our collection of location information by disabling
                            the location services feature on your device. For
                            more detailed information on how to choose which
                            applications use your Android device's location,
                            please click{" "}
                        </span>
                        <span lang="SV">
                            <a href="https://support.google.com/accounts/answer/3467281?hl=en&ref_topic=7189122">
                                <span lang="EN-GB">here</span>
                            </a>
                        </span>
                        <span lang="EN-GB">.</span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span
                            lang="EN-GB"
                            style={{
                                fontSize: "10.0pt",
                                lineHeight: "107%",
                                fontFamily: '"Times New Roman",serif'
                            }}
                        >
                            <br />
                        </span>
                        <i>
                            <u>
                                <span lang="EN-US">
                                    Your right to file complaints with
                                    authorities{" "}
                                </span>
                            </u>
                        </i>
                        <u>
                            <span lang="EN-US">
                                <br />
                            </span>
                        </u>
                        <span lang="EN-US">
                            For any request relating to this Privacy Policy, or
                            if you wish to exercise any of your right or have
                            any complaints, you are always free to contact us
                            (see details below). You also always have the right
                            to file a complaint with{" "}
                        </span>
                        <span lang="EN-GB">the </span>
                        <span lang="SV">
                            <a href="https://www.imy.se/">
                                <span lang="EN-GB">
                                    Swedish Authority for Privacy Protection
                                </span>
                            </a>
                        </span>
                        <span lang="EN-GB"> (Sw. </span>
                        <i>
                            <span lang="EN-US">
                                Integritetsskyddsmyndigheten
                            </span>
                        </i>
                        <span lang="EN-GB">
                            ) or the corresponding authority in your country of
                            residence.
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <u>
                            <span lang="EN-US">
                                <br />
                            </span>
                        </u>
                        <b>
                            <span lang="EN-US">Contact information</span>
                        </b>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="EN-US">
                            Feel free to contact us by email at
                            support@padelmates.se or the address below.{" "}
                        </span>
                    </p>
                    <p className="leading-6 mt-4 text-sm">
                        <span lang="SV">
                            Padel Mates International AB, org. nr. 559273-1359,
                            Fågelviksvägen 9, 145 53 NORSBORG, Sweden.
                        </span>
                    </p>
                </div>
                <div className=" py-8 bg-white text-gray-800">
      <h1 className="text-2xl font-medium mb-6 text-left">TERMS AND CONDITIONS</h1>

      <p className="text-sm mb-4 leading-relaxed">
        Please read these terms and conditions carefully as they contain important information about your legal
        rights and obligations. These terms and conditions for users (the ”Terms and Conditions”) govern the
        use of the application Padel Mates (the “Application”) accessed through a smartphone and/or tablet
        (the “Devices”). These Terms and Conditions also outline the rules and regulations for the use of Padel
        Mates International’s website, located at https://padelmates.se/ (the “Website”). By using the Services
        (as defined below), you (“you” or the “User”) accept and agree to the Terms and Conditions and you
        enter into a legally binding agreement with Padel Mates International AB, organisation number
        559273-1359, (“us” or “PM”).
      </p>
      <p className="text-sm mb-4 leading-relaxed">
        If you have downloaded the Application from App Store, you will also need to accept the specific End
        User Licensing Agreement of Apple Inc. (the “Apple Terms”). In case of conflict between the Terms
        and Conditions and the Apple Terms, the Apple Terms will take precedence.
      </p>

      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">1 THE SERVICES</h2>
      <p className="text-sm mb-4 leading-relaxed">
        1.1 PM provides the Website and the Application (collectively, the “Platform”), which is a multi-sport
        matchmaking, booking, and community service. The Platform allows the User to:
      </p>
      <ul className="list-disc ml-8 mb-4">
      <p className="text-sm mb-4 leading-relaxed">i. Search, book and pay for time slots at different sports facilities;</p>
      <p className="text-sm mb-4 leading-relaxed">ii. Create or join sports games, tournaments and training sessions;</p>
      <p className="text-sm mb-4 leading-relaxed">iii. Set the User’s game level based on a proven algorithm;</p>
      <p className="text-sm mb-4 leading-relaxed">
          iv. Find opponents or get game suggestions based on the User’s favourite sports facility and game level;
        </p>
        <p className="text-sm mb-4 leading-relaxed">v. Book or host the User’s own tournaments;</p>
        <p className="text-sm mb-4 leading-relaxed">vi. Book or contact verified and reviewed coaches;</p>
        <p className="text-sm mb-4 leading-relaxed">vii. Follow and chat with other users;</p>
        <p className="text-sm mb-4 leading-relaxed">viii. Gain insight into the User’s statistics and development;</p>
        <p className="text-sm mb-4 leading-relaxed">ix. Get feedback on the User’s game level after each sports game;</p>
        <p className="text-sm mb-4 leading-relaxed">
          x. Participate in community groups where users and/or sports facilities can create open and private
          groups.
        </p>
      </ul>
      <p className="text-sm mb-4 leading-relaxed">
        1.2 The provision of services as described in section 1.1 above are referred to herein as the “Services”.
      </p>
      <p className="text-sm mb-4 leading-relaxed">
        1.3 PM aims to continuously develop and improve the Services and the Platform, and may launch new
        technical features of the Services. Such new features may change the appearance and functionality of
        the Services and/or the Platform. PM expressly reserves the right to, from time to time, change,
        develop or amend the Services, the Platform and any information or content therein.
      </p>

      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">2 USER ACCOUNT</h2>
      <p className="text-sm mb-4 leading-relaxed">
        2.1 By accepting the Terms and Conditions, you submit that you are over the age of 13 years. The
        Services are not intended for use by persons under the age of 13. If you are under 13, you should use
        the Services only with involvement of a parent or guardian.
      </p>
      <p className="text-sm mb-4 leading-relaxed">
        2.2 In order to use the Services, you must register a user account with PM (“User Account”) and accept
        the Terms and Conditions. It is also possible to register an account by connecting to your Google
        account, Facebook account or other account you may use which we offer an integration with. In that
        case, we will collect your personal data from the party managing such accounts. If you register for a
        User Account, you agree to provide true and accurate information in connection with your registration
        and to keep that information current. You are solely responsible for any inaccuracies or errors in the
        information you have provided to us.
      </p>

      <p className="text-sm mb-4 leading-relaxed">
      2.3 Only one User Account is permitted per user and the registration of a User Account is free of charge. The User Account is personal to the User and may not be transferred or in any other way assigned to a third party, unless expressly stated in the Terms and Conditions.
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      2.4 The User acknowledges and agrees that the User Account login details and passwords are strictly personal and the User may be held responsible for all activity related to the User Account. The User agrees to promptly notify PM if the User has reason to believe that unauthorised access to the User Account has occurred, and such notification should be sent to <a
          href="mailto:support@padelmates.se"
          className="text-blue-500 hover:text-blue-700 font-semibold"
        >
          support@padelmates.se
        </a>. PM has the right, but not the obligation, to block access to the User Account if there is reason to believe that unauthorised access has occurred or will occur.  
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      2.5 PM may restrict the User access to the Services if there is reason to believe that the User is violating, or will violate, the Terms and Conditions and terminate the provision of the Services with immediate effect by notice by email. In the cases of termination, any and all outstanding benefits (including coins) earned by the User will expire.  
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      2.6 For the avoidance of doubt, you may at any time choose to delete your User Account, but will in such case no longer have any access to the Services or the Platform.
      </p>
      
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">3 USE OF The services AND PAYMENTS  </h2>
      <p className="text-sm mb-4 leading-relaxed">
      3.1 The Services may only be used for personal and non-commercial use. The User undertakes not to use the Services or any of the functions provided through the Platform for illegal purposes or purposes prohibited by the Terms and Conditions. For example, the User may not monitor, copy, scrape/crawl, download, reproduce, reverse-engineer, disassemble, attempt to derive the source code of or modify the Platform, or otherwise use anything in the Platform, or in other parts of the Services, for any commercial purposes without written permission of PM or its licensors.
      </p>

      <p className="text-sm mb-4 leading-relaxed">
      3.2 To use the Services and to maintain a comfortable and safe environment with us and/or other users within the Padel Mates community, the User pledges to respect other users of the Platform and act in an appropriate manner towards us and other users of the Platform. It is not permitted to post content: 
      </p>
      <ul className="list-disc ml-8 mb-4">
      <p className="text-sm mb-4 leading-relaxed">i. which is discriminatory in matters of appearance, race, religion, gender, culture, background, or is otherwise found offensive or inappropriate (as determined at PM’s sole discretion); </p>
      <p className="text-sm mb-4 leading-relaxed">ii. which calls for violence against and/or the harassment of another person or persons, or which promotes or effectuates illegal activities; </p>
      <p className="text-sm mb-4 leading-relaxed">iii. which contains viruses, Trojan horses, worms, bots, or other software which can damage, render unusable or inaccessible a computerised work, which can delete or appropriate it, or which is intended to obviate the technical security measures of the Platform; </p>
      <p className="text-sm mb-4 leading-relaxed">
          iv. which is based on falsehoods and/or is misleading or which consists of the assuming of a false identity and/or wrongly suggesting that the User is associated with PM; 
        </p>
        <p className="text-sm mb-4 leading-relaxed">v. which involves chain letters, junk mail, or spamming and/or whereby passwords or other information traceable to persons is requested for commercial or illegal purposes; </p>
        <p className="text-sm mb-4 leading-relaxed">vi. which involves chain letters, junk mail, or spamming and/or whereby passwords or other information traceable to persons is requested for commercial or illegal purposes; </p>
        <p className="text-sm mb-4 leading-relaxed">vii. which violates these Terms and Conditions, PM’s privacy policy, or any applicable legislation and/or regulation; </p>
        <p className="text-sm mb-4 leading-relaxed">viii. which in any manner is illegitimate or can impair the interests and reputation of PM. </p>
      </ul>
      <p className="text-sm mb-4 leading-relaxed">
      3.3 PM reserves the right to abridge, modify, refuse, or remove content from the Platform if this is necessary in the reasonable opinion of PM, without any liability in relation to the User. If you are of the opinion that certain content infringes on your rights or the rights of another user or a third party, you may report such to PM by <a
          href="mailto:support@padelmates.se"
          className="text-blue-500 hover:text-blue-700 font-semibold"
        >
          support@padelmates.se
        </a>.  
      </p>
      <p className="text-sm mb-4 leading-relaxed">3.4 By booking a time slot through the Platform, the User enters into an agreement with the applicable sports facility in accordance with the general terms and conditions of the sports facility (the “Sports Facility Conditions”). The Sports Facility Conditions include rules on bookings and cancellations (the “Booking Conditions”). The Booking Conditions of the respective sports facilities will always be made available through the Platform. However, it is the responsibility of the applicable sports facility to ensure that the Booking Conditions in the Platform are correct and up to date. PM is not responsible for providing time slots or other obligations arising out of the agreement between the User and the sports facility. PM is not a party to, and does not become a party to any contractual relationship between the User and such sports facilities. The applicable sports facility is solely responsible for the sports experience. From the point at which the User makes a booking through the Platform, PM solely acts as an intermediary between the User and the applicable sports facility.</p>
      <div className="px-8">

      <p className="text-sm mb-4 leading-relaxed">1.1 By booking a time slot through the Platform, the User enters into an agreement with the applicable sports facility in accordance with the general terms and conditions of the sports facility (the “Sports Facility Conditions”). The Sports Facility Conditions include rules on bookings and cancellations (the “Booking Conditions”). The Booking Conditions of the respective sports facilities will always be made available through the Platform. However, it is the responsibility of the applicable sports facility to ensure that the Booking Conditions in the Platform are correct and up to date. PM is not responsible for providing time slots or other obligations arising out of the agreement between the User and the sports facility. PM is not a party to, and does not become a party to any contractual relationship between the User and such sports facilities. The applicable sports facility is solely responsible for the sports experience. From the point at which the User makes a booking through the Platform, PM solely acts as an intermediary between the User and the applicable sports facility.</p>
      <p className="text-sm mb-4 leading-relaxed">1.2 If you wish to review, amend or cancel the booking of a time slot, please follow the instructions in the Platform. The applicable sports facility’s Booking Conditions apply, and it is up to you to ensure that you have read the Booking Conditions carefully. Should information be missing in the Booking Conditions, you shall contact the sports facility in order to obtain information on the applicable cancellation policy.  </p>
      <p className="text-sm mb-4 leading-relaxed">1.3 In cases where you have purchased a product or other service through the Platform and wish to review, amend or cancel such purchase, please contact us by email at <a
          href="mailto:support@padelmates.se"
          className="text-blue-500 hover:text-blue-700 font-semibold"
        >
          support@padelmates.se
        </a>.</p>
      <p className="text-sm mb-4 leading-relaxed">1.4 When booking a time slot, or purchasing a product or other service through the Platform, the User will receive a confirmation in the Platform which will be displayed together with the relevant price (the ”Fee”). The User shall pay the Fee by using the relevant payment method as indicated in the Platform. After receiving payment, PM will send out an electronic receipt by email. The Fee must be paid in full before the time slot, product or other service can be used. The Fee will include applicable taxes as required by law.  </p>
      <p className="text-sm mb-4 leading-relaxed">1.5 The User may through the Platform create sports games, tournaments and training sessions which other users may join, and the Application includes functionality whereby different users can settle debts owed to one another via Swish or another payment method that is currently available. Please note that the availability of payment methods may vary by jurisdiction. Also, please note that payments between users for sports games, tournaments and training sessions organized by the users themselves are outside the scope of PM’s responsibility. PM will not be held accountable for any issues arising from such payments made directly between users, such as non-payment or incomplete payment, but encourages users to report such situations to PM.  </p>
      <p className="text-sm mb-4 leading-relaxed">PM reserves the right to ban users from the Platform if they are reported for any misconduct related to the above. If a user is reported, PM will contact the user in question, and if there is no response, the user may be banned. If the user responds and pays, he or she will receive a warning. Two warnings will result in a ban from the Platform.  </p>
      </div>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">4 Bookings, Purchases, and Payments Within Padel Mates</h2>
      <p className="text-sm mb-4 leading-relaxed">
      4.1 Through your Padel Mates account, you can book and pay for courses and activities at various Venues, or purchase credits, memberships, and other offerings available at the Venues. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      4.2 The Venues, including their contact details, as well as the courses, activities, competitions, and available time slots, will be listed on the Website and in the Application. Please note that this information may change periodically. It is important to recognize that Padel Mates is not responsible for the availability or content related to the Venues, their courses, activities, competitions, memberships, credits, or any other offers provided by the Venues. 
      </p><p className="text-sm mb-4 leading-relaxed">
      4.3 When you purchase a booking through Padel Mates, it must be used in full during a single session; bookings cannot be reserved for future use. Bookings are personal and non-transferable unless otherwise stated. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      4.4 Some Venues may offer various types of redeemable value cards, punch cards, credits, or similar items (collectively referred to as "Credits"). These Credits, which are pre-paid by the purchaser, can be used to pay for goods or services at one or more Venues within a specified period under the terms set by the issuing Venue. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      4.5 When you make a booking or purchase via Padel Mates, the details of your booking or purchase are sent to the relevant Venue. This information is then accessible to the Venue through their booking system. The Venues operate independently from Padel Mates and are not affiliated with us as agents or representatives. Consequently, Padel Mates is not liable, to the extent permitted by applicable law, for any information, content, facilities, goods, services, Credits, or actions of the Venues, including those provided on their websites or applications. 
      </p><p className="text-sm mb-4 leading-relaxed">
      4.6 Padel Mates serves as a facilitator for the bookings or purchases you make through our Service, including payment processing for bookings, memberships, Credits, or other offers. When you complete a payment on our Website or in the Padel Mates Application, the payment is made to us or a designated third party, after which it is forwarded to the respective Venue. Padel Mates acts as a commercial agent on behalf of the Venues, handling transactions without bearing the financial benefits or costs, and subsequently settling accounts with the Venue. 
      </p><p className="text-sm mb-4 leading-relaxed">
      4.7 The prices listed on the Website or in the Application at the time of booking or purchase are the prices that will apply to your transaction. All prices are displayed in local currency and include VAT, if applicable. 
      </p><p className="text-sm mb-4 leading-relaxed">
      4.8 Should there be an error in the price or information of a course, activity, or other offer listed on the Website or in the Application, and you noticed or should have noticed this, the incorrect price or information will not be applicable. In such cases, we will contact you promptly. 
      </p><p className="text-sm mb-4 leading-relaxed">
      4.9 We offer various payment options, allowing you to choose the one that best suits you. We, along with the payment service providers we collaborate with, reserve the right to determine which payment options are available, which may change over time. The available payment options will always be clearly stated before you finalize your booking or purchase. Depending on the payment method you select, additional costs may apply. 
      </p><p className="text-sm mb-4 leading-relaxed">
      4.10 You may have the option to cancel your booking and receive a refund, subject to the cancellation and refund policies of the specific Venue. These terms may vary depending on the Venue where the booking was made. Consequently, you may be charged a cancellation fee or be required to pay the full price of the booking, in line with the Venue’s cancellation and refund policies. The specific terms applicable to your booking will be displayed on the Website or in the Application at the time of booking. Additionally, each Venue may have its own terms governing the use of their facilities, participation in activities, redemption of Credits, or the purchase of goods or services.  
      </p>
      <p className="text-sm mb-4 leading-relaxed">
        4.11 Padel Mates may also charge a cancellation fee upon cancellation of your booking. If such a fee is applicable, it will be indicated on the Website or in the Application at the time of booking. This fee is not for the benefit of the Venue and will not be forwarded to them. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
        4.12 Moreover, one of the features we offer allows users to split the payment for a booking among multiple players. The user who initiates a booking with a split payment option is always responsible for paying the full amount if the invited players do not complete their payment or cancel their part. When opting for split payment, a non-refundable fee is charged to each participant. 
      </p>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">5 PADEL MATES PRO</h2>
      <p className="text-sm mb-4 leading-relaxed">
       5.1 PM makes available for users a subscription based service (“Padel Mates PRO”), which if purchased by the User provides access to additional features within the Platform. Payment of Padel Mates PRO shall be managed through the applicable application store. The User declares to be aware of and accepts to be bound by the relevant terms and conditions of the applicable application store, which can be visited through the following links: 
      </p>
      <div className="flex-col mb-4">
      <p className="text-sm leading-relaxed">
      <a className="text-blue-500 underline" href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">App Store</a>
      </p>
      <p className="text-sm leading-relaxed">
      <a className="text-blue-500 underline" href="https://store.google.com/intl/en_uk/about/device-terms/">Google Store</a>
      </p>
      </div>
      <p className="text-sm mb-4 leading-relaxed">
       5.2 The User may terminate his/her subscription of Padel Mates PRO by cancelling it through the applicable application store before the next payment date is due, in accordance with the instructions and time periods described in the relevant terms and conditions of the applicable application store mentioned in Section 4.1 above. In any event, PM, subject to applicable law, shall have no liability in relation to the User and shall not refund any amounts paid for Padel Mates PRO. 
      </p>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">6 USE OF PERSONAL DATA </h2>
      <p className="text-sm mb-4 leading-relaxed">
       6.1 In order to provide the Services and perform its obligations to the User, PM must collect, process and to some extent store personal data of the User. PM processes data in accordance with the applicable data protection legislation in force at any given time, including the General Data Protection Regulation. Information on PM’s processing of personal data can be found in the privacy policy available here. 
      </p>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">7 INTELLECTUAL PROPERTY RIGHTS </h2>
      <p className="text-sm mb-4 leading-relaxed">
       7.1 Unless otherwise stated, all intellectual property in the Platform or in other parts of the Services, is either owned or licensed by PM. Nothing in these Terms and Conditions shall be interpreted as a transfer of any intellectual property or other rights to the User or a third party.  
      </p><p className="text-sm mb-4 leading-relaxed">
       7.2 Subject to the User’s compliance with these Terms and Conditions, PM grants the User a revocable, non-exclusive, non-transferable, non-sublicensable right to install and use the Application on Devices owned or controlled by the User, and to access and use the Application and the Services on such Devices strictly in accordance with the Terms and Conditions. The User may, in no event, use the Application or the Services in any commercial matter.  
      </p>
      <p className="text-sm mb-4 leading-relaxed">
       7.3 You warrant that if you upload anything to the Services, such as profile pictures or other content, you have the right to use such information and that such content does not infringe the rights of any third party. You retain the intellectual property rights regarding the content posted by yourself. You acknowledge and accept that you, by making available and/or uploading content, automatically grant to PM a free, world-wide, irrevocable, sub-licensable and transferable license to render public and otherwise use this content to the extent this is required in the context of the providing of the Services.    
      </p>

      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">8 limitation of liablity  </h2>
      <p className="text-sm mb-4 leading-relaxed">
       8.1 PM takes reasonable care in providing the Services, but the Services, including the Platform, may contain inaccuracies or errors. PM does not guarantee and is not liable for any such inaccuracies or errors in the Platform expect as specifically stated in these Terms and Conditions. To the extent permitted by law, PM cannot be held responsible for any such errors, interruptions or any missing information.  
      </p><p className="text-sm mb-4 leading-relaxed">
       8.2 Furthermore, PM does not warrant that the Platform and Services will be available at all times. PM may experience hardware, software, or other problems or need to perform maintenance related to the Platform, resulting in interruptions, delays, or errors. PM is not liable whatsoever for any loss, damage or inconvenience caused by the User’s inability to access or use the Platform or Services during any downtime or discontinuance of the Platform or Services.
      </p>
      <p className="text-sm mb-4 leading-relaxed">
       8.3 The Platform may contain links to external websites within the Platform which are provided and administered by others than PM. Such links might be provided for convenience or informational purposes. You should be aware that if you follow such links then you are leaving the Platform. The occurrence of such links does not mean that PM has approved the content or accepted liability for the content displayed on such websites, nor does it mean that PM is in any way affiliated with the website provider. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
       8.4 PM is not liable and is not obliged to compensate for delays, cancellations, overbooking, strikes, force majeure or other circumstances outside the scope of PM’s immediate control. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
       8.5 To the extent permitted by applicable mandatory law, PM shall not be liable for any indirect damage or any consequential damage resulting from the use of the Services, or delay or failure to use the Platform, or for the provided information through the Services or for the software.  
      </p>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">9 CHANGES TO THE Terms and conditions </h2>
      <p className="text-sm mb-4 leading-relaxed">
       9.1 These Terms and Conditions apply until further notice and the User agrees and undertakes to respect and comply with the Terms and Conditions applicable at any given time. PM may, from time to time, change these Terms and Conditions. In case of any material changes to these Terms and Conditions, PM will notify you of such changes via the Application or by email. To the extent permitted by applicable law, by continuing to use the Services after the change comes into effect, you will be considered having accepted the relevant changes to these Terms and Conditions. 
      </p>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">10 Applicable law  </h2>
      <p className="text-sm mb-4 leading-relaxed">
       10.1 The Terms and Conditions shall be governed by Swedish law. The Terms and Conditions do not limit any mandatory consumer laws or regulations. 
      </p>
      <p className="text-sm mb-4 leading-relaxed">
       10.2 If there is a dispute, controversy or claim arising out of or in connection with the Terms and Conditions, or the breach, termination or invalidity hereof, the User and PM shall primarily try to resolve the dispute by reaching an agreement. In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us by email at <a
          href="mailto:support@padelmates.se"
          className="text-blue-500 hover:text-blue-700 font-semibold"
        >
          support@padelmates.se
        </a>   
      </p>
      <p className="text-sm mb-4 leading-relaxed">
       10.3 In respect of disputes concerning consumer rights the User can, in many cases, submit a report to the Swedish National Board for Consumer Disputes (<a className="text-blue-500 underline" href="www.arn.se">www.arn.se</a>). If the User lives in a country in the EU/EEA, the User also has the right to use the European Online Dispute Resolution platform provided by the European Commission to settle any disputes. The European Online Dispute Resolution platform is available here: <a className="text-blue-500 underline" href="ec.europa.eu/consumers/odr">ec.europa.eu/consumers/odr</a> 
      </p>
      <h2 className="text-xl font-semibold mt-8 mb-4 uppercase">11 Contact information  </h2>
      <p className="text-sm mb-4 leading-relaxed">
      Email: <a
          href="mailto:support@padelmates.se"
          className="text-blue-500 hover:text-blue-700 font-semibold"
        >
          support@padelmates.se
        </a>
      </p>
      <p className="text-sm mb-4 leading-relaxed">
      Postal address: Fågelviksvägen 9, 145 53 Norsborg 
      </p>
    </div>
                {/* <footer className="TermsPrivacy">
        <div className="TermsPrivacyDiv">
          <NavLink className="TermsPrivacyNav" to="/TC">
            Terms of Use
          </NavLink>
          <NavLink className="TermsPrivacyNav" to="/Privacy-Policy">
            Privacy Policy
          </NavLink>
          <NavLink className="TermsPrivacyNav" to="/">
            Home - Player
          </NavLink>
          <NavLink className="TermsPrivacyNav" to="/club">
            Home - Club
          </NavLink>
        </div>
        <h3 className="TermsPrivacyCopy">Copyright © All Rights Reserved</h3>
      </footer> */}
            </div>
        </>
    );
};
