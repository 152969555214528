import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import BookSlot from "./BookSlot";
import moment, { Moment } from "moment-timezone";
import {
    convertUTC,
    getAllAvailableHours,
    getAllHours,
    getAvailableSlots,
    getCustomizedSelectableHours,
    getSelectableHours
} from "../../Api/api";
import { useBookingAtom } from "../../Utilities/clubStore";
import { useTranslation } from "react-i18next";
import SelectDate from "./SelectDate";

interface ClubProfileBookCentreProps {
    clubData: any;
    dayCount?: number;
    setIsSlot?: Dispatch<SetStateAction<boolean>>;
}

const ClubProfileBookCentre: React.FC<ClubProfileBookCentreProps> = ({
    clubData,
    dayCount = 7,
    setIsSlot,
}) => {
    const [selectedDate, setSelectedDate] = useState<Moment>(
        moment.tz(moment(), clubData?.timezone)
    );
    const [selectedTime, setSelectedTime] = useState<string>("");
    const [selectedButton, setSelectedButton] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [slots, setSlots] = useState<any>(null);
    const [availableSlots, setAvailableSlots] = useState<string[]>([]);
    const [slotsWithHours, setSlotsWithHours] = useState<any>([]);
    const [allSlots, setAllSlots] = useState<any[]>([]);
    const [originalCourts, setOriginalCourts] = useState<any[]>([]);
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    const { t } = useTranslation();
    const [apiCall, setApiCall] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const formattedDate = selectedDate?.format("YYYY-MM-DD");
        localStorage.setItem("selectedDate", formattedDate);
        const clubId: string = clubData?._id || "";
        if (selectedDate && clubId && clubData && !apiCall) {
            setApiCall(true);
            setAvailableSlots([]);
            // Reset so we don't show courts when there is not a correctly select slot

            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const today = String(currentDate.getDate()).padStart(2, "0");

            let todayDate = `${year}-${month}-${today}`;
            const isSameDay = todayDate === formattedDate;
            const currentHour = isSameDay
                ? moment()
                      .tz(clubData?.timezone)
                      .format("HH")
                : "00";
            getAvailableSlots(
                clubId,
                convertUTC(
                    formattedDate,
                    `${currentHour}:00`,
                    clubData?.timezone
                ),
                convertUTC(formattedDate, "23:59", clubData?.timezone)
            )
                .then((resp: any) => {
                    if (resp) {
                        setApiCall(false);
                        setAvailableSlots([]);
                        setSelectedTime("");
                        setSelectedButton("");

                        setSlots(resp);
                        setOriginalCourts(resp.originalCourts);
                        setAllSlots(resp.allSlots);
                        // const slotsWithHours = resp?.map((court: any) => ({
                        //     ...court,
                        //     available_slots: court.available_slots.map(
                        //         (slot: any) => ({
                        //             ...slot,
                        //             hours: getAllHours(slot, clubData?.timezone)
                        //         })
                        //     )
                        // }));

                        let newArray: string[] = [];
                        // get all available unique hours
                        resp.uniqueStartTimes.forEach(
                            (startTime: string | number | Date) => {
                                const data = moment(startTime)
                                    .tz(clubData?.timezone)
                                    .format("HH:mm");
                                const dataUTC = moment(startTime)
                                    .tz("UTC")
                                    .format("HH:mm");
                                newArray.push(data + "," + dataUTC);
                            }
                        );

                        // if (slotsWithHours && slotsWithHours.length) {
                        //     setSlotsWithHours(slotsWithHours);
                        //     const hours = getAllAvailableHours(slotsWithHours);

                        //     const customizedHours =
                        //         getCustomizedSelectableHours(
                        //             getSelectableHours(hours)
                        //         );

                        //     setAvailableSlots(customizedHours.flat());
                        //     setLoading(false);
                        // }

                        const customizedHours =
                            getCustomizedSelectableHours(newArray);

                        setAvailableSlots(customizedHours.flat());
                        setLoading(false);
                        console.log("THIS IS RIGHT");
                    } else {
                        setApiCall(false);
                        console.log("Arnab");
                        setSlotsWithHours([]);
                        setSlots([]);
                        setAvailableSlots([]);
                        setOriginalCourts([]);
                        setAllSlots([]);
                        setLoading(false);
                    }
                })
                .catch((e) => {
                    setApiCall(false);
                    console.log("ERROR THIS IS WRONG");
                    setSlotsWithHours([]);
                    setSlots([]);
                    setAvailableSlots([]);
                    setOriginalCourts([]);
                    setAllSlots([]);
                    setLoading(false);
                });
        }

        //setLoading(false);
    }, [selectedDate, clubData]);

    const maybeNoSlotsAvailableText = () => {
        if (loading) {
            return;
        } else if (availableSlots.length === 0) {
            return (
                <div id="profileNoSlotAvailableText" className="text-center w-full">
                    {t("Profile.noSlotsAvailable")}
                </div>
            );
        }
    };

    const bookingUnavailableText = () => {
        if (loading) {
            return;
        } else {
            return (
                <div className="text-center w-full mt-3">
                    {t("Profile.bookingUnavailable")}
                </div>
            );
        }
    };

    const getSlotValue = (slot: string, pre: boolean) => {
        const myArray = slot.split(",");
        if (pre) {
            return myArray[0];
        }
        return myArray[1];
    };

    const bookingWidget = () => {
        return (
            <>
                <SelectDate
                    dayCount={dayCount}
                    selectedDate={selectedDate}
                    timezone={clubData?.timezone}
                    loading={loading}
                    setSelectedDate={(newDate) => {
                        if (!loading) {
                            setAllSlots([]);
                            setSelectedDate(newDate);
                        }
                    }}
                    onDateClick={() => {}}
                />
                <div className="mt-9 flex flex-wrap gap-[10px] xl:px-4 items-center">
                    {loading ? (
                        <div className="text-center w-full">
                            {t("loadingSlots")}
                        </div>
                    ) : (
                        availableSlots?.map((slot: string, i: number) => (
                            <button
                                id={`slot-${i}`}
                                key={i}
                                className={
                                    selectedButton === getSlotValue(slot, true)
                                        ? "bg-secondary text-white text-[13px] border border-secondary rounded-full px-3 py-1 min-w-[63px] sm:min-h-[29px] flex items-center justify-center uppercase hover:opacity-80 transform duration-200"
                                        : "bg-white text-secondary text-[13px] border border-secondary rounded-full px-3 py-1 min-w-[63px] sm:min-h-[29px] flex items-center justify-center uppercase hover:bg-primary hover:text-secondary transform duration-200"
                                }
                                onClick={() => {
                                    if (
                                        selectedButton ===
                                        getSlotValue(slot, true)
                                    ) {
                                        setSelectedTime("");
                                        setSelectedButton("");
                                        setBookingAtom({
                                            ...bookingAtom,
                                            startTime: "",
                                            selectedSlot: ""
                                        });
                                    } else {
                                        setSelectedTime(slot);
                                        setSelectedButton(
                                            getSlotValue(slot, true)
                                        );
                                        setBookingAtom({
                                            ...bookingAtom,
                                            startTime: getSlotValue(slot, true),
                                            selectedSlot: getSlotValue(
                                                slot,
                                                true
                                            )
                                        });
                                    }
                                    setIsSlot && setIsSlot(true);
                                }}
                            >
                                {getSlotValue(slot, true)}
                            </button>
                        ))
                    )}
                    {maybeNoSlotsAvailableText()}
                </div>
                {selectedTime ? (
                    <div className="mt-8">
                        {/* <h2 className="text-xl font-medium mb-4 border-b border-b-secondary border-opacity-[0.15]">
                            {`Find your ideal ${selectedDate.format(
                                "dddd"
                            )} time slot on ${selectedDate.format(
                                "Do MMMM YYYY"
                            )}`}
                        </h2> */}

                        <div className="flex flex-col">
                            {allSlots.length ? (
                                <>
                                    <hr />
                                    <BookSlot
                                        date={selectedDate?.format(
                                            "DD-MM-YYYY"
                                        )}
                                        menuDivActive={!!selectedTime}
                                        day={selectedDate?.format("ddd")}
                                        jsonData={clubData}
                                        timezone={clubData?.timezone}
                                        time={selectedTime}
                                        allSlots={allSlots}
                                        originalCourts={originalCourts}
                                        setIsSlot={setIsSlot}
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <>
                        {availableSlots.length ? (
                            <div className="mt-12 font-medium text-center">
                                {t("Profile.selectAnySlot")}
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </>
        );
    };

    const maybeBookingWidget = () => {
        let mobile_booking = clubData?.permissions?.mobile_booking;
        if (mobile_booking === false) {
            return bookingUnavailableText();
        } else {
            return bookingWidget();
        }
    };

    return (
        <div className="bg-[#FBFBFB] p-4 sm:p-5 pb-16 rounded-2xl">
            <h2 className="text-2xl font-semibold text-center xl:px-5">
                {t("Profile.bookCentre")}
            </h2>
            {maybeBookingWidget()}
        </div>
    );
};

export default ClubProfileBookCentre;
