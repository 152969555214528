import React from "react";

export const BadgeImage = ({
  width = "29",
  height = "25",
  fill = "#F5FF7C",
  division = 1,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 29 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_62_4504)">
          <path
            d="M21.388 0.622101H7.60987L0.720783 12.5L7.60987 24.3779H21.388L28.2771 12.5L21.388 0.622101Z"
            fill="#000B29"
          />
          <path
            d="M21.7484 25H7.24948L0 12.5L7.24948 0H21.7484L28.9979 12.5L21.7484 25ZM7.97234 23.7558H21.0297L27.5584 12.5L21.0277 1.24419H7.97026L1.44365 12.5L7.97234 23.7558Z"
            fill={fill}
          />
          {division === 2 && (
            <path
              d="M21.4568 12.9209L14.499 10.6399L7.54321 12.9209V9.91206L14.499 7.63104L21.4568 9.91206V12.9209Z"
              fill={fill}
            />
          )}
          <path
            d="M21.4568 17.1616L14.499 14.8805L7.54321 17.1616V14.1527L14.499 11.8717L21.4568 14.1527V17.1616Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_62_4504">
            <rect width="29" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
