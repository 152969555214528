import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "./App.css";
import "./index.css";
import AuthProvider from "./Components/AuthProvider";
import HomepageClub from "./Pages/HomepageClub";
import HomepagePlayer from "./Pages/HomepagePlayer";
import SignUpForm from "./Pages/SignUp";
import SignUpExistingCreatedUserForm from "./Pages/SignUpExistingCreatedUser";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { default as SignIn } from "./Pages/SignIn";
import BookingPage from "./Pages/BookingPage";
import PaymentPage from "./Pages/PaymentPage";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../src/firebase.js";
import { Footer } from "./Components/shared/Footer";
import { ReactNotifications } from "react-notifications-component";
import ClubProfile from "./Pages/ClubProfile";
import Profile from "./Pages/Profile";
import Activities from "./Pages/Activities";
import AccountSettings from "./Pages/AccountSettings";
import FIndCentresPage from "./Pages/FIndCentresPage";
import { HelpCenter } from "./Pages/HelpCenter";
import ForgotPassword from "./Pages/ForgotPassword";
import ScrollToTop from "./Components/ScrollToTop";
import Onboarding from "./Pages/Onboarding";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./Pages/NotFound";
import { Navbar } from "./Components/shared/Navbar/Navbar";
import Career from "./Pages/Career";
import CareerProfile from "./Pages/CareerProfile";
import DownloadTheApp from "./Pages/DownloadTheApp";

const App: React.FC = () => {
    return (
        <Router>
            <AppContent />
        </Router>
    );
};

const AppContent = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [displayName, setDisplayName] = useState("");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoggedIn(true);
                setDisplayName(user?.displayName as string);
            } else {
                setLoggedIn(false);
                setDisplayName("");
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <>
            <HelmetProvider>
                <ScrollToTop />
                <ReactNotifications />
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    declineButtonText="Decline"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                    onAccept={() => {}}
                    onDecline={() => {}}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
                <AuthProvider>
                    {/* {location.pathname !== "/otp-verification" && ( */}
                    <Navbar isLoggedIn={loggedIn} displayName={displayName} />
                    {/* )} */}
                    <div className="mt-[80px]">
                        <Routes>
                            <Route
                                path="/payment-history"
                                element={<PaymentPage />}
                            />
                            <Route path="/sign-in" element={<SignIn />} />
                            <Route path="/sign-up" element={<SignUpForm />} />
                            <Route
                                path="/sign-up-existing-created-user"
                                element={<SignUpExistingCreatedUserForm />}
                            />
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route path="/booking" element={<BookingPage />} />
                            <Route
                                path="/find-centres"
                                element={<FIndCentresPage />}
                            />
                            <Route
                                path="/en/privacy-policy-1"
                                element={<PrivacyPolicy />}
                            />
                            <Route path="/club" element={<HomepageClub />} />
                            <Route path="/" element={<HomepagePlayer />} />
                            <Route path="/club/:id" element={<ClubProfile />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route
                                path="/activities"
                                element={<Activities />}
                            />
                            <Route
                                path="/career"
                                element={<Career />}
                            />
                            <Route path="/career/:id" element={<CareerProfile />} />
                            <Route
                                path="/account-settings"
                                element={<AccountSettings />}
                            />
                            <Route
                                path="/onboarding"
                                element={<Onboarding />}
                            />
                            <Route
                                path="/help-center"
                                element={<HelpCenter />}
                            />

                            <Route path="*" element={<NotFound />} />
                            <Route path="/downloadtheapp" element={<DownloadTheApp />} />
                        </Routes>
                    </div>
                    <Footer />
                </AuthProvider>
            </HelmetProvider>
        </>
    );
};

export default App;
