import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
    User
} from "firebase/auth";
import { auth } from "../firebase";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import {
    callSetPasswordAndActivateAccount,
    getLiteProfileById
} from "../Api/api";
import Spinner from "../Components/clubProfile/Spinner";
import { handleLoggedInUser } from "./SignIn";

const SignUpExistingCreatedUserForm: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams);

    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState("Loading email...");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("Loading name...");
    const [errorMessage, setErrorMessage] = useState("");

    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const onSubmit = async (e: React.FormEvent) => {
        setErrorMessage("");
        e.preventDefault();

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError(`${t("Auth.passwordRequired")}`);
        } else if (password.length < 6) {
            setPasswordError(`${t("Auth.passwordLength")}`);
        } else {
            setPasswordError("");
        }

        if (password && !passwordError) {
            try {
                //   await updateProfile(user, { displayName: fullName });
                // handleLoggedInUser(userCredential, navigate, queryParams);

                setLoading(true);
                setErrorMessage("Activating... Please hold...");
                await callSetPasswordAndActivateAccount(
                    queryParams?.userId,
                    password
                ).then((data) => {
                    console.log(data);

                    if (data) {
                        signInWithEmailAndPassword(auth, email, password)
                            .then((userCredential) => {
                                handleLoggedInUser(userCredential, null)
                                navigate(
                                    "/onboarding?show_download_modal=true"
                                );
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                console.log(errorCode, errorMessage);
                                setErrorMessage(error.message);
                            });
                    }
                });
            } catch (error: any) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setErrorMessage(error.message);
            }
        } else {
            setErrorMessage(`${t("Auth.fillInput")}`);
        }
    };

    useEffect(() => {
        getLiteProfileById(queryParams?.userId)
            .then((data) => {
                if (data.user.name) {
                    setName(data.user.name);
                    setEmail(data.user.email);
                    setLoading(false);
                } else {
                    setErrorMessage("Link is expired & cannot be used anymore");
                    setLoading(false);
                }
            })
            .catch((error) => {
                setErrorMessage("Link is expired & cannot be used anymore");
                setLoading(false);
            });
    }, []);
    return (
        <>
            <Helmet>
                <title>Activate Account | Padel Mates</title>
            </Helmet>
            <div
                className={`bg-[url("../public/images/signin-bg.png")] bg-no-repeat bg-left-bottom`}
            >
                <div className="max-w-[511px] mx-auto w-full flex flex-col justify-center items-center px-4 sm:px-5 py-10 lg:py-24 ">
                    <div className="flex flex-col justify-center items-center w-full lg:mt-6 2xl:mt-12">
                        <h1 className="text-2xl md:text-3xl xl:text-4xl 2xl:text-[42px] text-center font-bold">
                            {t("Auth.activateAccount")}
                        </h1>
                    </div>
                    {errorMessage && (
                        <label className="text-red-500 text-center mt-5">
                            {errorMessage}
                        </label>
                    )}
                    {!loading && !errorMessage && (
                        <>
                            <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                                <div className="w-full max-w-[400px] mx-auto">
                                    {t("Auth.name")}: {name}
                                </div>
                                <div className="w-full max-w-[400px] mx-auto">
                                    {t("Auth.emailAddress")}: {email}
                                </div>

                                <div className="w-full max-w-[400px] mx-auto">
                                    <input
                                        type="password"
                                        value={password}
                                        onChange={(e) => {
                                            const newPassword =
                                                e.currentTarget.value;
                                            setPassword(newPassword);
                                            if (newPassword) {
                                                if (newPassword.length < 8) {
                                                    setPasswordError(
                                                        `${t(
                                                            "Auth.passwordLength"
                                                        )}`
                                                    );
                                                } else {
                                                    setPasswordError("");
                                                }
                                            } else {
                                                setPasswordError(
                                                    `${t(
                                                        "Auth.passwordRequired"
                                                    )}`
                                                );
                                            }
                                        }}
                                        required
                                        placeholder={t("Auth.password")}
                                        className={`${
                                            passwordError
                                                ? "border-red-500 outline-red-500"
                                                : "border-[#000b2966] outline-primary"
                                        } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                                    />
                                    {passwordError && (
                                        <p className="text-red-500 mt-1 ml-2 text-xs">
                                            {passwordError}
                                        </p>
                                    )}
                                </div>
                                <label className="text-[15px] max-w-[460px] mx-auto -mt-3 ml-9 text-[#222222CC]">
                                    {t("Auth.passwordInfo")}
                                </label>
                                <input
                                    type="submit"
                                    onClick={onSubmit}
                                    value={t("Auth.activateAccount")}
                                    className="bg-secondary font-semibold text-white rounded-full w-full max-w-[400px] mx-auto shadow-md h-[56px] flex items-center justify-center py-1 px-4 mt-5 cursor-pointer hover:bg-green-600 xl:hover:bg-primary hover:text-secondary transform duration-200 hover:scale-105"
                                />
                            </form>
                        </>
                    )}
                    {loading && (
                        <>
                            <Spinner />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SignUpExistingCreatedUserForm;
