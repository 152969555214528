import { levelSystemMapping } from "./constant";

export const checkNullAndUndefined = (value:any) => {
    if (value === undefined || value === null) {
      return true;
    }
    return false;
  };

export const getFirstLettersFromWords = function (name: string) {
    let names = name.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};
// getFirstLettersFromWords('Faridul Hassan') ---> "FH"

export const generateGameType = (title: string) => {
    const lowerCaseTitle = title?.toLowerCase();
    switch (true) {
        case lowerCaseTitle?.includes("padel"):
            return "/images/padelGame.png";
        case lowerCaseTitle?.includes("tennis"):
            return "/images/tennisGame.png";
        case lowerCaseTitle?.includes("badminton"):
            return "/images/badmintonGame.png";
        case lowerCaseTitle?.includes("squash"):
            return "/images/squashGame.png";
        case lowerCaseTitle?.includes("pickleball"):
            return "/images/pickleballGame.png";
        default:
            return "/images/padelGame.png";
    }
};

export const cancellationTime = (default_cancellation_time_booking: any) => {
    if (default_cancellation_time_booking) {
        try {
        const time = default_cancellation_time_booking / (1000 * 60 * 60);
        const isInteger = Number.isInteger(time);
        return isInteger ? time : time.toFixed(1);
        } catch (error) {
        return 6;
        }
    }
    return 6;
};

export const getSystemValue = (gameType = "padel",levelsystem:any) => {
    const systemForGameType = levelsystem.find(
      (item:any) => item?.sportType?.toLowerCase() === gameType?.toLowerCase()
    );
    // @ts-ignore
    return systemForGameType ? levelSystemMapping[systemForGameType?.system]: "Not Found";
  };

  export const correctLevelWithPoints = (item:any) => {
    const points = item?.points;
    const level = item?.level?.im;
  
    if (!checkNullAndUndefined(points)) {
      if(points>0){
        return (parseFloat(points) / 100).toFixed(2);
      }else{
        return "1.00";
      }
    }
  
    if (!checkNullAndUndefined(level)) {
      return level.toFixed(2);
    }
  
    return "1.00";
  };