interface LevelDescription {
    level: string;
    description: string;
}

interface SkillLevels {
    [key: string]: {
        [key: number]: LevelDescription;
    };
}

export const levels: SkillLevels = {
    padel: {
        1.0: {
            level: "Newcomer",
            description: "Never held a racket."
        },
        1.5: {
            level: "Newcomer",
            description: "Never held a racket."
        },
        2.0: {
            level: "Beginner",
            description: "Learning the basics of padel"
        },
        2.5: {
            level: "Beginner",
            description: "Learning the basics of padel"
        },
        3.0: {
            level: "Beginner advanced",
            description:
                "Knows the basics of the game but is uncomfortable with ground strokes and having trouble with bounces of the wall."
        },
        3.5: {
            level: "Beginner advanced",
            description:
                "Knows the basics of the game but is uncomfortable with ground strokes and having trouble with bounces of the wall."
        },
        4.0: {
            level: "Recreational player",
            description:
                "Played for a while and know the basics well and can pick up the pace of the game but no consistency."
        },
        4.5: {
            level: "Recreational player",
            description:
                "Played for a while and know the basics well and can pick up the pace of the game but no consistency."
        },
        5.0: {
            level: "Average",
            description:
                "Has played for a while and knows the sport. You get the ball over the net with reasonable force. Serve and the other parts are good but not always consistent."
        },
        5.5: {
            level: "Average",
            description:
                "Has played for a while and knows the sport. You get the ball over the net with reasonable force. Serve and the other parts are good but not always consistent."
        },
        6.0: {
            level: "Average advanced",
            description:
                "Like level 5 but with better smash. Also more consistent and more in control."
        },
        6.5: {
            level: "Average advanced",
            description:
                "Like level 5 but with better smash. Also more consistent and more in control."
        },
        7.0: {
            level: "Experienced",
            description: "Can defend and turn the game against the opponent."
        },
        7.5: {
            level: "Experienced",
            description: "Can defend and turn the game against the opponent."
        },
        8.0: {
            level: "Skilled",
            description:
                "You master all the technical and tactical parts of the game."
        },
        8.5: {
            level: "Skilled",
            description:
                "You master all the technical and tactical parts of the game."
        },
        9.0: {
            level: "Expert",
            description:
                "Belongs to the absolute best and masters the game at the highest level."
        },
        9.5: {
            level: "Expert",
            description:
                "Belongs to the absolute best and masters the game at the highest level."
        },
        10.0: {
            level: "Professional",
            description:
                "Can qualify and participate in professional competitions"
        }
    },

    badminton: {
        1: {
            level: "New Player",
            description:
                "Has no or limited badminton experience. Primarily needs to work on keeping the shuttle in play, with no knowledge of strokes, different shots, or service. Requires guidance to learn the basics and struggles to apply them during play. Lacks understanding of court coverage."
        },
        2: {
            level: "Beginner1",
            description:
                "Requires further on-court experience. Beginner-1 is familiar with basics, able to swing overhead and underarm to strike the shuttle. Has obvious stroke weaknesses (typically the backhand/service/overhead clear). Lacks control and unable to intentionally put pace (speed) on the shuttle. Weak court coverage in singles and doubles."
        },
        3: {
            level: "Beginner2",
            description:
                "Strokes need developing and needs to learn other basic shots (typically consistent striking with backhand grip, overhead smash/drop shots). Can sustain a slow rally of slow pace. Has integrated some basic movements consistently during play, such as overhead shots."
        },
        4: {
            level: "Beginner Intermediate",
            description:
                "Fairly consistent when hitting medium-paced shots (limited pace up to a drive). Able to execute basic shots (typically includes clear, net underarm lift on fore and backhand, and net kills) and sustain rallies. Execution typically fails when aiming to demonstrate more control and/or power. Able to cover court, but not efficiently and struggles with more difficult shots (backhand, overhead smash, defensive block)."
        },
        5: {
            level: "Intermediate",
            description:
                "Has a small repertoire of shots and able to play them intentionally with directional control and medium pace. Typically able to execute all basic shots (not always) and some advanced shots. Smashes lack power, backhands can be weak, and further development is needed for shot consistency and aim. Able to cover court using basic principles for singles and doubles, can retrieve most/all shuttles at a medium pace. Has a thorough understanding of footwork."
        },
        6: {
            level: "Intermediate-Advanced",
            description:
                "Demonstrates improved stroke reliability and ability to play the majority of shots well (typically able to execute powerful, steep smashes and backhand clears). Able to control the shuttle at a fast pace. Possesses good footwork and understands effective principles for court positioning in singles and doubles. Consistency can vary and may lose points on occasion due to unforced errors."
        },
        7: {
            level: "Advanced",
            description:
                "Top players – able to demonstrate mastery of all strokes and shots, with efficient footwork. Advanced sense of positioning in singles and doubles. Exhibits a high level of consistency and makes few unforced errors."
        }
    },

    pickleball: {
        1.0: {
            level: "New Player",
            description:
                "You are just starting to play pickleball and you are just starting to understand the rules of pickleball."
        },
        2.0: {
            level: "Beginner1",
            description:
                "You are just starting to play pickleball and you are just starting to understand the rules of pickleball."
        },
        2.5: {
            level: "Beginner2",
            description:
                "You have limited experience playing pickleball, you can keep a short rally on the pickleball court, and you have a basic understanding of the rules of pickleball (particularly, if you can keep the score properly)."
        },
        3.0: {
            level: "Beginner Intermediate",
            description:
                "You can generally hit forehand drives, serves, and returns with medium pace, as well as dinks, but generally lack consistency and control (for instance, serves and returns are not consistent and lack depth); and (ii) you understand the basic strategy and rules of pickleball, including that you can keep the score properly."
        },
        3.5: {
            level: "Intermediate",
            description:
                "You can hit drives, serves, and returns with pace (including developing backhand shots), as well as dinks and drop shots, and are developing some consistency and control (including consistently hitting serves and returns in play); and (ii) you understand the basic strategy and rules of pickleball, you are starting to vary your shots between the hard and soft game, and you are moving quickly to the Non-Volley Zone line when you have the opportunity."
        },
        4.0: {
            level: "Intermediate1",
            description:
                "You can generally hit both forehand and backhand drives, serves, and returns with pace, as well as dinks, drop shots, and volleys (including block volleys) of different speeds, and have consistency and control; and you understand the strategy and rules of pickleball (including stacking), you have a moderate number of unforced errors, and you understand how to attack your opponents’ weaknesses on the pickleball court."
        },
        4.5: {
            level: "Intermediate2",
            description:
                "Give yourself a pickleball self-rating of 4.5 if (i) you can generally hit both forehand and backhand drives, serves, returns, dinks, drop shots, and volleys with pace, spin, depth, direction, consistency and control; and (ii) you understand the strategy and rules of pickleball (including stacking), you have a limited number of unforced errors, and you change your game play to attack your opponents’ weaknesses on the pickleball court."
        },
        5.0: {
            level: "Intermediate2",
            description:
                "You have mastered both forehand and backhand drives, serves, and returns with pace, as well as dinks, drop shots, and volleys of different speeds, and have consistency and control; and you have mastered the strategy and rules of pickleball (including stacking), you rarely make unforced errors, and you attack your opponents’ weaknesses on the pickleball court."
        },
        5.5: {
            level: "Intermediate-Advanced",
            description:
                "You have mastered all shots and strategy on the pickleball court; and you are consistently winning at the highest level, meaning that you are winning against the best professional pickleball players at the tournament level."
        },
        6.0: {
            level: "Advanced",
            description:
                "You have mastered all shots and strategy on the pickleball court; and you are consistently winning at the highest level, meaning that you are winning against the best professional pickleball players at the tournament level."
        }
    },

    squash: {
        2.5: {
            level: "New Player",
            description:
                "Runs hard. Cannot consistently control the middle of the court. Form developing. Can handle moderate-paced shots. Still has grip and preparation problems. Can hit the serve/cannot return deep with consistency."
        },
        3.0: {
            level: "Beginner1",
            description:
                "Good consistency and variety on moderate shots. Over hits under pressure. On the backhand, frequently prepared, starting to hit for depth. Tends to run hard and hit hard. Tries to drop only on easy shots."
        },
        3.5: {
            level: "Beginner2",
            description:
                "The variety of moderate shots. Over hits under pressure. On the backhand, frequently prepared, starting to hit for depth. Starting to hit for power. Can retrieve in the front and back corners. Can hit the cross-court and down the rail for power. Can lob off both sides but without much touch."
        },
        4.0: {
            level: "Beginner Intermediate",
            description:
                "Physical makeup now determines playing style. The big player powers the ball to the corners and tries drops. Smaller, quicker, fitter player plays retrieving game with counter punch shots. Can drive the ball for width and power. Starting to hit drops from the mid and front court. Hits the backhand hard but can drop. Misses drop under pressure."
        },
        4.5: {
            level: "Intermediate",
            description:
                "Consistently keeps the ball in play against equal opponents. Controls T against the other players. Forehand is a strong shot with power and depth. On the backhand, controls depth and power but still occasionally breaks down under pressure. Would win a 3.5 or 4.0 tournament."
        },
        5.0: {
            level: "Intermediate-Advanced",
            description:
                "Knows that rallies must start with drives for depth. Tends to play for a shot a little early at times, but can run down balls if the shot sets the opponent up to take control of the T. Has purpose with each swing. Drives the ball and boasts from the same spot. Can throw up a good lob from the defensive position. Would win a 4.5 tournament. Could win a match or two in the 5.5."
        },
        5.5: {
            level: "Advanced",
            description:
                "Plays patiently to the opening. Will still rush an occasional shot but patience and shot quality separate the 5.5 player from the 5.0. Is great practice for any level player. Would win every 4.5 tournaments. Might get upset in a 5.0 but would win these 80% of the time. Gives the pros a reasonable match."
        }
    },

    tennis: {
        1.0: {
            level: "New Player",
            description: "Beginner."
        },
        1.5: {
            level: "Motivated",
            description:
                "You have limited experience and is still working primarily on getting the ball into play."
        },
        2.0: {
            level: "Beginner1",
            description:
                "This player needs on-court experience. you have obvious stroke weaknesses but are familiar with basic positions for singles and doubles play."
        },
        2.5: {
            level: "Beginner2",
            description:
                "You are learning to judge where the ball is going although court coverage is weak. Can sustain a short rally of slow pace with other players of the same ability."
        },
        3.0: {
            level: "Beginner Intermediate",
            description:
                "You are fairly consistent when hitting medium-paced shots, but is not comfortable with all strokes and lack execution when trying for directional control, depth, or power. The most common doubles formation is one-up, one-back."
        },
        3.5: {
            level: "Intermediate",
            description:
                "You have achieved improved stroke dependability with directional control on moderate shots but still lack depth and variety. This player exhibits more aggressive net play, has improved court coverage, and is developing teamwork in doubles."
        },
        4.0: {
            level: "Intermediate1",
            description:
                "You have dependable strokes, including directional control and depth on both forehand and backhand sides on moderate shots, plus the ability to use lobs, overheads, approach shots, and volleys with some success. This player occasionally forces errors when serving. Rallies may be lost due to impatience. Teamwork in doubles is evident."
        },
        4.5: {
            level: "Intermediate2",
            description:
                "You have begun to master the use of power and spins and are beginning to handle pace, have sound footwork, can control the depth of shots, and are beginning to vary game plan according to opponents. This player can hit the first serves with power and accuracy and place the second serve. This player tends to overhit on difficult shots. Aggressive net play is common in doubles."
        },
        5.0: {
            level: "Intermediate2",
            description:
                "You have good shot anticipation and frequently have an outstanding shot or attribute around which a game may be structured. This player can regularly hit winners or force errors off of short balls and can put away volleys, can successfully execute lobs, drop shots, half volleys, and overhead smashes and has good depth and spin on most 2nd serves."
        },
        5.5: {
            level: "Intermediate-Advanced",
            description:
                "You have developed power and/or consistency as a major weapon. This player can vary strategies and styles of play in a competitive situation and hits dependable shots in a stressful situation."
        },
        6.0: {
            level: "Advanced",
            description:
                "You have typically had intensive training for national tournament competitions at the junior and collegiate levels and have obtained a sectional and/or national ranking."
        },
        7.0: {
            level: "World Class",
            description:
                "You are a world-class player. You have had extensive training for national tournaments at the junior and collegiate levels and have obtained a sectional and/or national ranking."
        }
    }
};

const sportsMap = new Map();
sportsMap.set("pickleball", levels.pickleball);
sportsMap.set("squash", levels.squash);
sportsMap.set("tennis", levels.tennis);
sportsMap.set("badminton", levels.badminton);
sportsMap.set("padel", levels.padel);

/**
 * Retrieves the information for a specific level in a sport.
 *
 * @param {string} sportType - The type of sport.
 * @param {number} level - The level of the sport.
 * @returns {object|string} - The information for the specified level as an object, or an error message as a string.
 * @example
 * Retrieve information for tennis level 1
 * const tennisLevel1 = getLevelInfo('tennis', 1);
 * console.log(tennisLevel1);
 * Output:
 * {
 *   level: "New Player",
 *   description: "You have limited experience and are still working primarily on getting the ball into play.",
 *   image: "1",
 *   descriptionKey: "levels_tennis_description_1_5",
 *   levelKey: "levels_tennis_level_1"
 * }
 */
const getLevelInfo = (sportType: string, level: number) => {
    if (sportsMap.has(sportType)) {
        const sportsObj = sportsMap.get(sportType);
        const levelKey = (Math.round(level * 2) / 2).toString();
        if (sportsObj.hasOwnProperty(levelKey)) {
            const levelInfo = sportsObj[levelKey];
            const levelKeyUnderscore = levelKey.replace(".", "_");
            const imageKey = levelKeyUnderscore; // Replace dot with underscore
            levelInfo.image = `${imageKey}`;
            levelInfo.descriptionKey = `levels_${sportType}_description_${levelKeyUnderscore}`;
            levelInfo.levelKey = `levels_${sportType}_level_${levelKeyUnderscore}`;
            return levelInfo;
        } else {
            return "Invalid level";
        }
    } else {
        return "Invalid sport type";
    }
};

/**
 * Retrieves an array of available levels for a specific sport type.
 *
 * @param {string} sportType - The type of sport.
 * @returns {number[]} - An array of available levels for the specified sport type, sorted in ascending order.
 *
 * @example
 * // Retrieve levels for tennis
 * const tennisLevels = getLevelsForSportType('tennis');
 * console.log(tennisLevels);
 *
 * Output: [1, 2, 3, 4, 5]
 */
const getLevelsForSportType = (sportType: string) => {
    if (sportsMap.has(sportType)) {
        const sportsObj = sportsMap.get(sportType);

        // Retrieve the level keys from the sports object and sort them numerically
        return Object.getOwnPropertyNames(sportsObj)
            .sort((a, b) => parseFloat(a) - parseFloat(b))
            .map((value) => parseFloat(value));
    }
    console.error("Could not find sport when trying to get first level");
    return [];
};

export { sportsMap, getLevelInfo, getLevelsForSportType };
