import React, { useEffect, useState } from "react";
import ClubProfileMain from "../Components/clubProfile/ClubProfileMain";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { getClubProfileData } from "../Api/api";
import { useParams } from "react-router";

const ClubProfile = () => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        getCubProfileData(id);
    }, []);

    const [clubData, setClubData] = useState<any>(null);
    const { t } = useTranslation();
    const getCubProfileData = async (clubId: any) => {
        try {
            getClubProfileData(clubId).then((resp: any) => {
                if (resp?.status === 200) {
                    if (!resp.data._id && resp.data.club_id) {
                        resp.data._id = resp.data.club_id;
                    }
                    setClubData(resp.data);
                    // console.log("club ka data", resp.data);
                    // console.log("image ka data", resp.data?.photo_url);
                }
            });
        } catch (error: any) {
            console.log(
                "🚀 ~ file: ClubProfileMain.tsx:92 ~ getCubProfileData ~ error:",
                error
            );
        }
    };

    return (
        <div>
            <Helmet>
                <title>
                    {`${clubData?.name} - ${t("Title.clubProfile")}`} | Padel
                    Mates
                </title>

                <meta
                    name="description"
                    content={`Join Club ${clubData?.name} to participate in exciting activities and events. Learn more about membership benefits and upcoming events.`}
                />

                <meta
                    name="keywords"
                    content="Club booking,Sports club,Training sessions,Match booking,Sports training,Club training,Sports matches,Athletic training,Sports events,Training programs"
                ></meta>

                <meta name="robots" content="index, follow" />
                <link
                    rel="canonical"
                    href={`https://padelmates.se/club/${clubData?._id}`}
                />
                {/* Open Graph tags */}
                <meta
                    property="og:title"
                    content={`${clubData?.name} - Activities, Events`}
                />
                <meta
                    property="og:description"
                    content={clubData?.about_the_hall}
                />
                <meta property="og:image" content={clubData?.photo_url} />
                <meta
                    property="og:url"
                    content={`https://padelmates.se/club/${clubData?._id}`}
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={clubData?.name} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:article:author" content="Padel Mates" />

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={clubData?.name} />
                <meta name="twitter:creator" content="@padelmates" />
                <meta
                    name="twitter:title"
                    content={`${clubData?.name} - Activities, Events, and Membership`}
                />
                <meta
                    name="twitter:description"
                    content={`Join Club ${clubData?.name} to participate in exciting activities and events. Learn more about membership benefits and upcoming events.`}
                />
                <meta name="twitter:image" content={clubData?.photo_url} />
                <meta
                    name="twitter:image:alt"
                    content={`${clubData?.name} Club Logo`}
                />

                {/* Canonical tag */}
                <link
                    rel="canonical"
                    href={`https://padelmates.se/club/${clubData?._id}`}
                />

                {/* Schema.org Structured Data */}
                <script type="application/ld+json">
                    {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": ${clubData?.name},
              "url": ${`https://padelmates.se/club/${clubData?._id}`},
              "logo": ${clubData?.photo_url},
              "sameAs": [
                ${`https://www.facebook.com/${clubData?.name}`},
                ${`https://www.twitter.com/${clubData?.name}`}
              ],
              "description":${clubData?.about_the_hall},
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": ${clubData?.phone_number}, 
                "contactType": "Customer Service"
              }
            }
          `}
                </script>
            </Helmet>
            <ClubProfileMain />
        </div>
    );
};

export default ClubProfile;
