import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    createUserWithEmailAndPassword,
    updateProfile,
    User
} from "firebase/auth";
import { auth } from "../firebase";
import { handleLoggedInUser } from "./SignIn";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Loader from "../Components/ui/Loader";

const SignUpForm: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loader, setloader] = useState<boolean>(false);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    const onSubmit = async (e: React.FormEvent) => {
        setErrorMessage("");
        e.preventDefault();

        if (!firstName) {
            setFirstNameError(`${t("Auth.firstNameRequired")}`);
        } else {
            setFirstNameError("");
        }

        if (!lastName) {
            setLastNameError(`${t("Auth.lastNameRequired")}`);
        } else {
            setLastNameError("");
        }

        if (!email) {
            setEmailError(`${t("Auth.emailRequired")}`);
        } else if (!emailPattern.test(email)) {
            setEmailError(`${t("Auth.invalidEmail")}`);
        } else {
            setEmailError("");
        }

        if (!password) {
            setPasswordError(`${t("Auth.passwordRequired")}`);
        } else if (password.length < 6) {
            setPasswordError(`${t("Auth.passwordLength")}`);
        } else {
            setPasswordError("");
        }

        const fullName = `${firstName} ${lastName}`;

        if (
            firstName &&
            lastName &&
            email &&
            password &&
            !firstNameError &&
            !lastNameError &&
            !emailError &&
            !passwordError
        ) {
            setloader(true);
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                const user: User | null = userCredential.user;

                if (user) {
                    await updateProfile(user, { displayName: fullName });
                    handleLoggedInUser(userCredential, navigate, queryParams);
                }
            } catch (error: any) {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setErrorMessage(error.message);
            } finally {
                setloader(false);
            }
        } else {
            setErrorMessage(`${t("Auth.fillInput")}`);
        }
    };

    return (
        <>
            <Helmet>
                <title>Sign up | Padel Mates</title>
            </Helmet>
            <div
                className={`bg-[url("../public/images/signin-bg.png")] bg-no-repeat bg-left-bottom`}
            >
                <div className="max-w-[511px] mx-auto w-full flex flex-col justify-center items-center px-4 sm:px-5 py-10 lg:py-24 ">
                    <div className="flex flex-col justify-center items-center w-full lg:mt-6 2xl:mt-12">
                        <h1 className="text-2xl md:text-3xl xl:text-4xl 2xl:text-[42px] text-center font-bold">
                            {t("Auth.signUpTitle")}
                        </h1>
                    </div>

                    {errorMessage && (
                        <label className="text-red-500 text-center mt-5">
                            {errorMessage}
                        </label>
                    )}
                    {loader && <Loader />}
                    <form className="flex flex-col mt-10 w-full max-w-[460px] mx-auto gap-6">
                        <div className="w-full max-w-[400px] mx-auto">
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => {
                                    const newFirstName = e.currentTarget.value;
                                    setFirstName(newFirstName);
                                    newFirstName
                                        ? setFirstNameError("")
                                        : setFirstNameError(
                                              `${t("Auth.firstNameRequired")}`
                                          );
                                }}
                                required
                                placeholder={t("HomeClubConnectForm.FirstName")}
                                className={`${
                                    firstNameError
                                        ? "border-red-500 outline-red-500"
                                        : "border-[#000b2966] outline-primary"
                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                            />
                            {firstNameError && (
                                <p className="text-red-500 mt-1 ml-2 text-xs">
                                    {firstNameError}
                                </p>
                            )}
                        </div>
                        <div className="w-full max-w-[400px] mx-auto">
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => {
                                    const newLastName = e.currentTarget.value;
                                    setLastName(newLastName);
                                    newLastName
                                        ? setLastNameError("")
                                        : setLastNameError(
                                              `${t("Auth.lastNameRequired")}`
                                          );
                                }}
                                required
                                placeholder={t("HomeClubConnectForm.LastName")}
                                className={`${
                                    lastNameError
                                        ? "border-red-500 outline-red-500"
                                        : "border-[#000b2966] outline-primary"
                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                            />
                            {lastNameError && (
                                <p className="text-red-500 mt-1 ml-2 text-xs">
                                    {lastNameError}
                                </p>
                            )}
                        </div>
                        <div className="w-full max-w-[400px] mx-auto">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    const newEmail = e.currentTarget.value;
                                    setEmail(newEmail);
                                    if (newEmail) {
                                        if (!emailPattern.test(newEmail)) {
                                            setEmailError(
                                                `${t("Auth.invalidEmail")}`
                                            );
                                        } else {
                                            setEmailError("");
                                        }
                                    } else {
                                        setEmailError(
                                            `${t("Auth.emailRequired")}`
                                        );
                                    }
                                }}
                                required
                                placeholder={t("Auth.emailAddress")}
                                className={`${
                                    emailError
                                        ? "border-red-500 outline-red-500"
                                        : "border-[#000b2966] outline-primary"
                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                            />
                            {emailError && (
                                <p className="text-red-500 mt-1 ml-2 text-xs">
                                    {emailError}
                                </p>
                            )}
                        </div>
                        <div className="w-full max-w-[400px] mx-auto">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    const newPassword = e.currentTarget.value;
                                    setPassword(newPassword);
                                    if (newPassword) {
                                        if (newPassword.length < 6) {
                                            setPasswordError(
                                                `${t("Auth.passwordLength")}`
                                            );
                                        } else {
                                            setPasswordError("");
                                        }
                                    } else {
                                        setPasswordError(
                                            `${t("Auth.passwordRequired")}`
                                        );
                                    }
                                }}
                                required
                                placeholder={t("Auth.password")}
                                className={`${
                                    passwordError
                                        ? "border-red-500 outline-red-500"
                                        : "border-[#000b2966] outline-primary"
                                } w-full max-w-[400px] mx-auto border border-[#000b2966] h-[56px] flex items-center justify-center p-3 rounded-full text-lg`}
                            />
                            {passwordError && (
                                <p className="text-red-500 mt-1 ml-2 text-xs">
                                    {passwordError}
                                </p>
                            )}
                        </div>
                        <label className="text-[15px] max-w-[460px] mx-auto -mt-3 ml-9 text-[#222222CC]">
                            {t("Auth.passwordInfo")}
                        </label>
                        <input
                            type="submit"
                            onClick={onSubmit}
                            value={t("Auth.createAccount")}
                            className="bg-secondary font-semibold text-white rounded-full w-full max-w-[400px] mx-auto shadow-md h-[56px] flex items-center justify-center py-1 px-4 mt-5 cursor-pointer hover:bg-green-600 xl:hover:bg-primary hover:text-secondary transform duration-200 hover:scale-105"
                        />
                    </form>

                    <p className="flex text-lg justify-between items-center flex-wrap gap-2 mt-[35px]">
                        {t("Auth.alreadyHave")}
                        <Link
                            className="md:text-primary text-green-600 font-semibold hover:scale-105 transform duration-200"
                            to="/sign-in"
                        >
                            {t("login")}
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};

export default SignUpForm;
