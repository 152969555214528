import { Store } from "react-notifications-component";
import Axios from "./Axios";
import AxiosFastAPI from "./AxiosFastAPI";
import moment from "moment-timezone";
import { AxiosError } from "axios";

export interface ICourt {
    _id: string;
    name: string;
    // club_id: string;
    sport: {
        padel: boolean;
        tennis: boolean;
        badminton: boolean;
        squash: boolean;
        pickleball: boolean;
    };
    indoor: boolean;
    double: boolean;
}

export interface ISlot {
    slot_id: string;
    price: number;
    start_datetime: number;
    end_datetime: number;
}

export interface IBookingData {
    club_id: string;
    bookData: {
        total_price: number;
        discount: number;
        code: string;
        info: string;
        payment_status: string;
        court_ids: string[]; // [string, string]
        players: string[];
        start_datetime: number;
        stop_datetime: number;
    };
}

export interface IPaymentIntent {
    success: boolean;
    message: string;
    clientSecret: string;
    subscriptionId: string;
    paymentIntentId: string;
    transactionId: string;
    paymentStatus: string;
    chargeId: string;
    receiptUrl?: string;
}

export interface ICard {
    brand?: string;
    defaultPaymentMethod?: boolean;
    expireMonth?: number;
    expireYear?: number;
    last4Digit?: string;
    status?: string;
    stripePaymentMethodId?: string;
}

export interface IGame {
    _id: string;
    gameType: string;
    level: {
        name: string;
        im: number;
        des: string;
    };
    points: number;
    levelAssesment: number;
    sportsManship: number;
    userId: string;
    numOfFavCenters: number;
}

export interface IUser {
    _id: string;
    firebaseUID: string;
    name: string;
    email: string;
    onboarded: boolean;
    isExistingUser: boolean;
    isCoach: boolean;
    pro: boolean;
    lang: string;
    role: string;
    ageRange: string;
    gender: string;
    fcmToken: string;
    games: IGame[];
    punctuality: number;
    profilePic: string;
    count: number;
}

export interface ActivityType {
    activityType: "Book Court";
    _id: string;
    name: string;
    activity_records_id: string;
    created_by: string;
    start_datetime: number;
    stop_datetime: number;
    title: string;
    total_price: number;
    discount: number;
    court_info: ICourt[];
    players: IUser[];
    clubProfile: string;
    timezone: string;
    currency: string;
    amountPerPlayer: number;
    refundFee: number;
}

export const convertUTC = (
    selectedDate: any,
    time: string,
    timezone: string
): number => {
    const date = selectedDate,
        timeString = time,
        dateTime = `${date} ${timeString}`;

    return moment.tz(dateTime, timezone).utc().valueOf();
};

export const mergeSlots = (unMergedSlots: ISlot[] = []): ISlot[] => {
    if (!Array.isArray(unMergedSlots)) return [];

    const sortedSlots = unMergedSlots.sort(
        (a, b) => a.start_datetime - b.start_datetime
    );

    const mergedSlots: ISlot[] = [];
    sortedSlots.forEach((slot, index: number) => {
        if (index === 0) {
            mergedSlots.push(slot);
        } else {
            const prevMergedSlot = mergedSlots[mergedSlots.length - 1];

            const isContinues = moment(prevMergedSlot.end_datetime).isSame(
                slot.start_datetime
            );
            const isDuplicate =
                moment(slot.start_datetime).isSameOrAfter(
                    prevMergedSlot.start_datetime
                ) &&
                moment(slot.end_datetime).isSameOrBefore(
                    prevMergedSlot.end_datetime
                );

            if (!isContinues && !isDuplicate) {
                mergedSlots.push(slot);
            } else {
                prevMergedSlot.end_datetime = slot.end_datetime;
                mergedSlots.pop();
                mergedSlots.push(prevMergedSlot);
            }
        }
    });

    return mergedSlots;
};
// export const getAllHours = (
//     slot: ISlot,
//     timezone: string
// ): {
//     slotId: string;
//     price: number;
//     time: string;
// }[] => {
//     const start = moment.utc(slot.start_datetime).tz(timezone),
//         end = moment.utc(slot.end_datetime).tz(timezone);

//     const times = [];
//     for (; start.isBefore(end); start.add('30', 'minutes')) {
//         if (start.clone().add(60, 'minutes').isAfter(end)) break;
//         if (start.clone().add(1, 'second').isAfter(end)) break;
//         if (moment().isAfter(start)) continue;

//         // checking if this is the last slot and it's not 90 minutes long
//         if (times.length === 1 && !start.clone().add(90, 'minutes').isAfter(end))
//             start.add(30, 'minutes');

//         times.push({
//             slotId: slot.slot_id,
//             price: slot.price,
//             time: start.format('HH:mm'),
//         });
//     }

//     return times;
// };

// export const getAllHours = (slot: ISlot, timezone: string): string[] => {
//     const start = moment.utc(slot.start_datetime).tz(timezone),
//         end = moment.utc(slot.end_datetime).tz(timezone);

//     const times = [];
//     let newTimes = [];
//     for (; start.isBefore(end); start.add("30", "minutes")) {
//         newTimes.push(start.format("HH:mm"));
//         if (start.clone().add(60, "minutes").isAfter(end)) break;
//         if (start.clone().add(1, "second").isAfter(end)) break;
//         if (moment().isAfter(start)) continue;

//         // checking if this is the last slot and it's not 90 minutes long
//         if (
//             times.length === 1 &&
//             !start.clone().add(90, "minutes").isAfter(end)
//         )
//             start.add(30, "minutes");

//         times.push(start.format("HH:mm"));
//     }

//     return [...new Set(times)].sort();
// };

export const getAllHours = (slot: ISlot, timezone: string): string[] => {
    const start = moment.utc(slot.start_datetime).tz(timezone),
        end = moment.utc(slot.end_datetime).tz(timezone),
        endWith1minAdded = moment
            .utc(slot.end_datetime)
            .tz(timezone)
            .add(1, "minute");

    const times = [];
    for (; start.isSameOrBefore(endWith1minAdded); start.add("30", "minutes")) {
        times.push(start.format("HH:mm"));
    }

    times.pop();
    const sortedData = [...new Set(times)].sort();
    return sortedData;
};

export const getAllAvailableHours = (
    slots: (ICourt & {
        available_slots: ISlot[];
    })[]
): string[] => {
    const hours = slots
        .map((slot: any) => slot.available_slots?.map((s: any) => s.hours))
        .flat();
    const flatHours = [...new Set(hours.flat()?.sort())];

    return flatHours;
};

export const getSelectableHours = (hours: string[]): string[] => {
    const newArr: string[][] = [];
    let tempArr = [];

    for (let i = 0; i < hours.length; i++) {
        const firstHour = hours[i];
        const secondHour = hours[i + 1];
        const diff = moment(secondHour, "HH:mm").diff(
            moment(firstHour, "HH:mm"),
            "minutes"
        );

        if (diff === Math.abs(30)) {
            tempArr.push(firstHour);
        } else {
            tempArr.push(firstHour);
            newArr.push(tempArr);
            tempArr = [];
        }
    }

    const flatArr: string[] = [];
    newArr.forEach((arr) => {
        for (let i = 0; i < arr.length - 1; i++) {
            if (i !== 1) flatArr.push(arr[i]);
        }
    });

    return flatArr;
};

export const getCustomizedSelectableHours = (hours: string[]): string[][] => {
    const customizeArr = hours.reduce((acc, val, i) => {
        if (i % 6 === 0) {
            acc.push([]);
        }
        acc[acc.length - 1].push(val);
        return acc;
    }, [] as string[][]);

    return customizeArr;
};

export const calculateAvailability = (
    slots: ISlot[],
    selectedSlot: string,
    hours: string[]
) => {
    const durations = [60, 90, 120, 180]; // Durations in minutes
    const availability: {
        [key: number]: {
            isAvailable: boolean;
            price: number;
        };
    } = {};

    for (const duration of durations) {
        const isAvailable = checkAvailability(
            slots,
            selectedSlot,
            duration,
            hours
        );
        if (!isAvailable) continue;

        availability[duration] = {
            isAvailable,
            price: calculatePrice(slots, selectedSlot, duration)
        };
    }

    return availability;
};

export const checkAvailability = (
    slots: ISlot[],
    selectedSlot: string,
    duration: number,
    hours: string[]
) => {
    const allHours = [...new Set(slots.map((s: any) => s.hours))].flat().sort();

    const convertHourToMinute = (slot: string): number => {
        const start = moment("00:00", "HH:mm"),
            end = moment(slot, "HH:mm");

        return end.diff(start, "minutes");
    };

    const allHoursInMin = allHours.map(convertHourToMinute);
    const selectedHourInMin = convertHourToMinute(selectedSlot);
    const lastTime = selectedHourInMin + duration - 30;

    const indexOfSelectItem = allHoursInMin.indexOf(selectedHourInMin);
    const indexOfLastTime = allHoursInMin.indexOf(lastTime);

    for (let slot = selectedHourInMin; slot <= lastTime; slot += 30) {
        if (!allHoursInMin.includes(slot)) {
            return false;
        }
    }

    let startCondition = false;
    let endCondition = false;

    if (indexOfLastTime === -1 || indexOfSelectItem === -1) {
        return false;
    } else {
        const prevFirstItem = allHoursInMin[indexOfSelectItem - 1],
            prevSecondItem = allHoursInMin[indexOfSelectItem - 2],
            nextFirstItem = allHoursInMin[indexOfLastTime + 1],
            nextSecondItem = allHoursInMin[indexOfLastTime + 2];

        // Check for next items
        if (nextFirstItem !== undefined) {
            const diffWithNextFirst = nextFirstItem - lastTime;
            if (diffWithNextFirst >= 60) {
                endCondition = true;
            } else if (
                diffWithNextFirst === 30 &&
                nextSecondItem !== undefined &&
                nextSecondItem - lastTime === 60
            ) {
                endCondition = true;
            }
        } else {
            endCondition = true;
        }

        // Check for previous items
        if (prevFirstItem !== undefined) {
            const diffWithPrevFirst = selectedHourInMin - prevFirstItem;
            if (diffWithPrevFirst >= 60) {
                startCondition = true;
            } else if (
                diffWithPrevFirst === 30 &&
                prevSecondItem !== undefined &&
                selectedHourInMin - prevSecondItem === 60
            ) {
                startCondition = true;
            }
        } else {
            startCondition = true;
        }

        // Set to true if prevFirstItem is not found
        if (prevFirstItem === undefined) {
            startCondition = true;
        }
    }

    return startCondition && endCondition;
};

export const calculatePrice = (
    slots: ISlot[],
    selectedHour: string,
    duration: number
) => {
    let price = 0;
    const hours: string[] = [];

    // 90 => 08:00, 08:30, 09:00
    for (let i = 0; i < duration; i += 30) {
        hours.push(
            moment(selectedHour, "HH:mm").add(i, "minutes").format("HH:mm")
        );
    }

    for (const hour of hours) {
        const slot = slots.find((slot: any) => slot.hours.includes(hour));
        if (!slot) continue;

        const slotPrice30Min = slot.price / 2;
        price += slotPrice30Min;
    }

    return price;
};

export const getNearbyClubs = async (data: any) => {
    try {
        const response = await AxiosFastAPI.post(
            `/player/player_booking/nearby_clubs?player_id=${data.currentUser.uid}&latitude=${data.latitude}&longitude=${data.longitude}&radius=${data.radius}&limit=0&skip=0`,
            {
                sport:
                    data.sports.length > 0
                        ? data.sports.map((v: any) => v.toLowerCase())
                        : undefined,
                court_type:
                    [...data.courtType, ...data.courtDimension].length > 0
                        ? [...data.courtType, ...data.courtDimension]
                        : undefined
            }
        );

        return response;
    } catch (error) {
        console.error("Error fetching nearby clubs:", error);
    }
};

export const getSearchNearbyClubs = async (data: any) => {
    try {
        const response = await AxiosFastAPI.post(
            `/player/player_booking/v2/search_nearby_clubs?player_id=${data
                .currentUser?.uid}&search_club_name=${(
                data.clubName || ""
            ).trim()}&latitude=${data.latitude}&longitude=${
                data.longitude
            }&limit_for_registered=250&limit_for_non_registered=0&skip_for_registered=${
                data.pageNumber ?? 0
            }`,
            {
                sport: data.selectedSport ?? [],
                court_type: []
            }
        );

        return response;
    } catch (error) {
        console.error("Error fetching nearby clubs:", error);
    }
};

export const getAllClubs = async (data?: any) => {
    try {
        const response = await AxiosFastAPI.post(
            `/player/player_booking/search_clubs?search_club_name=${
                data?.clubName || ""
            }&limit=${data?.limit ?? 0}&skip=${data?.skip ?? 0}`,
            {
                sport: [],
                court_type: []
            }
        );

        return response;
    } catch (error) {
        console.error("Error fetching all clubs:", error);
    }
};

// get club profile data when logged in

export const getClubProfile = async (club_id: string, player_id: string) => {
    try {
        const data = await AxiosFastAPI.get(`/player/player_club_info/crud`, {
            params: {
                club_id,
                player_id
            }
        });

        return data;
    } catch (error: any) {
        console.log(error.response?.data?.detail);
    }
};

// get club profile data when not logged in

export const getClubProfileData = async (club_id: string) => {
    try {
        const data = await AxiosFastAPI.get(`/club/`, {
            params: {
                club_id
            }
        });

        return data;
    } catch (error: any) {
        console.log(error.response?.data?.detail);
    }
};

export const getAvailableSlots = async (
    club_id: string,
    start_datetime: number,
    end_datetime: number
) => {
    try {
        const { data } = await AxiosFastAPI.get(
            `/player/player_booking/all_courts_slot_prices_v2`,
            {
                params: {
                    club_id,
                    start_datetime,
                    end_datetime
                }
            }
        );
        const slotsResponse: any = data;

        return slotsResponse || [];
    } catch (error: any) {
        /*  throw new Error(
                  error?.details?.message || 'Failed to fetch available slots'
              );*/
        console.log("Error retrieving slots");
    }
};

export const handleSaveCard = async (
    cardNumber: any,
    cardExpire: any,
    cardCvv: any
) => {
    try {
        const { data } = await Axios.post("/payment/addNewCard", {
            cardNumber: cardNumber,
            expireMonth: Number(cardExpire?.split("-")[1]),
            expireYear: Number(cardExpire?.split("-")[0]),
            cvc: cardCvv
        });

        return data;
    } catch (error) {
        console.log("loading => card saving error", error);
        return error;
    }
};

export const getAllCards = async (setCardsFetching: any) => {
    try {
        const { data } = await Axios.get(
            "payment/getAllPaymentMethodsOfCustomer"
        );
        if (data.success) return data;
    } catch (error) {
        setCardsFetching(false);
        console.log("loading => get all cards saved error", error);
    }
};

export const getLiteProfileById = async (userId: string) => {
    try {
        const { data } = await Axios.get(
            "user/profileByIdLite?userId=" + userId
        );
        if (data.success) return data;
    } catch (error) {
        console.log("loading => createPayment error", error);
        throw error;
    }
};
export const callSetPasswordAndActivateAccount = async (
    userId: string,
    password: string
) => {
    try {
        const { data } = await Axios.post("user/setPassword", {
            userId: userId,
            password: password
        });
        return data;
    } catch (error) {
        console.log(
            "loading => callSetPasswordAndActivateAccount error",
            error
        );
        throw error;
    }
};

export const createPayment = async (
    clubId: string,
    totalPrice: number,
    paymentMethodId: string
) => {
    try {
        const { data } = await Axios.post("payment?payFor=booking", {
            clubId,
            totalPrice,
            paymentMethodId
        });
        if (data.success) return data;
    } catch (error) {
        console.log("loading => createPayment error", error);
        throw error;
    }
};

export const bookCourt = async ({ club_id, bookData }: IBookingData) => {
    try {
        const { data } = await AxiosFastAPI.post(
            `/player/player_booking/crud`,
            bookData,
            {
                params: {
                    club_id
                }
            }
        );

        return data;
    } catch (error: any) {
        console.log({ error: error.config });
        throw error.response;
    }
};

export const userLoginWithoutFCMToken = async (email: string) => {
    try {
        const res = await Axios.post("/auth/login", {
            email
        });

        const data = await res.data;
        return data;
    } catch (error) {
        console.log("Error from user api", { error });
        return null;
    }
};

export const checkAvailableSlot = async (arg: any) => {
    try {
        const { data } = await AxiosFastAPI.get(
            `player/player_booking/slot_available?club_id=${arg.clubId}&court_id=${arg.courtId}&start_datetime=${arg.startDatetime}&end_datetime=${arg.endDatetime}`
        );
        if (data) return data;
    } catch (error) {
        console.log("loading => checkAvailableSlot", error);
    }
};

// delete card
export const handleRemoveCard = async (card: ICard, saveCards: ICard[]) => {
    const { stripePaymentMethodId, defaultPaymentMethod } = card;
    let defaultPaymentMethodId;
    if (defaultPaymentMethod) {
        const filterCards = saveCards?.filter(
            (card) => !card.defaultPaymentMethod
        );
        defaultPaymentMethodId = filterCards?.[0]?.stripePaymentMethodId;
    }

    try {
        const data = await Axios.delete("payment/removeCard", {
            params: {
                paymentMethodId: stripePaymentMethodId,
                defaultPaymentMethodId
            }
        });

        return data;
    } catch (error) {
        console.log("loading => delete card error", { error });
    }
};

export const updateClubInfo = async (
    club_id: string,
    player_id: string,
    body: {
        following?: boolean;
        notification?: {
            match_post: boolean;
            tournament_post: boolean;
            training_post: boolean;
        };
    }
) => {
    try {
        // const data = await Axios.patch(
        const data = await AxiosFastAPI.patch(
            `/player/player_club_info/crud`,
            body,
            {
                params: {
                    club_id,
                    player_id
                }
            }
        );

        return data;
    } catch (error: any) {
        console.log("loading => follow club api error", error);
    }
};

export const applyCoupon = async (
    club_id: string,
    code: string,
    setApplyingVoucher: any,
    setVoucherErrorMessage: any
) => {
    try {
        const resp = await AxiosFastAPI.get(`/club/discount_code/check`, {
            params: {
                club_id,
                code
            }
        });
        if (resp.data?.status) {
            return resp;
        } else {
            setApplyingVoucher(false);
            setVoucherErrorMessage("failed to apply coupon");
        }
    } catch (error: any) {
        setApplyingVoucher(false);
        console.log("loading => voucher catch error", { error });
        setVoucherErrorMessage("failed to apply coupon");
    }
};

export const getMembershipDiscounts = async ({
    player_ids,
    club_id,
    activity_type,
    end_datetime,
    start_datetime,
    sport_type,
  }: {
    player_ids: string[];
    club_id: string;
    activity_type: 'booking' | 'matches' | 'tournaments' | 'training';
    start_datetime: number;
    end_datetime: number;
    sport_type: string;
  }) => {
    try {
      const { data, config } = await AxiosFastAPI.post(
        `/club/membership/membership_discount`,
        {
          player_ids,
          club_id,
          activity_type,
          end_datetime,
          start_datetime,
          sport_type: sport_type?.toUpperCase(),
        }
      );
  
  
      return data as { [key: string]: number };
    } catch (error: AxiosError | any) {
  
      throw error;
    }
};

export const getUserNotifications = async (email: string) => {
    try {
        const { data } = await Axios.get(
            `/notification/getNotificationList/${email}`
        );
        if (data) return data;
    } catch (error) {
        console.log("loading => checkAvailableSlot", error);
    }
};

export interface AutoJoinFlowAdditionalInfo {
    isTeamAmericano?: boolean;
    credit_package_id?: string;
    teamAmericanoDto?: TeamAmericanoRequestDto;
    bookData?: IBookRawData;
    membershipRequest?: MembershipRequest;
    payingForExistingPlayer?: boolean;
    activity_join_id?: string;
}
export interface TeamAmericanoRequestDto {
    player1: PlayerData;
    player2: PlayerData;
}

export interface IBookRawData {
    total_price: number;
    discount: number;
    code: string;
    info: string;
    payment_status?: string;
    court_ids: string[]; // [string, string]
    players: string[];
    start_datetime: number;
    stop_datetime: number;
}

export interface MembershipRequest {
    player_id: string;
    birth_date?: string;
    phone_number?: string;
    address?: string;
    postal_no?: string;
    country?: string;
    club_id?: string;
    membership_id?: string;
    following?: boolean;
    payment_status?: string;
    next_payment?: number;
    subscription_id?: string;
}

export interface PlayerData {
    name: string;
    email: string;
    profilePic: string;
    punctuality: number;
    pun: number;
    lang: string;
    fcmToken: string;
    level: {
        name: string;
        im: number;
        des: string;
        point?: number;
        img?: number;
    };
    userId: string;
    pro?: boolean;
    _id: string;
}

// handle payment by user credit
export const handleCreditPayment = async ({
    payFor,
    gameType,
    clubId,
    totalPrice,
    sourceId,
    source,
    credit,
    startTime,
    endTime,
    isSupportAutoJoinFlow = false,
    autoJoinAdditionalInfo,
    splitAmount,
    splitDiscount,
    payForSplitPlayerId,
    payForSplitPayment,
    activityId,
    courtType,
    transactionIdForSplit,
    isForSplitPayment,
    split_players_ids,
    transactionForSplit,
    paymentMethod,
    discountCode,
    sourceType
}: {
    payFor: string;
    gameType?: string;
    clubId: string;
    totalPrice: number;
    sourceId?: string;
    source: string;
    credit: number;
    startTime: number;
    endTime: number;
    isSupportAutoJoinFlow?: boolean;
    autoJoinAdditionalInfo?: AutoJoinFlowAdditionalInfo;
    splitAmount?: number;
    splitDiscount?: number;
    payForSplitPlayerId?: string;
    payForSplitPayment?: boolean;
    activityId?: string;
    courtType?: string;
    transactionIdForSplit?: string;
    isForSplitPayment?: boolean;
    split_players_ids?: Array<{ id: string }>;
    transactionForSplit?: boolean;
    paymentMethod?: string;
    discountCode?: string;
    sourceType?: string;
}) => {
    const { data } = await Axios.post(
        "/payment/credit",
        {
            clubId,
            totalPrice,
            sourceId,
            source,
            credit,
            startTime,
            endTime,
            isSupportAutoJoinFlow,
            autoJoinAdditionalInfo,
            splitAmount,
            splitDiscount,
            payForSplitPlayerId,
            payForSplitPayment,
            activityId,
            courtType,
            transactionIdForSplit,
            isForSplitPayment,
            split_players_ids,
            transactionForSplit,
            paymentMethod,
            discountCode,
            sourceType
        },
        {
            params: {
                payFor,
                gameType
            }
        }
    );
    console.log("handleCreditPayment", data);
    return data as {
        success: boolean;
        message: string;
        transactionId: string;
        chargeId: string;
        paymentStatus: string;
        receiptUrl: string;
    };
};

// get all credits of user from a club
export const getCredits = async (club_id: string) => {
    if (!club_id) throw new Error("Club id not found");

    try {
        const { data } = await AxiosFastAPI.get("/player/credits", {
            params: {
                club_id
            }
        });

        console.log({ data });

        if (data.credit >= 0) {
            return data.credit;
        }
    } catch (error) {
        console.log({ error });

        throw error;
    }
};

export const getClubMemberships = async (clubId: string) => {
    try {
        const data = await AxiosFastAPI.get(
            `/player/player_membership/?club_id=${clubId}`
        );

        console.log("loading => getClubMemberships", data);

        return data;
    } catch (error: any) {
        console.log("loading => getClubMemberships error", error?.response);
    }
};

export const getClubMembershipById = async (membership_id: string) => {
    try {
        const resp = await AxiosFastAPI.get(`/club/membership/crud`, {
            params: {
                membership_id
            }
        });
        console.log("loading => getClubMembershipById resp", resp);
        return resp;
    } catch (error: any) {
        console.log("loading => getClubMembershipById catch error", { error });
    }
};

export const getClubActivities = async (club_id: string) => {
    try {
        const resp = await Axios.get(
            `webportal/getClubActivityRecordsWithoutAuth/${club_id}`
        );
        // console.log("loading => getClubActivities resp", resp);
        return resp;
    } catch (error: any) {
        console.log("loading => getClubActivities catch error", { error });
    }
};

export const confirmSubscription = async (
    subscriptionId: string,
    url: string
) => {
    try {
        const { data } = await Axios.post(url, { subscriptionId });

        return { ...data, subscriptionId } as {
            success: boolean;
            message: string;
            receiptUrl: string;
            subscriptionId: string;
        };
    } catch (error) {
        console.log({ error });
    }
};

export const createSubscriptionIntent = async (
    {
        planId,
        planDuration,
        amount,
        currency,
        sourcePaymentMethodId,
        url,
        clubId,
        description
    }: {
        planId: string;
        planDuration: number;
        amount: number;
        currency: string;
        sourcePaymentMethodId: string;
        url: string;
        clubId?: string;
        description?: string;
    },
    setPaymentState: any,
    paymentState: any,
    setIsLoading: any
) => {
    try {
        const { data } = await Axios.post(url, {
            planId,
            planDuration,
            amount,
            currency,
            sourcePaymentMethodId,
            clubId,
            description
        });

        return data as IPaymentIntent;
    } catch (error: any) {
        setIsLoading(false);
        console.log("loading => createSubscriptionIntent", { error });
        const errorMsg: any =
            error?.response?.data?.message || "Payment failed";
        setPaymentState({ ...paymentState, error: errorMsg });
    }
};

export const activeMembership = async (requestData: any) => {
    try {
        const { data } = await AxiosFastAPI.post(
            `/player/player_membership/crud`,
            requestData
        );

        return data as {
            status_code: number;
            success: boolean;
            message: string;
            user: {
                _id: string;
            };
        };
    } catch (error: any) {
        console.log(error?.response);
    }
};

export const getPlayersAllMemberships = async (setFetchingMemberships: any) => {
    setFetchingMemberships(2);
    try {
        const { data } = await AxiosFastAPI.get(
            "/player/player_membership/crud"
        );
        return data?.memberships;
    } catch (error: any) {
        console.log({ error });
        setFetchingMemberships(2);
    }
};

export type ProfileData = {
    name: string;
    email?: string;
    phone?: string;
    /*  facebookLink?: string;
  instagramLink?: string;
  aboutMe?: string;
  profilePic?: string; */
};
// update user profile data
export const updateUserInfo = async ({
    name,
    phone /*   facebookLink,
  instagramLink,
  aboutMe,
  profilePic, */
}: ProfileData) => {
    const params: ProfileData = {
        name,
        phone
        /* facebookLink,
    instagramLink,
    aboutMe,
    profilePic */
    };

    try {
        const { data } = await Axios.put("/user/updateUserInfo", params);
        return data;
    } catch (error) {
        console.log({ error });
        throw error;
    }
};

// update user hidden boolean
export const updateUserHideProfile = async () => {
    try {
        const { data } = await Axios.put("/user/toggle-hidden");
        return data;
    } catch (error) {
        console.log({ error });
        throw error;
    }
};

// get user profile data
export const getUserInfo = async () => {
    try {
        const { data } = await Axios.get("/user/profile");
        return data;
    } catch (error) {
        await console.log({ error });
        return null;
    }
};

export const cancelMembership = async (
    club_id: string | null,
    setCancelling: any
) => {
    try {
        const { data, config } = await AxiosFastAPI.patch(
            `/player/player_membership/crud?club_id=${club_id}`
        );

        console.log("loading => cancelMembership data before return", {
            data,
            config
        });
        if (data) {
            return data;
        }
    } catch (error: any) {
        console.log("loading => cancelMembership error", { error });
        const errorMsg = error.response?.data?.detail.message;
        Store.addNotification({
            title: "Failed",
            message: errorMsg,
            type: "danger",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000
            }
        });
        setCancelling(false);
    }
};

export const getActivities = async ({
    playerId,
    isPlayedActivity
}: {
    playerId: string;
    isPlayedActivity: boolean;
}) => {
    try {
        const payload =  {
            showMatches: true,
            showTournaments: true,
            showTrainings: true,
          }
        const { data } = await Axios.post(
            `/webportal/getAllActivityUsers/${playerId}?${
                !isPlayedActivity ? "upcoming=1" : "played=1"
            }`,
            payload
        );
        return data as ActivityType[];
    } catch (error: any) {
        console.log({ error: error });
    }
};

export const cancelBooking = async (activity_records_id: string) => {
    try {
        const data = await AxiosFastAPI.delete(`/player/player_booking/crud`, {
            params: {
                activity_records_id
            }
        });
        if (data) return data;
    } catch (error: any) {
        const errorMsg = error?.response?.data?.detail?.message;
        console.log(
            "loading => cancel booking error",
            error?.response?.data?.detail
        );
        Store.addNotification({
            title: "Failed",
            message: errorMsg,
            type: "danger",
            container: "bottom-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000
            }
        });
    }
};

export const removeSplitUser = async ({
    playersId,
    clubId,
    transactionId,
  }: {
    playersId: string[];
    clubId: string;
    transactionId: string;
    position?: number;
  }) => {
    try {
  
      const response = await Axios.post('payment/forSplit/removePlayer', {
        playersId: playersId,
        clubId: clubId,
        transactionId: transactionId,
      });
  
      return response.data;
    } catch (error: any) {
      return {
        ...error.response.data,
        success: false
      }
    }
};

export const favouriteClubs = async (
    player_id: string,
    latitude: number,
    longitude: number
) => {
    try {
        const { data } = await AxiosFastAPI.get(
            `/player/player_club_info/following_clubs`,
            {
                params: {
                    player_id,
                    latitude,
                    longitude
                }
            }
        );
        const clubs = data?.following_clubs || [];
        return clubs.filter((club: any) => club.following);
    } catch (error: AxiosError | any) {
        console.log("loading => favouriteClubs error", error.data);
    }
};

export const getUserProfile = async (
    // userEmail: string,
    sportType: string = "padel"
) => {
    let res = null;
    // await Axios.get("user/profileByEmail", {
    await Axios.get("user/profileById", {
        params: {
            // userEmail,
            sportType
        }
    })
        .then((response) => {
            console.log("loading => getUserProfile", response);
            res = response.data.user;
        })
        .catch((error) => {
            console.log("loading => getUserProfile", error);
        });
    return res;
};

export const userOnboard = async (data: any) => {
    try {
        const processData = {
            ageRange: data.ages,
            gender: data.gender,
            gameOptions: data?.gameOptions?.map((game: any) => ({
                level: +game.level || 1,
                name: game.name
            })),
            userLocation: {
                lat: data?.userLocation?.lat || 59.51279,
                long: data?.userLocation?.long || 17.64373
            }
        };

        const resp = await Axios.put("/user/onboard", processData);
        const userData = await resp.data;
        return userData;
    } catch (error: any) {
        console.log("loading => user onboarding error", { error });
        const errorMsg: any =
            error?.response?.data?.message || "onboarding failed";
        Store.addNotification({
            title: "Failed",
            message: errorMsg,
            type: "danger",
            container: "center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000
            }
        });
        return error;
    }
};

export const getAmericanoTournamentPlayers = async (id: any) => {
    try {
        const response = await Axios.post(
            `tournament/getAmericanoTournamentPlayers/${id}`
        );
        console.log("getAmericanoTournamentPlayers response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error in getAmericanoTournamentPlayers:", error);
        throw new Error(`Error in getAmericanoTournamentPlayers: ${error}`);
    }
};

export const declineRequestTeamAmericano = async (
    id: string,
    email: string
) => {
    try {
        const response = await Axios.post(
            `tournament/declineRequestTeamAmericano/${id}`,
            JSON.stringify({ email })
        );
        console.log(response);
        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error(`Error in declineRequestTeamAmericano ${error}`);
    }
};

export const declineTournamentRequest = async (id: any, playerEmail: any, email: any, message="") => {
    try {
      const payload = JSON.stringify({
        email,
        playerEmail,
        message
      });
      const response = await Axios.post(
        `tournament/declineTournamentRequest/${id}`,
        payload
      );
      
      return response.data;
    } catch (error) { 
      throw Error(`Error in declineTournamentRequest ${error}`);
    }
    // return res;
};

export const removeFromQueueForTeamAmericano = async (id: any, email: any) => {
    try {
      const response = await Axios.put(
        `/tournament/removeFromQueueForTeamAmericano/${id}`,
        JSON.stringify({ email })
      );
  
      return response;
    } catch (error) {
        throw Error(`Error in removeFromQueueForTeamAmericano ${error}`);
    }
  };

  export const removeFromQueue = async (id: any, email: any) => {
    try {
      const response = await Axios.put(
        `/tournament/removeFromQueue/${id}`,
        JSON.stringify({ email })
      );
  
      return response;
    } catch (error) {
        throw Error(`Error in removeFromQueue ${error}`);
    }
  };

export const isQueueTeam = async (email: any, id: any) => {
    try {
        const response = await Axios.post(`/tournament/isInTournamentTeamQueue/${id}`, JSON.stringify({
            email
        }))
        return response.data
      
    } catch (error) {
        console.log({ error });
        throw Error(`isInTournamentTeamQueue ${error}`)
    }
};

export const isQueue = async (email: any, id: any) => {
    let res = {};
    await Axios.post(`tournament/isInQueue/${id}`, JSON.stringify({ email }))
        .then((response) => {
        res = response.data;
        
        return res;
        })
        .catch((error) => {
        // baseUrl =backup  
        });
    return res;
};

export const isRequested = async (email: string, id: any) => {
    let res = {};
    await Axios.post(`tournament/isRequested/${id}`, JSON.stringify({ email }))
      .then((response) => {
        res = response.data;
        
        return res;
      })
      .catch((error) => {
        // baseUrl =backup
        
      });
    return res;
  };

export const isRequestedteam = async (email: string, id: any) => {
    try {
        const response = await Axios.post(
            `/tournament/isRequestedTeamAmericano/${id}`,
            JSON.stringify({ email })
        );
        console.log("response isRequestedTeamAmericano in : ", response);
        return response.data;
    } catch (error) {
        throw Error(`Error in isRequestedTeamAmericano ${error}`);
    }
};

export const getTournamentById = async (id: any) => {
    let res = {};
    await Axios.get(`tournament/getTournamentById/${id}`)
        .then((response) => {
            if (response.data == "") {
                res = { error: "notfound" };
            } else if (response.data?.error !== undefined) {
                res = { error: "notfound" };
            } else {
                res = response.data;
            }
            console.log("get getTournamentById : ", response);
            return res;
        })
        .catch((error) => {
            console.log(error);
        });
    return res;
};
export const leaveTournament = async (email: string, id: any) => {
    try {
        const response = await Axios.post(
            `tournament/leaveTournament/${id}`,
            JSON.stringify({ email })
        );
        console.log(
            "🚀 ~ file: Api.js:4171 ~ leaveTournament ~ response:",
            response
        );

        return response;
    } catch (error: any) {
        console.log(
            "🚀 ~ file: Api.js:4175 ~ leaveTournament ~ error:",
            error.response,
            error
        );
        return error.response;
    }
};

export const deleteAmericanoTeam = async (id: any, data: any) => {
    try {
      const response = await Axios.delete(`tournament/deleteTeamAmericano/${id}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error: any) {
      return error?.response
    }
  };

export const isInTournament = async (email: string, id: any) => {
    let res = {};

    await Axios.post(
        `tournament/isInTournament/${id}`,
        JSON.stringify({ email })
    )
        .then((response: any) => {
            res = response.data;
            console.log("response isInTournament in : ", response);
            return res;
        })
        .catch((error: any) => {
            console.log("inInTournament ", JSON.stringify(error));
        });
    return res;
};

export const isInTournamentTeamAmericano = async (email: string, id: any) => {
    try {
      const response = await Axios.post(
        `tournament/isInTournamentTeamAmericano/${id}`,
        JSON.stringify({ email })
      );
      return response.data;
    } catch (error) {
      console.log({ error });
      throw Error(`Error in isInTournamentTeamAmericano ${error}`);
    }
  };

export const requestToJoinTeamAmericano = async (id: any, user: any) => {
    try {
        console.log(JSON.stringify(user));
        const response = await Axios.post(
            `tournament/requestToJoinTeamAmericano/${id}`,
            JSON.stringify(user)
        );
        console.log("requestToJoinTeamAmericano ", response);
        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error(`Error in requestToJoinTeamAmericano ${error}`);
    }
};

export const getTournamentQueue = async (id: any) => {
    let res = {};
    await Axios.get(`tournament/getTournamentQueue/${id}`)
      .then((response) => {
        res = response.data;
        
      })
      .catch((error) => {
        // baseUrl =backup
        
      });
    return res;
  };

export const joinTournament = async (id: string, user: any) => {
    const res = {};
    console.log(JSON.stringify(user));
    try {
        const response = await Axios.post(
            `tournament/joinTournament/${id}`,
            JSON.stringify(user)
        );
        console.log("response recieved in joinTournament : ", response);
        if (response.data.error) {
            throw Error(
                `Error in joinTournament activity ${response.data.error}`
            );
        }
        return response.data;
    } catch (error) {
        console.log({ error });
        throw Error(`Error in joinTournament ${error}`);
    }
};

export const joinTeamAmericanoTournament = async (id: any, user: any) => {
    const res = {};
    try {
        const response = await Axios.post(
            `tournament/joinTeamAmericanoTournament/${id}`,
            JSON.stringify(user)
        );
        console.log("response recieved in joinTournament : ", response);
        return response.data;
    } catch (error) {
        throw Error(`${error}`);
    }
};

export const isInvitedTeamAmericano = async (id: any, email: any) => {
    try {
      const response = await Axios.post(
        `tournament/isInvitedTeamAmericano/${id}`,
        JSON.stringify({ email })
      );
      
      if (response.data.error) {
        throw Error(`Error in isInvitedTeamAmericano ${response.data.error}`);
      }
      return response.data;
    } catch (error) {
      throw Error(`Error in isInvitedTeamAmericano ${error}`);
    }
};

export const isInvitedTournament = async (email: any, id: any) => {
    try {
      const response = await Axios.post(
        `tournament/isInvitedTournament/${id}`,
        JSON.stringify({ email })
      );
      
      if (response.data.error) {
        throw Error(response.data.error);
      }
      return response.data;
    } catch (error) {
      throw Error(`Error in isInvitedTournament ${error}`);
    }
  };

export const getTournamentPlayers = async (id: any, type = "americano") => {
    try {
        const response = await Axios.post(
            `tournament/getTournamentPlayers/${id}`,
            {
                type
            }
        );
        console.log(
            "get All getTournamentPlayers : ",
            JSON.stringify(response.data)
        );
        if (response.data.error) {
            throw Error(
                `Error in get All getTournamentPlayers ${response.data.error}`
            );
        }

        return response.data;
    } catch (error) {
        throw Error(`Error in get All getTournamentPlayers ${error}`);
    }
};

export const sampleMembership = [
    {
        club_name: "United Club",
        timezone: "Asia/Kolkata",
        currency: "INR",
        club_address: "United Club, 123, United Street",
        club_postal_code: "123456",
        club_city: "United City",
        club_state: "United State",
        club_country: "United Country",
        _id: "d8f5ta8d7fx6ad9f67sd9",
        type: "Public",
        name: "United Gold",
        managed_by: "6437e4de69fff3f7b2049383",
        start_date: 1630454400000,
        end_date: 1630454400000,
        discount: 25,
        discount_validity: {
            booking: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            },
            matches: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            },
            tournaments: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            },
            training: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            }
        },
        information: "This is a public membership",
        price: 100,
        period: "Monthly",
        usp_1: "20% on matches and trainings",
        usp_2: "Free tshirt and water bottle",
        usp_3: "20% on matches and trainings",
        usp_4: "Free tshirt and water bottle"
    },
    {
        club_name: "Axios Club",
        timezone: "Asia/Kolkata",
        currency: "PKR",
        club_address: "Axios Club, 123, United Street",
        club_postal_code: "123456",
        club_city: "Karachi",
        club_state: "Sindh",
        club_country: "Pakistan",
        _id: "d8f5ta8d7fx6ad9f67sd9",
        type: "Public",
        name: "Pak Gold",
        managed_by: "6437e4de69fff3f7b2049383",
        start_date: 1630454400000,
        end_date: 1630454400000,
        discount: 25,
        discount_validity: {
            booking: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            },
            matches: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            },
            tournaments: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            },
            training: {
                status: true,
                slot_id: "d8f5ta8d7fx6ad9f67sd9"
            }
        },
        information: "This is a public membership",
        price: 999,
        period: "Monthly",
        usp_1: "20% on matches and trainings",
        usp_2: "Free tshirt and water bottle",
        usp_3: "20% on matches and trainings",
        usp_4: "Free tshirt and water bottle"
    }
];

const updateFCMToken = async (token: any) => {
    try {
        const { data } = await Axios.put("/user/updateFCMToken", {
            fcmToken: token
        });

        return data;
    } catch (error) {
        console.log({
            error
        });

        return null;
    }
};

const getUserSettings = async () => {
    try {
        const { data } = await Axios.get("user/profileByIdWithSportType");

        if (data?.user)
            // await AsyncStorage.setItem('userSportsFilter', JSON.stringify(data.user));
            // await AsyncStorage.setItem(
            //   'currentHomeFilterSettings',
            //   JSON.stringify(data.currentHomeFilterSettings)
            // )
            return data?.user;
    } catch (error) {
        console.log(error);
    }
};
export const userLogin: any = async (email: string, fcmToken: string) => {
    try {
        const res = await Axios.post("/auth/login", {
            email
        });

        await updateFCMToken(fcmToken);
        await getUserSettings();
        const data = await res.data;
        return data;
    } catch (error) {
        console.log("Error from user api", { error });
        return null;
    }
};

export const resendEmail = async () => {
    try {
        const res = await Axios.post(`user/resend-verification`);
        return res;
    } catch (error) {
        console.log("Error from resendEmail api", { error });
        return null;
    }
};

export const activateEmail = async (body: { verificationCode: string }) => {
    try {
        const res = await Axios.patch(`user/activate`, body);
        return res;
    } catch (error) {
        console.log("Error from resendEmail api", { error });
        return null;
    }
};

export const uploadUserImage = async (formData: any) => {
    try {
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      };
  
      const response = await Axios.post(`user/addProfilePic`, formData, config);
      return response.data;
    } catch (error) {
      throw Error(`Error in image upload ${error}`);
    }
  };

export const getFollowingsWithLevel = async (sportType: any) => {
    try {
        const response = await Axios.get('user/getFollowingsWithLevel',{
        params:{
            sportType
        }
        })
        
        return response.data.followings;
        
    } catch (error) {
        throw Error(`Error ${error}`);
    }
};

export const searchUserForActivity = async ({
    name,
    centerName,
    level,
    sportType,
    skip,
    limit,
  }: any) => {
    try {
        const response: any = await Axios.get(`user/searchUserForActivity`, {
            params: {
              name,
              centerName,
              level,
              sportType,
              skip,
              limit,
            },
          });
        return response?.data?.searchedUsers;
    } catch (error) {
        throw Error(`Error in ${error}`);
    }
};