import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const DownloadTheApp = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t("downloadtheapp")} | Padel Mates</title>
            </Helmet>
            <div className=" text-center mx-auto px-6 py-16">
                <div
                    className={`text-3xl font-medium ${"leading-6"} text-gray-900`}
                >
                    {`${t("download")}`}
                </div>
                <div className="mt-9">
                    <div className="flex gap-4 justify-center">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.padelmates"
                            target="_blank"
                            data-stop-propagation="true"
                            rel="noreferrer"
                        >
                            <img
                                src="/images/googleplay.png"
                                className="h-12 w-18"
                                alt="Google Play"
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/app/id1531797995?fbclid=IwAR3ywH0UF_AFtXYXTD2UaWNfAcOHZX8YRmgW1qV3RMC1w8RXo6EFkPVb0a0"
                            target="_blank"
                            data-stop-propagation="true"
                            rel="noreferrer"
                        >
                            <img
                                src="/images/appstore.png"
                                className="h-12 w-18"
                                alt="App Store"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DownloadTheApp;
