import React, { ReactElement,useEffect, useMemo } from "react";
import { BadgeImage } from "./BadgeImage"; 
import { getSystemValue } from "../../../Utilities/utils"; 
import { defaultLevelSystem } from "../../../Utilities/constant";

interface ProfileELOBadgeProps {
  player: any;
  gameType: string;
  containerStyle?: string;
  onFillChange?: (fill: string) => void;
  userData?:any
}
const ProfileELOBadge =  (props: ProfileELOBadgeProps): ReactElement => {
  const { player, gameType = "padel", containerStyle, onFillChange, userData } = props
  const mergeLevelSystems = (defaultSystem:any, playerSystem:any) => {
    let mergedSystem:any = []
    if (playerSystem) {
      mergedSystem = [...playerSystem];
  
      defaultSystem.forEach((defaultSport:any) => {
        const isSportPresent = playerSystem.some(
          (playerSport:any) =>
            playerSport.sportType.toLowerCase() === defaultSport.sportType.toLowerCase()
        );
    
        if (!isSportPresent) {
          mergedSystem.push(defaultSport);
        }
      }); 
    }
  
    return mergedSystem;
  };

  function getBarAndDivision(value: number) {
    if (value < 1.9) {
      return { bar: 1, division: 1 };
    } else if (value >= 1.9 && value < 2.8) {
      return { bar: 1, division: 2 };
    } else if (value >= 2.8 && value < 3.7) {
      return { bar: 2, division: 1 };
    } else if (value >= 3.7 && value < 4.6) {
      return { bar: 2, division: 2 };
    } else if (value >= 4.6 && value < 5.5) {
      return { bar: 3, division: 1 };
    } else if (value >= 5.5 && value < 6.4) {
      return { bar: 3, division: 2 };
    } else if (value >= 6.4 && value < 7.3) {
      return { bar: 4, division: 1 };
    } else if (value >= 7.3 && value < 8.2) {
      return { bar: 4, division: 2 };
    } else if (value >= 8.2 && value < 9.1) {
      return { bar: 5, division: 1 };
    } else if (value >= 9.1 && value <= 10.0) {
      return { bar: 5, division: 2 };
    } else {
      return { bar: 1, division: 1 };
    }
  }

  const percentage = useMemo(() => {
    const finalLevelSystem = mergeLevelSystems(defaultLevelSystem, userData?.levelSystem);
    
    let userLevel = 10
    if (finalLevelSystem?.length) {
      userLevel = getSystemValue( gameType,finalLevelSystem)
    }
    if (player?.points && userLevel) {
      let percent = player?.points / userLevel;
      let fill = "#43EC9B";
      const lvl = getBarAndDivision(percent / 10);
      let level = lvl?.bar;
      if (level == 5) {
        fill = "#F94545";
      } else if (level == 4) {
        fill = "#FF7C7C";
      } else if (level == 3) {
        fill = "#FFA37C";
      } else if (level == 2) {
        fill = "#F5FF7C";
      }

      return {
        level: level,
        fill,
        percentage: percent,
        division: lvl?.division || 1,
      };
    }
  }, [player?.points]);

  useEffect(() => {
    if (percentage?.fill && onFillChange) {
      onFillChange(percentage.fill); // Pass the fill color to the parent component
    }
  }, [percentage?.fill]);

  return (
    <div className={`absolute right-0 -top-2 ${containerStyle || ""}`}>
      <BadgeImage
        width='20'
        fill={percentage?.fill}
        division={percentage?.division}
      />
    </div>
  );
};

export default ProfileELOBadge;