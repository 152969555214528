import React from "react";

const CareerProfile = () => {
    return (
        <div className="min-h-screen">
            <div className="max-w-6xl mx-auto px-6 py-12">
                <section className="text-secondary rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">The Role</h2>
                    <p className="mb-6">
                        As a Lead Solution Architect, you will play a vital role
                        in shaping the future of our products and systems.
                        Reporting directly to the CTO, you’ll lead the design of
                        scalable solutions, actively contribute to the codebase,
                        and coordinate efforts across multiple teams to ensure
                        the timely delivery of high-quality, production-ready
                        systems.
                    </p>
                    <p>
                        You’ll work closely with developers, Technical Leads,
                        and stakeholders to align technical solutions with
                        business goals. Your expertise will help maintain
                        operational excellence, including occasionally stepping
                        in to support system stability during critical moments.
                    </p>
                </section>

                <section className="text-secondary rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">
                        Key Responsibilities
                    </h2>
                    <ul className="list-disc list-inside space-y-4">
                        <li>
                            <strong>Solution Architecture:</strong> Design and
                            deliver innovative, scalable, and secure solutions
                            for our app and booking platform.
                        </li>
                        <li>
                            <strong>Hands-On Development:</strong> Actively
                            engage in coding, solving complex challenges, and
                            prototyping new features or integrations.
                        </li>
                        <li>
                            <strong>Team Coordination:</strong> Collaborate with
                            offshore Technical Leads and developers across
                            Mobile, Backend, and Frontend teams to ensure
                            alignment, resolve dependencies, and deliver
                            efficiently.
                        </li>
                        <li>
                            <strong>Production Readiness:</strong> Oversee CI/CD
                            pipelines, testing, and deployments to maintain
                            system stability and ensure production reliability.
                        </li>
                        <li>
                            <strong>Stakeholder Collaboration:</strong> Partner
                            with the CTO and other business leaders to align
                            technical initiatives with strategic goals.
                        </li>
                        <li>
                            <strong>Mentorship and Best Practices:</strong>{" "}
                            Mentor developers, promote best practices, and
                            ensure architectural documentation is accessible and
                            up-to-date.
                        </li>
                        <li>
                            <strong>Working Environment:</strong> Embrace a
                            hybrid work setup with mandatory in-office
                            collaboration two days per week, ensuring a balance
                            between flexibility and team synergy.
                        </li>
                    </ul>
                </section>

                <section className="text-secondary rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">
                        Required Skills and Qualifications
                    </h2>
                    <ul className="list-disc list-inside space-y-4">
                        <li>
                            5+ years of experience in software development, with
                            strong programming skills.
                        </li>
                        <li>
                            2+ years of experience in solution architecture,
                            designing scalable and secure systems.
                        </li>
                        <li>
                            Expertise in Node.js, NestJS, AWS, MongoDB, and
                            modern frontend (React.js)/backend frameworks.
                        </li>
                        <li>
                            Experience with monolithic and microservices
                            architectures.
                        </li>
                        <li>
                            Expertise with CI/CD pipelines, system monitoring,
                            and production reliability practices.
                        </li>
                        <li>
                            Excellent problem-solving and analytical skills with
                            a proactive mindset.
                        </li>
                        <li>
                            Strong communication skills for collaborating across
                            teams and stakeholders.
                        </li>
                    </ul>
                </section>

                <section className="text-secondary rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">
                        Desired Attributes
                    </h2>
                    <ul className="list-disc list-inside space-y-4">
                        <li>
                            Bachelor’s or Master’s degree in Computer Science,
                            Software Engineering, or a related field.
                        </li>
                        <li>
                            A passion for sports and technology, with an
                            interest in shaping the future of sports matchmaking
                            and club management.
                        </li>
                        <li>
                            An innovative thinker who thrives in a collaborative
                            and fast-paced environment.
                        </li>
                        <li>
                            Strong organizational skills and the ability to
                            manage multiple priorities effectively.
                        </li>
                    </ul>
                </section>

                <section className="text-secondary rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">Why Join Us?</h2>
                    <ul className="list-disc list-inside space-y-4">
                        <li>
                            Impactful Work: Be at the forefront of designing
                            solutions that connect thousands of players and
                            clubs.
                        </li>
                        <li>
                            Empowering Culture: We value creativity, ownership,
                            and the freedom to make a difference.
                        </li>
                        <li>
                            Collaborative Team: Work closely with our leadership
                            team, reporting directly to the CTO.
                        </li>
                        <li>
                            Flexibility: Enjoy a hybrid work environment with
                            opportunities for in-person collaboration in
                            Stockholm.
                        </li>
                    </ul>
                </section>

                <section className="text-secondary text-[#FFFFFFCC] rounded-lg shadow-lg p-4 lg:p-8 mb-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">
                        About Padel Mates
                    </h2>
                    <p className="mb-4">
                        Padel Mates is revolutionizing the way people play
                        sports. We offer a sports matchmaking app that helps
                        players find partners and join matches effortlessly. For
                        clubs, we provide a comprehensive booking system to
                        manage bookings, players, and operations through an
                        intuitive portal. Our mission is to create scalable and
                        impactful solutions that enhance the joy of sports while
                        empowering players and clubs.
                    </p>
                </section>

                <section className="text-secondary text-[#FFFFFFCC] rounded-lg shadow-lg p-4 lg:p-8 border border-primary">
                    <h2 className="text-2xl font-bold mb-4">How to Apply</h2>
                    <p className="mb-4">
                        We’d love to hear from you! Apply directly by sending your CV to{" "}
                        <a
                            href="mailto:feras@padelmates.se"
                            className="text-primary underline"
                        >
                            feras@padelmates.se
                        </a>
                        . Share examples of your work or achievements that
                        demonstrate your skills as a hands-on architect and
                        leader.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default CareerProfile;
